<div class="row">
  <div class="col-md-8 col-md-offset-2">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h3 class="panel-title">{{'title_change_password' | translate}}</h3>
      </div>
      <div class="panel-body">
        <form [formGroup]="passwordForm" novalidate [ngClass]="passwordForm.valid ? 'form-valid form-horizontal' : 'form-invalid form-horizontal'">
          <div class="form-group">
            <label class="col-md-4 control-label">{{'title_old_password' | translate}}</label>
            <div class="col-md-6">
              <input type="password" name="old_password" [(ngModel)]="old_password" class="form-control" formControlName="old_password" ngModel />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">{{'title_new_password' | translate}}</label>
            <div class="col-md-6">
              <input type="password" name="new_password" [(ngModel)]="new_password" (ngModelChange)="valuechange()" class="form-control"
                formControlName="new_password" [placeholder] ="passwordValidDisp | translate" ngModel />
              <div *ngIf="passwordForm.controls.new_password.touched && passwordForm.controls.new_password.errors">
                <span class="text-danger">
                  <div [hidden]="!passwordForm.controls.new_password.errors.required">{{'msg_required_field' | translate}}</div>
                  <div [hidden]="!passwordForm.controls.new_password.errors.pattern">{{'msg_invalid_password' | translate}}</div>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">{{'title_new_password_confirm' | translate}}</label>
            <div class="col-md-6">
              <input type="password" name="new_password_conf" [(ngModel)]="new_password_conf" class="form-control" formControlName="new_password_conf"
                [validateEquals]="new_password" [placeholder] ="passwordValidDisp | translate" ngModel/>
              <div *ngIf="passwordForm.controls.new_password_conf.touched && passwordForm.controls.new_password_conf.errors">
                <span class="text-danger">
                    <div [hidden]="!passwordForm.controls.new_password_conf.errors.required">{{'msg_required_field' | translate}}</div>
                    <div [hidden]="!passwordForm.controls.new_password_conf.errors.validateEquals">{{'msg_confirm_password_not_match' | translate}}</div>
                    <div [hidden]="!passwordForm.controls.new_password_conf.errors.pattern">{{'msg_invalid_password' | translate}}</div>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-md-6 col-md-offset-4">
              <button class="btn btn-primary" (click)="onClick()" [disabled]="!passwordForm.valid">
                <span class="glyphicon glyphicon-ok-sign" aria-hidden="true"></span> {{'title_ok' | translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
