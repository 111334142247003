<h2>{{'title_alert_log_list' | translate}}</h2>

<!-- 検索折り畳み start -->
<div class="row">
  <div class="col-sm-12 text-right">
    <button (click)="isExpanded = !isExpanded">
      <span class="glyphicon"
        [ngClass]="{'glyphicon-menu-down': !isExpanded, 'glyphicon-menu-up': isExpanded }"></span>
    </button>
  </div>
</div>
<mat-expansion-panel [expanded]="isExpanded" hideToggle class="noPanelStyle">
<!-- 検索折り畳み end -->

<mat-card class="search-form">
  <mat-card-content>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <input matInput id="display_name" name="display_name" type="text" [(ngModel)]="condition.deviceDisplayName" placeholder="{{'title_device_name' | translate}}">
          <mat-hint align="end">{{'p_holder_partial_match' | translate}}</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <input matInput id="device_id" name="device_id" type="text" [(ngModel)]="condition.deviceId" placeholder="{{'title_device_id' | translate}}">
          <mat-hint align="end">{{'p_holder_partial_match' | translate}}</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <mat-select placeholder="{{'title_device_type' | translate}}" [(ngModel)]="condition.deviceTypeId" #deviceTypeModel="ngModel" multiple>
            <mat-option disabled="disabled" class="select-action">
              <button mat-button (click)="deviceTypeModel.update.emit([])">
                <mat-icon aria-hidden="true">clear</mat-icon> {{'title_clear_all_selections' | translate}}
              </button>
            </mat-option>
            <mat-divider></mat-divider>
            <mat-option *ngFor="let type of dropdownDeviceTypes" [value]="type.value">{{type.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <mat-select placeholder="{{'title_group' | translate}}" [(ngModel)]="condition.groupId" #groupModel="ngModel" multiple>
            <mat-option disabled="disabled" class="select-action">
              <button mat-button (click)="groupModel.update.emit([])">
                <mat-icon aria-hidden="true">clear</mat-icon> {{'title_clear_all_selections' | translate}}
              </button>
            </mat-option>
            <mat-divider></mat-divider>
            <mat-option *ngFor="let group of dropdownGroups" [value]="group.value">{{group.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <mat-select [(ngModel)]="condition.isActive" placeholder="{{'title_alert_state' | translate}}">
            <mat-option></mat-option>
            <mat-option *ngFor="let isActive of dropdownIsActive" [value]="isActive.value">{{isActive.name | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <input matInput id="alert_title" name="alert_title" type="text" [(ngModel)]="condition.alertTitle" placeholder="{{'title_alert_title' | translate}}">
          <mat-hint align="end">{{'p_holder_partial_match' | translate}}</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right">
        <button mat-raised-button color="accent" (click)="clickSearch()">
          <mat-icon aria-hidden="true">search</mat-icon> {{'title_search' | translate}}
        </button>
        <button type="button" mat-icon-button matTooltip="{{'title_clear_conditions' | translate}}" (click)="condition.clear()">
          <mat-icon aria-hidden="true">clear</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
</mat-expansion-panel>

<mat-card class="result-table">
  <mat-card-content>
    <table class="table table-bordered table-hover">
      <colgroup>
        <col style="width:30%">
        <col style="width:20%">
        <col style="width:30%">
        <col style="width:10%">
        <col style="width:10%">
      </colgroup>
      <thead>
        <tr>
          <th *ngFor="let columns of columnList">
            <button *ngIf="columns.issort" mat-button id={{columns.id}} class="btn-order" (click)="sortAlerts(columns.id)">
              <div [ngSwitch]="condition.currentSortDirection(columns.id)">
                <span>
                  <b>{{columns.displayName | translate}}</b>
                </span>
                <span *ngSwitchDefault class="fa fa-sort" aria-hidden="true"></span>
                <span *ngSwitchCase="'asc'" class="fa fa-sort-asc" aria-hidden="true"></span>
                <span *ngSwitchCase="'desc'" class="fa fa-sort-desc" aria-hidden="true"></span>
              </div>
            </button>
            <label class="labelth" *ngIf="!columns.issort" id={{columns.id}}>
              {{columns.displayName | translate}}
            </label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of alertLogResults; let idx = index;" class="active">
          <td [attr.data-label]="columnList[0].displayName | translate">
            <a [routerLink]="['/devices', result.deviceId]">{{result.deviceDisplayName}}</a>
          </td>
          <td [attr.data-label]="columnList[1].displayName | translate">{{result.startDatetime | date:('date_format_ymdhms' | translate)}}</td>
          <td [attr.data-label]="columnList[2].displayName | translate">
            <a (click)="openDetail(result.id)">{{result.title}}</a>
          </td>
          <td [attr.data-label]="columnList[3].displayName | translate">
            <mat-icon aria-hidden="true" [color]="result.alertActiveIcon.color" [matTooltip]="result.alertActiveName | translate">{{result.alertActiveIcon.name}}</mat-icon>
          </td>
          <td [attr.data-label]="columnList[4].displayName | translate">
            <mat-icon aria-hidden="true" [color]="result.mailStatusIcon.color" [matTooltip]="result.mailStatusName | translate">{{result.mailStatusIcon.name}}</mat-icon>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="isNoData" class="col-sm-12">
      <app-note role="info">
        {{'msg_no_data_to_display' | translate}}
      </app-note>
    </div>
    <div class="row">
      <div class="col-sm-12 text-center">
        <pagination *ngIf="alertLogResults.length > 0" [totalItems]="count" [itemsPerPage]="condition.pageSize" [maxSize]="8"
          [(ngModel)]="condition.page" [nextText]="'pagination_next' | translate" [previousText]="'pagination_prev' | translate" (pageChanged)="pageChanged($event)">
        </pagination>
      </div>
    </div>
  </mat-card-content>
</mat-card>
