import { Subscription, Observable } from 'rxjs';
import { OnInit, Component, OnDestroy } from '@angular/core';
import { DeviceDetail } from '../../common/api/device';
import { ActivatedRoute } from '@angular/router';
import { WebStorageAuthRepository } from '../../services/auth-repository.service';
import { ApiHandler } from '../../services/api-handler.service';
import { DialogService } from '../../services/dialog.service';
import { Functions } from '../../common/functions';
import {TranslateService} from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  templateUrl: './remote.component.html'
})
export class RemoteComponent implements OnInit, OnDestroy {
  /** 本画面で表示するデバイス情報 */
  device: DeviceDetail;

  private parentSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private apiHandler: ApiHandler,
    private dialogService: DialogService,
    private t: TranslateService,
    private functions: Functions,
    private authRepo: WebStorageAuthRepository) { }

  ngOnInit(): void {
    this.parentSubscription = this.route.parent.data.subscribe(obj => {
      // 親コンポーネントのデバイスタイプをそのまま使わせていただく
      this.device = obj['device'];
      const canEidtFlg = this.authRepo.hasPermission('device', 'edit');
      if (!this.device.sshActive) {
          this.functions.showToastMessage(this.t.instant(`msg_nosupport_device`, {
          }), 'error');
      } else if (!canEidtFlg) {
          this.functions.showToastMessage(this.t.instant(`msg_403`, {
          }), 'error');
      }
    });
  }

  /** デバイスを編集できるかどうか */
  get canEdit() {
    return this.authRepo.hasPermission('device', 'edit') && this.device.sshActive;
  }

  openPage() {
    let url = this.device.connectionDestinationUrl + '/device?device_id=' + this.device.id;
     window.open(url);
  }

  ngOnDestroy() {
    this.parentSubscription.unsubscribe();
  }
}
