import {Component, OnInit, OnDestroy} from '@angular/core';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {MapEditComponent} from '../modal/map-edit.component';
import {ActivatedRoute} from '@angular/router';
import {DeviceDetail, DeviceUpdateApi, Property} from '../../common/api/device';
import {Subscription} from 'rxjs';
import {ApiHandler} from '../../services/api-handler.service';
import {RouterService} from '../../services/router.service';
import {DialogService} from '../../services/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {Functions} from '../../common/functions';

@Component({
  templateUrl: './edit.component.html'
})
export class EditComponent implements OnInit, OnDestroy {
  /** 本画面で表示するデバイスの情報 */
  device: DeviceDetail;
  parentSubscription: Subscription;

  public modalRef: BsModalRef;
  message: string;
  isNoData: boolean = false;

  constructor(private route: ActivatedRoute, private routerService: RouterService,
              private apiHandler: ApiHandler, private modalService: BsModalService,
              public dialogService: DialogService,
              public t: TranslateService,
              public functions: Functions) {
  }

  /**
   * 保存ボタンclick
   */
  clickSave() {
    const deviceEntity = {
      displayName: this.device.displayName,
      properties: this.device.properties.map(prop => {
        return {id: prop.id, value: prop.value};
      })
    };
    this.apiHandler.call(true, DeviceUpdateApi, [this.device.id], deviceEntity)
      .subscribe(res => {
        if (!res.hasError) {
          // 画面をリロード
          this.routerService.reload();
        }
      });
  }

  /**
   * マップ編集画面起動
   */
  openMapEdit(property: Property) {
    /* NOTE: モーダル内で property の value が直接書き換えられるので注意
       本来はイベントとかで値を引き渡したいところ。 */
    const modalRef = this.modalService.show(MapEditComponent,
      {class: 'modal-dialog modal-lg', backdrop: 'static'});
    const modalContent = <MapEditComponent>modalRef.content;
    modalContent.property = property;
  }

  ngOnInit() {
    this.parentSubscription = this.route.parent.data.subscribe(obj => {
      // 親コンポーネントが解決したデバイス情報をそのまま使わせていただく
      this.device = obj['device'] as DeviceDetail;
      if (this.device.properties.length === 0) {
        this.isNoData = true;
      } else {
        this.isNoData = false;
      }
    });
  }

  ngOnDestroy() {
    this.parentSubscription.unsubscribe();
  }
}
