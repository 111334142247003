import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiHandler } from '../../services/api-handler.service';
import { SelectOption　} from '../../common/interfaces/display-interfaces';
import { GroupMemberAddApi } from '../../common/api/group';

@Component({
  moduleId: module.id,
  templateUrl: 'group-member-add.component.html',
  providers: [GroupMemberAddApi]
})

export class GroupMemberAddComponent implements OnInit {
  title = '';
  isOk = false;
  groupMemberAddForm: FormGroup;
  email: FormControl;
  roles: FormControl;
  groupId: number;
  selecteddropdownRoles: number[] = [];
  selectRolesList: any[] = [];
  idList: number[] = [];

  public initGroupMemberAddModal(isAdd: boolean, title: string, dropRole: SelectOption, groupId: number,
    roles: SelectOption[]) {
    this.title = title;
    this.groupId = groupId;
    // メールアドレス
    this.email.setValue('');
    // ロール
    // this.dropdownRoles = roles;

    for (let i = 0; i < roles.length; i++) {
      this.selectRolesList.push({ value: roles[i].value, name: roles[i].name });
      this.idList.push(roles[i].value);
    }
  }

  /**
   * メンバー登録
   */
  groupMemberAdd() {
    const entity = this.toGroupMemberAddEntity();
    // ローカル環境で確認するとcode:500が返ってくるが、適切な環境であれば登録処理が正常終了している
    this.apiHandler.call(true, GroupMemberAddApi, [this.groupId], entity, {}, [], {})
      .subscribe(
        res => {
          // 登録成功の場合は登録完了フラグONにして画面を閉じる
          this.isOk = true;
          this.bsModalRef.hide();
        });
  }

  // 入力フォームの入力をエンティティに変換する
  private toGroupMemberAddEntity(): GroupMemberAddApi['requestPayloadInterface'] {
    const selectedRoles = this.selecteddropdownRoles;
    return {
      email: this.email.value,
      roles: selectedRoles,
    };
  }

  constructor(
    public t: TranslateService,
    public bsModalRef: BsModalRef,
    public apiHandler: ApiHandler,
    private builder: FormBuilder,
  ) { }

  ngOnInit() {
    this.email = new FormControl('', [
      Validators.required,
      Validators.maxLength(128),
      Validators.email
    ]);

    this.roles = new FormControl('', [
      Validators.required,
    ]);

    this.groupMemberAddForm = new FormGroup({
      email: new FormControl(),
      roles: new FormControl(),
    });

    this.groupMemberAddForm = this.builder.group({
      email: this.email,
      rolea: this.roles,
    });
  }

  /**
   * コンボボックス 全て選択・選択解除
   */
  selectAll($event: any[], isSelectAll: boolean) {
    if (isSelectAll) {
      // 全て選択
      this.roles.setValue(this.idList);
    } else {
      // 全て選択解除
      this.roles.setValue('');
    }
  }
}
