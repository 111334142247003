import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { ApiHandler } from './api-handler.service';
import { TokenModel } from '../models/token.model';
import { Functions } from '../common/functions';
import { AuthInfo } from '../common/api/auth';
import { WebStorageAuthRepository } from './auth-repository.service';
import { mergeMap, catchError, map } from 'rxjs/operators';

@Injectable()
export class DataService {
  constructor(private http: HttpClient, private functions: Functions, private router: Router, private auth: ApiHandler,
    private authRepository: WebStorageAuthRepository) { }

  /**
   * 最後にトークンを更新してから30秒以上経過している場合、
   * トークンリフレッシュを行います。
   */
  refreshToken() {
    const token = this.authRepository.token;
    if (!token) {
      // 認証NG
      this.auth.eraseToken();
      this.router.navigate(['/login'], { queryParams: { session_end: true } });
      return of(false);
    } else if (this.authRepository.shouldReflesh) {
      const tokeParam: TokenModel = {
        token: token
      };
      let headers = {};
      headers["Content-Type"] = 'application/json';
      return this.http.post('/api/auth_refresh', JSON.stringify(tokeParam), { headers: headers, observe: 'response' })
        .pipe(map(
          res => {
            const json = res.body as AuthInfo;
            // NOTE: 上の as AuthInfo でキャストしただけではスネークケースのキー名が
            // キャメルケースに変換されない。したがって、group_idsがそのままローカルストレージに保存されてしまう。
            // 仕方がないのでトークンだけ更新して、権限情報、所属ルート情報はそのまま引き継ぐこととする。
            // 認証OK
            this.authRepository.update({
              token: json.token,
              permissions: this.authRepository.permissions,
              roots: this.authRepository.roots
            });
            return true;
          }
        ),
        catchError(
          _error => {
            // 認証NG
            this.auth.eraseToken();
            this.router.navigate(['/login'], { queryParams: { session_end: true } });
            return of(false);
          }));
    } else {
      return of(true);
    }
  }

  /**
  * データ登録
  */
  putData(url: string, data: any) {
    return this.refreshToken().pipe(mergeMap(
      __res => {
        const body = JSON.stringify(data);
        let headers = {};
        headers["Authorization"] = 'JWT ' + this.authRepository.token;
        headers["Content-Type"] = 'application/json';
        return this.http.put(url, body, { headers: headers, observe: 'response' });
      }
    ));
  }
}
