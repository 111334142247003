import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DevicesComponent } from './devices.component';
import { DevicesRoutingModule } from './devices-routing.module';
import { DataService } from '../services/data.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DeviceAddComponent } from './modal/device-add.component';
import { Functions } from '../common/functions';
import { ConfirmComponent } from '../common/messages/confirm.component';
import { AppMaterialModule } from '../common/app-material.module';
import { PartsComponentModule } from '../parts/parts-component.module';
@NgModule({
  imports: [
    DevicesRoutingModule,
    AppMaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PartsComponentModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule,
  ],
  declarations: [
    DevicesComponent,
    DeviceAddComponent
  ],
  providers: [
    DataService,
    Functions
  ],
  bootstrap: [
    DevicesComponent
  ],
  entryComponents: [
    DeviceAddComponent,
    ConfirmComponent
  ]
})
export class DevicesModule { }

