import { ApiDef, ApiStatusMessage } from './sip-api-client';
import { Method } from '../enums/api-enums';
import { IconDef } from '../interfaces/display-interfaces';

/**
 * デバイスミュート切り替えAPIの定義
 */
export class DeviceMuteToggleAddApi extends ApiDef {
  requestPayloadInterface: {
    deviceId: number;
    isMuted: boolean;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/device_mute/toggle';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
      { code: 201, message: 'toast_mute_on_device' },
      { code: 204, message: 'toast_mute_off_device' },
      { code: 400, message: 'toast_device_not_found' }
  ];
}

/** デバイスミュート状態 */
export type DeviceMuteStatus = boolean;
export namespace DeviceMuteStatus {
  export const toIcon = (status: DeviceMuteStatus): IconDef => {
    return status ?
     { label: 'title_mute_on', name: 'mic_off', color: 'foreground' } :
     { label: 'title_mute_off', name: 'mic', color: 'accent' } ;
  };
}
