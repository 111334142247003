import { Permission, AuthInfo, Root, PermissionResource, PermissionAction } from '../common/api/auth';
import { Injectable } from '@angular/core';

/**
 * 認証情報を読み書きする機能を表すインタフェース。
 *
 * NOTE: AngularのDIはインターフェースに対して実装クラスのインスタンスをインジェクションできない。つまり
 *
 *   constructor(private authRepo: AuthRepository)
 *
 * と書いて WebStorageAuthRepository をインジェクションすることはできない。
 * 以下のように明示的に実装クラスの型を指定する必要がある。
 *
 *   constructor(private authRepo: WebStorageAuthRepository)
 *
 * なので、このインタフェースがあろうとなかろうとあんまり意味はない。
 * けど、一応インタフェースを明示するために interface として定義しておく。
 */
interface AuthRepository {
  readonly token: string;
  readonly shouldReflesh: boolean;
  readonly permissions: Permission[];
  readonly roots: any[];
  update(info: AuthInfo): void;
  clear(): void;
}

/**
 * WebStorageを使って認証情報を読み書きする機能を提供する。
 */
@Injectable()
export class WebStorageAuthRepository implements AuthRepository {
  private static readonly TOKEN_KEY = 'token';
  private static readonly TIMESTAMP_KEY = 'timestamp';
  private static readonly PERMISSIONS_KEY = 'permissions';
  private static readonly ROOTS_KEY = 'roots';

  get token() {
    return localStorage.getItem(WebStorageAuthRepository.TOKEN_KEY) || '';
  }

  get shouldReflesh() {
    const isotime = localStorage.getItem(WebStorageAuthRepository.TIMESTAMP_KEY);
    if (isotime == null) { return true; }
    const timestamp = new Date(isotime);
    // 30秒以上経過していたら更新の必要あり
    return new Date().getTime() - timestamp.getTime() > 30 * 1000;
  }

  get permissions() {
    const perms = localStorage.getItem(WebStorageAuthRepository.PERMISSIONS_KEY);
    if (perms == null) { return []; }  // ストレージに権限情報がない場合
    return JSON.parse(perms) as Permission[];
  }

  get roots() {
    const perms = localStorage.getItem(WebStorageAuthRepository.ROOTS_KEY);
    if (perms == null) { return []; }  // ストレージにルート情報がない場合
    return JSON.parse(perms) as Root[];
  }

  update(info: AuthInfo): void {
    localStorage.setItem(WebStorageAuthRepository.TOKEN_KEY, info.token);
    localStorage.setItem(WebStorageAuthRepository.PERMISSIONS_KEY,
      JSON.stringify(info.permissions));
    localStorage.setItem(WebStorageAuthRepository.ROOTS_KEY,
      JSON.stringify(info.roots));
    localStorage.setItem(WebStorageAuthRepository.TIMESTAMP_KEY,
      new Date().toISOString());
  }

  clear(): void {
    localStorage.removeItem(WebStorageAuthRepository.TOKEN_KEY);
    localStorage.removeItem(WebStorageAuthRepository.PERMISSIONS_KEY);
    localStorage.removeItem(WebStorageAuthRepository.ROOTS_KEY);
    localStorage.removeItem(WebStorageAuthRepository.TIMESTAMP_KEY);
  }

  /** 少なくとも１つのグループの権限を持っているか判定する。 */
  hasPermission(resource: PermissionResource, action: PermissionAction): boolean;

  /**
   * 特定のグループの権限を持っているか判定する。
   *
   * @param groupId チェック対象のグループのユニークID
   */
  // オーバーロードメソッドごとにドキュメントを分けたいので unified-signatures ルールを無視する。
  // tslint:disable-next-line:unified-signatures
  hasPermission(resource: PermissionResource, action: PermissionAction, groupId: number): boolean;

  hasPermission(resource: PermissionResource, action: PermissionAction, groupId?: number): boolean {
    const matchedPerm = this.permissions.find(perm =>
      perm.resource === resource && perm.action === action
    );
    if (matchedPerm == null) { return false; }  // 該当する権限が存在しない
    const allowedGroups = matchedPerm.groupIds;
    if (groupId == null) {
      // グループの指定がない場合は少なくとも１つのグループがあるか？
      return allowedGroups.length > 0;
    } else {
      // 指定されたグループが含まれるか？
      return allowedGroups.includes(groupId);
    }
  }

  isRootUser(): boolean {
    if (this.roots != null && this.roots.length > 0) {
      return true;
    } else {
      return false;
    }
  }

}
