import { Component, OnInit } from '@angular/core';
import { ApiHandler } from '../services/api-handler.service';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Functions } from '../common/functions';
import { DeviceTypeAddComponent } from './modal/device-type-add.component';
import { DeviceTypeListApi, CommunicationType, DeviceTypeDeleteApi, DeviceType } from '../common/api/device-type';
import { ApiPagenation } from '../common/api/sip-api-client';
import { WebStorageAuthRepository } from '../services/auth-repository.service';
import { GroupsGetApi } from '../common/api/group';
import { PageableSearchCondition } from '../common/view/PageableSearchCondition';
import { Sort, SelectOption } from '../common/interfaces/display-interfaces';
import { DialogService } from '../services/dialog.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { QueryStoreService } from '../services/query-store.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  templateUrl: 'device-types.component.html'
})
export class DeviceTypesComponent implements OnInit {
  condition: DeviceTypeSearchCondition;
  isNoData: boolean = false;
  isExpanded: boolean = true;
  private querySubscription: Subscription;

  constructor(
    public t: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    public apiHandler: ApiHandler,
    public authRepo: WebStorageAuthRepository,
    private queryStore: QueryStoreService,
    private modalService: BsModalService,
    private dialogService: DialogService,
    private functions: Functions
  ) { }

  // interfaceModel
  devicetypesresults: Array<DeviceTypeRow> = [];

  // DropDown
  groupOptions: SelectOption[] = [];
  communicationTypeOptions: SelectOption[] = CommunicationType.values().map(type => {
    return { value: type, name: CommunicationType.toName(type) };
  });

  // Pagination
  count: number; // 全件数

  /** 削除ボタン活性状態 */
  get isDeletable(): boolean {
    // チェックが1つでもあればtrueを返す
    return this.devicetypesresults.some(row => row.isChecked);
  }

  /** デバイスタイプの追加削除権限を持っているか？ */
  get canAddDelete(): boolean {
    return this.authRepo.hasPermission('device_type', 'add_delete');
  }

  get allchecked(): boolean {
    return this.devicetypesresults.length !== 0 /* ignore empty array */ &&
      this.devicetypesresults.every(device => device.isChecked);
  }

  columnList = [
    { seq: 1, id: 'displayName', displayName: 'title_device_type_name', issort: true },
    { seq: 2, id: 'resourceName', displayName: 'title_device_type_id', issort: true },
    { seq: 3, id: 'communicationType', displayName: 'title_communication_type', issort: true },
    { seq: 4, id: 'groupDisplayName', displayName: 'title_group', issort: true }
  ];

  // ソート
  sortDevices(sortColumn: string) {
    this.condition.setSort(sortColumn);
    this.condition.clearPage();
    this.searchDeviceTypes();
  }

  /**
   * 検索ボタンonclick
   */
  clickSearch() {
    this.condition.clearSort();
    this.condition.clearPage();
    this.searchDeviceTypes();
  }

  /**
   * ページ遷移
   */
  pageChanged(event: any): void {
    this.condition.setPage(event.page);
    this.searchDeviceTypes();
  }

  /**
   * 削除ボタンonclick
   */
  clickDelete() {
    // 確認ダイアログ
    this.dialogService.show(this.t.instant('title_confirmation'), this.t.instant('msg_confirm_delete_device_types'))
      .subscribe(ok => {
        if (ok) {  // OKボタンが押下されたら
          const apiCalls = this.devicetypesresults
            .filter(type => type.isChecked)
            .map(type => this.apiHandler.call(false, DeviceTypeDeleteApi, [type.id]));
          // すべてのAPI呼び出しをまとめたObservableを生成
          forkJoin(apiCalls)
            .subscribe(result => {
              // すべてのAPI呼び出しが（失敗も含めて）完了した後の処理
              const totalCount = result.length;
              const failedCount = result.filter(elm => elm.hasError).length;
              if (failedCount > 0) {
                this.functions.showToastMessage(this.t.instant(`msg_faild_delete_items`, {
                  totalCount: totalCount, failedCount: failedCount
                }), 'warning');
              } else {
                this.functions.showToastMessage(this.t.instant(`msg_succeeded_delete_items`, {
                  totalCount: totalCount
                }), 'success');
              }
              this.searchDeviceTypes();
            });
        }
      });
  }

  /**
   * デバイスタイプ一覧取得
   */
  searchDeviceTypes() {
    // URLを変更する
    this.router.navigate(this.route.snapshot.url, {
      queryParams: this.condition.queryParams(),
      replaceUrl: true  // 履歴は上書き
    });
    this.apiHandler.call(false, DeviceTypeListApi, [], undefined, this.condition.query, this.condition.order, this.condition.pagination)
      .subscribe(res => {
        if (!res.hasError) {
          this.queryStore.storeQuery('device_types', this.condition.queryParams());  // 検索条件を保存
          const deviceTypes = res.body;
          this.count = deviceTypes.count;
          this.devicetypesresults = deviceTypes.results.map(type => {
            return {
              isChecked: false,  // デフォルトではチェックボックスはチェックなしにする
              communicationTypeName: CommunicationType.toName(type.communicationType),
              ...type
            };
          });

          if (this.devicetypesresults.length === 0) {
            this.isNoData = true;
          } else {
            this.isNoData = false;
          }
        }
      });

  }

  /**
   * グループ一覧取得
   */
  private searchGroups() {
    const pagination: ApiPagenation = {
      pageSize: 1000,  // 1ページに全グループが収まるように、ページサイズを最大にする
    };
    // グループ一覧取得API呼び出し
    this.apiHandler.call(false, GroupsGetApi, [], {}, {}, [], pagination)
      .subscribe(
        res => {
          const groups = res.body;
          // ドロップダウンの選択肢を更新する
          this.groupOptions = groups.results.map(group => {
            return { value: group.id, name: group.displayName };
          });
        },
        error => this.functions.showToastMessage(error.message, 'error')
      );
  }

  /**
   * デバイスタイプ登録画面をモーダルウィンドウで開く。
   */
  openDeviceTypeAdd() {
    const modalRef = this.modalService.show(DeviceTypeAddComponent, { class: 'modal-dialog modal-lg', backdrop: 'static' });
    // DropDown
    const modalComponent = <DeviceTypeAddComponent>modalRef.content;
    modalComponent.groupOptions = this.groupOptions;
    modalComponent.communicationTypeOptions = this.communicationTypeOptions;
    // モーダルを閉じたときに登録完了フラグONの場合に再取得する。
    this.modalService.onHide.subscribe(() => {
      if (modalRef.content.isOk) {
        this.searchDeviceTypes();
        modalRef.content.isOk = false;
      }
    });
  }

  /**
   * チェックボックス一括設定
   */
  cbAll(value: boolean) {
    // 各行のチェック状態を更新
    for (const row of this.devicetypesresults) {
      row.isChecked = value;
    }
  }

  /**
   * 初期化処理
   */
  ngOnInit(): void {
    this.condition = new DeviceTypeSearchCondition();
    // グループ取得
    this.searchGroups();
    /* クエリ文字列 or 永続化された検索条件で検索条件入力フォームを更新する */
    let activeQuery: Params = {};
    const params = this.route.snapshot.queryParams;  // クエリ文字列
    if (Object.keys(params).length === 0) {
      // クエリ文字列が空の場合は保存されている検索条件を取得する
      const storedQuery = this.queryStore.getQuery('device_types');
      activeQuery = storedQuery;
    } else {
      activeQuery = params;
    }
    this.condition.update(activeQuery);
    this.searchDeviceTypes();
  }
}

/** デバイスタイプ検索の入力フォームを表すクラス */
class DeviceTypeSearchCondition extends PageableSearchCondition<DeviceTypeListApi> {
  constructor(
    sort: Sort | null = null,
    page: number | null = null,
    public displayName: string = null,
    public resourceName: string = null,
    public groupId: number[] = [],
    public communicationType: string[] = []
  ) {
    super(sort, page);
  }

  get query(): DeviceTypeListApi['queryInterface'] {
    const query: DeviceTypeListApi['queryInterface'] = {};
    if (!this.isEmpty(this.displayName)) {
      query.displayName = this.displayName;
    }
    if (!this.isEmpty(this.resourceName)) {
      query.resourceName = this.resourceName;
    }
    if (!this.isEmpty(this.groupId)) {
      query.groupId = this.groupId;
    }
    if (!this.isEmpty(this.communicationType)) {
      query.communicationType = this.communicationType;
    }
    return query;
  }

  /** クエリパラメータに応じて条件を更新する */
  update(params: Params): void {
    super.update(params);
    if (!this.isEmpty(params['display_name'])) {
      this.displayName = params['display_name'];
    }
    if (!this.isEmpty(params['resource_name'])) {
      this.resourceName = params['resource_name'];
    }
    if (!this.isEmpty(params['group_id'])) {
      this.groupId = params['group_id'].split('|').map(elm => Number(elm));
    }
    if (!this.isEmpty(params['communication_type'])) {
      this.communicationType = params['communication_type'].split('|');
    }
  }

  /** 入力フォームの入力をクリアする */
  clear() {
    this.displayName = null;
    this.resourceName = null;
    this.groupId = [];
    this.communicationType = [];
  }

}

/** 1行に表示するデバイスタイプ情報 */
type DeviceTypeRow = DeviceType & {
  communicationTypeName: string,  // 通信方式名
  isChecked: boolean,  // 行ごとのチェックボックス
};
