import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Groups, GroupInfoGetApi } from '../common/api/group';
import { Injectable } from '@angular/core';
import { ApiHandler } from '../services/api-handler.service';
import { Observable } from 'rxjs';
import { ApiPagenation } from '../common/api/sip-api-client';
import { map } from 'rxjs/operators';

@Injectable()
export class GroupsResolver implements Resolve<Groups> {
  constructor(private router: Router, private apiHandler: ApiHandler) { }
  /**
  * URLパラメータからグループ情報を取得する
  */
  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot):
    Groups | Observable<Groups> | Promise<Groups> {

    const pagination: ApiPagenation = {
      pageSize: 1000,
    };

    return this.apiHandler.call(false, GroupInfoGetApi, [route.params['group_id']], {}, {}, [], pagination)
      .pipe(map(res => {
        if (res.hasError) {
          console.log('Error to get group api keys info.');
          this.router.navigate(['404']);
        } else {
          return res.body;
        }
      }));
  }
}
