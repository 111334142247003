import { ApiDef, ApiStatusMessage } from './sip-api-client';
import { Method } from '../enums/api-enums';
import { AlertDef, AlertType } from './alert-def';

/**
 * 個別アラート定義登録APIの定義
 */
export class DeviceAlertDefAddApi extends ApiDef {
  requestPayloadInterface: {
    deviceId: number;
    title: string;
    detail: string;
    type: AlertType;
    conditions?: string;
    pingInterval?: number;
    suppressInterval?: number;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/device_alert_defs';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
      { code: 201, message: 'toast_registered_individual_alert_def' },
      { code: 400, message: 'toast_input_value_invalid' }
  ];
}

/**
 * 個別アラート定義更新APIの定義
 */
export class DeviceAlertDefUpdateApi extends ApiDef {
  requestPayloadInterface: {
    title: string;
    detail: string;
    type: AlertType;
    conditions?: string;
    pingInterval?: number;
    suppressInterval?: number;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/device_alert_defs/{0}';
  method = Method.PUT;
  statusMsgList: ApiStatusMessage[] = [
      // TODO: APIのステータスコードが204ではなく200になっているので暫定対応。後で直す。
      { code: 200, message: 'toast_updated_individual_alert_def' },
      { code: 204, message: 'toast_updated_individual_alert_def' },
      { code: 400, message: 'toast_input_value_invalid' },
      { code: 404, message: 'toast_individual_alert_def_not_found' }
  ];
}

/**
 * 個別アラート定義削除APIの定義
 */
export class DeviceAlertDefDeleteApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/device_alert_defs/{0}';
  method = Method.DELETE;
  statusMsgList: ApiStatusMessage[] = [
      { code: 204, message: 'toast_deleted_individual_alert_def' },
      { code: 404, message: 'toast_individual_alert_def_not_found' }
  ];
}

export type DeviceAlertDef = AlertDef & {
  deviceId: number;
};
