import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
@Component({
    moduleId: module.id,
    templateUrl: 'confirm.component.html'
})
export class ConfirmComponent implements OnInit {
    title: string;
    messageHead: string;
    messageDetail: any;
    /**
     * @deprecated onClose イベントを使用してください。
     */
    isOk = false;

    /**
     * 確認ダイアログを閉じたときに発火するイベント。
     *
     * OKボタンを押下して閉じた場合は true がイベントオブジェクトになる。
     * キャンセルボタンを押下して閉じた場合は false がイベントオブジェクトになる。
     */
    onClose: Subject<boolean>;

    constructor(public bsModalRef: BsModalRef) { }

    clickOK() {
        this.isOk = true;
        this.bsModalRef.hide();
        this.onClose.next(true);
    }
    clickCancel() {
        this.isOk = false;
        this.bsModalRef.hide();
        this.onClose.next(false);
    }

    ngOnInit() {
      this.onClose = new Subject();
    }
}
