import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Method } from '../../common/enums/api-enums';
import { Injectable, EventEmitter } from '@angular/core';
import { WebStorageAuthRepository } from '../../services/auth-repository.service';
import { map, catchError } from 'rxjs/operators';

export interface ApiPagenation {
  pageSize?: number;
  page?: number | 'last';
}

export interface Page<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

export interface ApiStatusMessage {
  code: number;
  message: string;
}

export abstract class ApiDef {
  /**
   * リクエストペイロードのインターフェース型
   */
  abstract requestPayloadInterface: any;
  /**
   * クエリストリング用のペイロードのインターフェース型
   */
  abstract queryInterface: any;
  /**
   * ソート順指定可能な要素のインターフェース型
   */
  abstract orderableInterface: any;
  /**
   * レスポンスボディのインターフェース型
   */
  abstract responseBodyInterface: any;
  /**
   * APIのURI。URIパラメーターは{0},{1}..で記述する。
   */
  abstract uri: string;
  /**
   * APIの呼び出しHTTPメソッド。
   */
  abstract method: Method;
  /**
   * リターンステータスと、それに応じたメッセージのリスト。
   */
  abstract statusMsgList: ApiStatusMessage[];
  /**
   * ステータスコードから定義したメッセージを索く。
   */
  public getMessage = (code: number): string => {
    const def = this.statusMsgList.filter(v => v.code === code)[0];
    return def ? def.message : undefined;
  }
}

@Injectable()
export class SipApiClient {
  /**
   * 処理中のリクエスト数
   */
  private requestingApiNumber = 0;
  /**
   * 1つ以上のリクエストが問合せ中かどうか
   */
  public get isRequesting(): boolean {
    return this.requestingApiNumber > 0;
  }

  /**
   * API呼び出しの開始、終了を伝えるイベント。
   *
   * APIの同時呼び出し数が0から1になるときにtrueでイベントが発火する。
   * APIの同時呼び出しが1から0になるときにfalseでイベントを発火する。
   */
  apiEvent = new EventEmitter<boolean>();

  private increment() {
    this.requestingApiNumber++;
    if (this.requestingApiNumber === 1) {
      this.apiEvent.emit(true);
    }
  }

  private decrement() {
    this.requestingApiNumber--;
    if (this.requestingApiNumber === 0) {
      this.apiEvent.emit(false);
    }
  }

  /**
   * コンストラクタ
   */
  constructor(private http: HttpClient, private authRepository: WebStorageAuthRepository) { }

  /**
   * プリミティブ型もしくはDate型かどうか判定する
   */
  private isPrimitive = (val) => {
    return _.includes(['string', 'number', 'boolean'], typeof val)
      || val instanceof Date;
  }
  /**
   * Camel/SnakeCaseのキーを持つオブジェクトのキーを再帰的に逆の記法に変換する。
   * refs: https://kuroeveryday.blogspot.jp/2017/06/object-property-transform.html
   * @param object 対象のオブジェクト
   * @param toStyle どちらに変換するか(_.camelCase or _.snakeCase)
   */
  public localizeKeys = (object, toStyle = _.camelCase) => {
    const _localize = (obj) => {
      // プリミティブ型なら変換しない
      if (this.isPrimitive(obj) || obj === null) {
        return obj;
      }
      // 配列の中身がオブジェクトの場合のみキーを変換する
      if (Array.isArray(obj)) {
        const dest = [];
        obj.forEach(a => {
          if (this.isPrimitive(a) || a === null) { return dest.push(a); }
          if (typeof a === 'object') { return dest.push(_localize(a)); }
        });
        return dest;
      }
      // オブジェクトのキーを変換する
      if (typeof obj === 'object') {
        const dest = {};
        Object.keys(obj).forEach(a => {
          if (this.isPrimitive(obj[a]) || obj[a] === null) {
            return dest[toStyle(a)] = obj[a];
          }
          if (typeof obj[a] === 'object') {
            return dest[toStyle(a)] = _localize(obj[a]);
          }
        });
        return dest;
      }
    };
    return _localize(object);
  }

  /**
   * APIから戻ってきた結果をオブジェクトに変換する。
   * @param res レスポンス
   * @param msg ステータスコードから判断したメッセージ
   */
  private mapApiResult = <T extends ApiDef>(res, msg: string) => {
    this.decrement();
    const snakeObj = res.body;
    const isSuccess = (200 <= res.status && res.status < 300);
    // スネークケースで帰ってきた戻り値をキャメルケースに変換する。
    return {
      body: this.localizeKeys(snakeObj, _.camelCase) as T['responseBodyInterface'],
      code: res.status,
      hasError: !isSuccess,
      msg: msg
    };
  }
  /***
   * 指定したAPI定義クラスにもとづき、APIリクエストを実行する。
   * ローカルストレージから認証情報トークンを取得する。(未実装)
   * また、当該トークンが5秒以上前のトークンである場合、認証更新を行う。(未実装)
   * 結果はObservableで返り、レスポンスのオブジェクト、ステータスコード、
   * ステータスコードにより定義したメッセージを含む。
   *
   * @param apiDefType API定義クラス(インスタンスではなく型)を指定する。
   * @param uriParams URIパラメーターを配列で渡す。
   * @param payload POST/PUT用ペイロードをオブジェクトで渡す。
   * @param query GET用クエリパラメータをオブジェクトで渡す。
   * @param order GET用ソート項目をオブジェクトで渡す。Trueは昇順、Falseは降順、指定しないと指定なし。
   * @param page ページネーション用のパラメータをオブジェクトで渡す。
   */
  public exec = <T extends ApiDef>(
    apiDefType: { new(): T; }, uriParams?: any[], payload?: T['requestPayloadInterface'],
    query?: T['queryInterface'], order?: T['orderableInterface'][], page?: ApiPagenation)
    : Observable<{ body: T['responseBodyInterface'], code: number, msg: string, hasError: boolean }> => {
    const t = new apiDefType();

    // URIの組み立て(プリフィックスおよびパラメーター)
    let uri = environment.apiRoot + t.uri;
    for (let i = 0; i < uriParams.length; i++) {
      uri = uri.replace(`{${i}}`, uriParams[i]);
    }

    // 認証(未実装。ひとまず現有のトークンを取り出すだけにする。)
    const token = this.authRepository.token;

    let headers = {};
    headers["Authorization"] = 'JWT ' + token

    switch (t.method) {
      case Method.GET:
        let params = {};
        // クエリの組み立て(スネークケース変換・クエリストリング化)
        if (query) {
          for (const key of Object.keys(query)) {
            const styledKey = _.snakeCase(key);
            const val = query[key];
            if (val instanceof Date) {
              params[styledKey] = val.toISOString();
            } else if (this.isPrimitive(val)) {
              // プリミティブ型なら変換しない
              params[styledKey] = val.toString();
            } else if (Array.isArray(val)) {
              // 配列の場合は id(multi) とみなして　'|' で結合する
              params[styledKey] = val.join('|');
            } else {
              console.warn(`Cannot use ${JSON.stringify(val)} as query string.`);
            }
          }
        }
        // オーダーの組み立て(スネークケース変換・クエリストリング化)
        if (order) {
          let orderString = '';
          let isFirst = true;
          for (const elm of order) {
            const keys = Object.keys(elm);
            if (keys.length === 0) {
              console.warn('The number of key of each order element must be 1.');
              continue;
            }
            const key = keys[0];  // 2番目以降のキーは無視するよ
            const isAsc = elm[key];
            const comma = isFirst ? '' : ',';
            const prefix = isAsc ? '' : '-';
            isFirst = false;
            orderString += (comma + prefix + _.snakeCase(key));
          }
          if (orderString) {
            params['ordering'] = orderString;
          }
        }
        // ページの組み立て(クエリストリング化)
        if (page) {
          if (page.page) {
            params['page'] = page.page.toString();
          }
          if (page.pageSize) {
            params['page_size'] = page.pageSize.toString();
          }
        }
        this.increment();
        return this.http.get(uri, { params: params, headers: headers, observe: 'response' })
        .pipe(map((res) => this.mapApiResult(res, t.getMessage(res.status))), 
        catchError(res => of(this.mapApiResult(res, t.getMessage(res.status)))));
      case Method.POST:
        headers["Content-Type"] = 'application/json';
        this.increment();
        return this.http.post(uri, JSON.stringify(this.localizeKeys(payload, _.snakeCase)), { headers: headers, observe: 'response' })
        .pipe(map((res) => this.mapApiResult(res, t.getMessage(res.status))),
        catchError(res => of(this.mapApiResult(res, t.getMessage(res.status)))));
      case Method.PUT:
        headers["Content-Type"] = 'application/json';
        this.increment();
        return this.http.put(uri, JSON.stringify(this.localizeKeys(payload, _.snakeCase)), { headers: headers, observe: 'response' })
        .pipe(map((res) => this.mapApiResult(res, t.getMessage(res.status))),
        catchError(res => of(this.mapApiResult(res, t.getMessage(res.status)))));
      case Method.DELETE:
        this.increment();
        return this.http.delete(uri, { headers: headers, observe: 'response' })
          .pipe(map((res) => this.mapApiResult(res, t.getMessage(res.status))),
          catchError(res => of(this.mapApiResult(res, t.getMessage(res.status)))));
    }
  }
}
