import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceTypeGetApi, CommunicationType } from '../common/api/device-type';

@Component({
  selector: 'app-device-type',
  templateUrl: './device-type.component.html'
})
export class DeviceTypeComponent implements OnInit {

  /** タブのリンク情報 */
  tabLinks: Array<{ path: any[], label: string, options?: object }>;

  /** 本コンポーネントが表示しているデバイスタイプ情報 */
  deviceType: DeviceTypeGetApi['responseBodyInterface'];

  // 表示をわかりやすくするためのタブ名（本来はいらないので実装が進んだら削除する）
  tabName: string;

  constructor(public t: TranslateService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(obj => {
      this.deviceType = obj['deviceType'];
      this.tabLinks = [
        {
          path: ['/device_types', this.deviceType.id],
          label: 'title_detail',
          options: { exact: true }
        },
        {
          path: ['/device_types', this.deviceType.id, 'alert_defs'],
          label: 'title_alert_edit'
        }];
      /* 通信方式がLPWA系の時だけLPWA設定タブを表示する */
      if (CommunicationType.isLpwa(this.deviceType.communicationType)) {
        this.tabLinks.push({
          path: ['/device_types', this.deviceType.id, 'lpwa_packets'],
          label: 'title_lpwa_settings'
        });
      }
    });
  }

}
