import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SelectOption　} from '../../common/interfaces/display-interfaces';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { GrouspAddApi } from '../../common/api/group';
import { ApiHandler } from '../../services/api-handler.service';

@Component({
  moduleId: module.id,
  templateUrl: 'groups-add.component.html',
  providers: [GrouspAddApi]
})

export class GroupsAddComponent implements OnInit {
  // DropDown
  groupRoots: SelectOption[] = [];
  selectedGroupRoot: object;
  display_name: string;
  message: string;
  isOk = false;
  // 入力フォーム
  root: FormControl;
  displayName: FormControl;
  groupsForm: FormGroup;
  sshActive: FormControl;
  constructor(
    public t: TranslateService,
    public bsModalRef: BsModalRef,
    public apiHandler: ApiHandler,
    public grouspAddApi: GrouspAddApi,
    private builder: FormBuilder) { }

  /**
   * グループ登録
  */
  groupsAdd() {
    const entity = this.toGroupsEntity();
    this.apiHandler.call(true, GrouspAddApi, [], entity)
      .subscribe(
        res => {
          // 登録成功の場合は登録完了フラグONにして画面を閉じる
          this.isOk = true;
          this.bsModalRef.hide();
        });
  }

  // 入力フォームの入力をグループのエンティティに変換する
  private toGroupsEntity(): GrouspAddApi['requestPayloadInterface'] {
    return {
      rootId: this.root.value,
      displayName: this.displayName.value,
      sshActive: this.sshActive.value,
    };
  }

  ngOnInit() {
    this.message = '';
    // 入力フォーム
    this.root = new FormControl('', Validators.required);
    this.displayName = new FormControl('', [
      Validators.required,
      Validators.maxLength(50)
    ]);
    this.groupsForm = this.builder.group({
      root: this.root,
      displayName: this.displayName
    });
    this.sshActive = new FormControl(true);
  }
}

