import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DeviceDetail, HasAlert } from '../common/api/device';
import { IconDef } from '../common/interfaces/display-interfaces';
import { AlertSubscriptionStatus, AlertSubscriptionToggleAddApi } from '../common/api/alert-subscription';
import { ApiHandler } from '../services/api-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../services/dialog.service';
import {DeviceMuteStatus, DeviceMuteToggleAddApi} from '../common/api/device-mute';

@Component({
  moduleId: module.id,
  templateUrl: 'device.component.html'
})
export class DeviceComponent implements OnInit {
  /** タブのリンク情報 */
  tabLinks: Array<{ path: any[], label: string, options?: object }>;

  /** デバイス詳細系画面で表示するデバイス情報 */
  device: DeviceDetail & {
    hasAlertIcon: IconDef;
    isSubscribedIcon: IconDef;
    isMutedIcon: IconDef;
  };

  public modalRef: BsModalRef;
  constructor(
    private t: TranslateService,
    private route: ActivatedRoute,
    private apiHandler: ApiHandler,
    private dialogService: DialogService) { }

  ngOnInit(): void {
    this.route.data.subscribe(obj => {
      const device = obj['device'] as DeviceDetail;
      this.device = {
        ...device,
        hasAlertIcon: HasAlert.toIcon(device.hasAlert),
        isSubscribedIcon: AlertSubscriptionStatus.toIcon(device.isSubscribed),
        isMutedIcon: DeviceMuteStatus.toIcon(device.isMuted)
      };
      this.tabLinks = [
        {
          path: ['/devices', this.device.id],
          label: 'title_detail',
          options: { exact: true }
        },
        {
          path: ['/devices', this.device.id, 'prop_logs'],
          label: 'title_prop_log'
        },
        {
          path: ['/devices', this.device.id, 'edit'],
          label: 'title_device_edit'
        },
        {
          path: ['/devices', this.device.id, 'alert_defs'],
          label: 'title_alert_edit'
        },
        {
          path: ['/devices', this.device.id, 'remote'],
          label: 'title_device_remote'
        }];
    });
  }

  /**
   * アラート購読切り替えボタン
   */
  clickAlertToggle() {
    const payload = { deviceId: this.device.id };
    this.apiHandler.call(true, AlertSubscriptionToggleAddApi, [], payload)
      .subscribe(res => {
        if (!res.hasError) {
          // 当該モデルの値を更新
          this.device.isSubscribed = !this.device.isSubscribed;
          this.device.isSubscribedIcon = AlertSubscriptionStatus.toIcon(this.device.isSubscribed);
        }
      });
  }

  /**
   * デバイスミュート切り替えボタン
   */
  clickDeviceToggle() {

    let msg_confirm_key = 'msg_confirm_mute_on';
    if (this.device.isMuted) {
      msg_confirm_key = 'msg_confirm_mute_off';
    }
    this.dialogService.show(this.t.instant('title_confirmation'),
      this.t.instant(msg_confirm_key, {'deviceDisplayName': this.device.displayName}))
      .subscribe(ok => {
        if (ok) {
          // ミュート切り替え start
          const payload = {
            deviceId: this.device.id,
            isMuted: !this.device.isMuted
          };
          this.apiHandler.call(true, DeviceMuteToggleAddApi, [], payload)
            .subscribe(res => {
              if (!res.hasError) {
                // 当該モデルの値を更新
                this.device.isMuted = !this.device.isMuted;
                this.device.isMutedIcon = DeviceMuteStatus.toIcon(this.device.isMuted);
              }
            });
          // ミュート切り替え end
        }
      });
  }
}
