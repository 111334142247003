import { NgModule } from '@angular/core';
import { EditableTextComponent } from './editable-text.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppMaterialModule } from '../common/app-material.module';
import { NoteComponent } from './note/note.component';
import { AlertDefEditComponent } from './alert-def/alert-def-edit.component';
import { TimeSeriesChartComponent } from './chart/time-series-chart.component';
import { MapComponent } from './map/map.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ChartsModule } from 'ng2-charts';
import { DeviceAlertDefEditComponent } from './alert-def/device-alert-def-edit.component';

/**
 * 画面部品系のコンポーネントをまとめたモジュール。
 *
 * 複数画面で画面部品を共用するために定義している。
 */
@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule,
    ChartsModule,
    AppMaterialModule,
    BsDatepickerModule,
    TimepickerModule,
    TranslateModule,
  ],
  exports: [
    EditableTextComponent,
    NoteComponent,
    AlertDefEditComponent,
    DeviceAlertDefEditComponent,
    TimeSeriesChartComponent,
    MapComponent,
    TranslateModule,
  ],
  declarations: [
    EditableTextComponent,
    NoteComponent,
    AlertDefEditComponent,
    DeviceAlertDefEditComponent,
    TimeSeriesChartComponent,
    MapComponent,
  ]
})
export class PartsComponentModule { }
