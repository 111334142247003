import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DeviceTypeComponent } from './device-type.component';
import { DeviceTypeRoutingModule } from './device-type-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetailComponent } from './detail/detail.component';
import { PartsComponentModule } from '../parts/parts-component.module';
import { PropertyDefEditComponent } from './modal/property-def-edit.component';
import { LpwaPacketsComponent } from './lpwa-packets/lpwa-packets.component';
import { LpwaPacketTypeComponent } from './lpwa-packets/lpwa-packet-type.component';
import { LpwaPacketTypeEditComponent } from './modal/lpwa-packet-type-edit.component';
import { LpwaPacketDecodeRowComponent } from './lpwa-packets/lpwa-packet-decode-row.component';
import { AlertDefsComponent } from './alert-defs/alert-defs.components';
import { AppMaterialModule } from '../common/app-material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    PartsComponentModule,
    DeviceTypeRoutingModule,
    AppMaterialModule,
    TranslateModule,
  ],
  declarations: [
    DeviceTypeComponent,
    DetailComponent,
    PropertyDefEditComponent,
    AlertDefsComponent,
    LpwaPacketsComponent,
    LpwaPacketTypeComponent,
    LpwaPacketTypeEditComponent,
    LpwaPacketDecodeRowComponent
  ],
  providers: [
  ],
  bootstrap: [
    DeviceTypeComponent
  ],
  entryComponents: [
    PropertyDefEditComponent,
    LpwaPacketTypeEditComponent
  ]
})
export class DeviceTypeModule { }

