import {Component, OnInit} from '@angular/core';
import { ApiHandler } from '../services/api-handler.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiPagenation } from '../common/api/sip-api-client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location, DatePipe} from '@angular/common';
import { DeviceTypeListApi } from '../common/api/device-type';
import { SelectOption　} from '../common/interfaces/display-interfaces';
import { PageableSearchCondition } from '../common/view/PageableSearchCondition';
import { QueryStoreService } from '../services/query-store.service';
import {AlertOutputListApi} from '../common/api/alert-output';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DialogService } from '../services/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import { Functions } from '../common/functions';
import {DeviceListApi} from '../common/api/device';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  moduleId: module.id,
  templateUrl: 'alert-output.component.html',
  styleUrls: ['./alert-output.component.css']
})

export class AlertOutputComponent implements OnInit {

  constructor(
    public apiHandler: ApiHandler,
    public modalService: BsModalService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private queryStore: QueryStoreService,
    public datepipe: DatePipe,

    public dialogService: DialogService,
    public t: TranslateService,
    public functions: Functions,
    public builder: FormBuilder
  ) {
  }

  // form チェック用
  form: FormGroup;
  deviceTypeIdForm: FormControl;
  deviceIdForm: FormControl;
  startForm: FormControl;
  endForm: FormControl;
  startTimeForm: FormControl;
  endTimeForm: FormControl;

  // 検索条件
  condition: AlertOutputSearchCondition;

  // interfaceModel
  alertOutputResults: Array<{
    item1: string,
    item2: string,
    item3: string,
    item4: string,
    item5: string,
    item6: string,
    item7: string,
    item8: string
  }> = [];

  // DropDown
  dropdownDeviceTypes: SelectOption[] = [];
  dropdownDevices: SelectOption[] = [];

  // Pagination
  count: number;  // 全件数

  // Chart設定
  minDate = new Date(1800, 0, 1, 0, 0, 0);
  maxDate = new Date(2200, 11, 31, 23, 59, 59);

  /**
   * CSVダウンロード
   */
  downloadCsv() {

    // condition設定
    this.condition.deviceTypeId = this.deviceTypeIdForm.value;
    this.condition.deviceId = this.deviceIdForm.value;
    this.condition.start = this.startForm.value;
    this.condition.end = this.endForm.value;

    // 検索クエリ
    const query = this.condition.query;
    // アラート出力一覧検索
    this.apiHandler.call(false, AlertOutputListApi, [], undefined, query, [])
      .subscribe(res => {
        if (!res.hasError) {

          // 検索条件を保存
          this.queryStore.storeQuery('alert_output', this.condition.queryParams());

          // エラー判断
          if (res.body.count === 0) {
            this.functions.showToastMessage(this.t.instant(`msg_faild_search_zero`, {
            }), 'error');
            return;
          }
          if (res.body.count > 10000) {
            this.functions.showToastMessage(this.t.instant(`msg_faild_search_max`, {
            }), 'error');
            return;
          }

           // データ取得
          const alertOutputs = res.body;
          this.alertOutputResults = alertOutputs.results.map(alertOutput => {
            return {
              item1: alertOutput.deviceTypeDisplayName,
              item2: alertOutput.deviceDisplayName,
              item3: alertOutput.title,
              item4: this.datepipe.transform(alertOutput.startDatetime, 'yyyy/MM/dd HH:mm:ss'),
              item5: alertOutput.alertType,
              item6: alertOutput.propDisplayName,
              item7: alertOutput.propValue,
              item8: alertOutput.detail.replace(/\r?\n/g, '')
            };
          });

          // データ出力
          let startTime = '';
          let endTime = '';
          if (this.condition.start) {
            startTime = this.datepipe.transform(this.condition.start, 'yyyy/MM/dd HH:mm:ss');
          }
          if (this.condition.end) {
            endTime = this.datepipe.transform(this.condition.end, 'yyyy/MM/dd HH:mm:ss');
          }
          const options = {
            title: this.t.instant(`csv_get_data_time`) + '：' + startTime + '-' + endTime,
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: true,
            useBom: true,
            headers: [
              this.t.instant(`csv_alert_head_1`),
              this.t.instant(`csv_alert_head_2`),
              this.t.instant(`csv_alert_head_3`),
              this.t.instant(`csv_alert_head_4`),
              this.t.instant(`csv_alert_head_5`),
              this.t.instant(`csv_alert_head_6`),
              this.t.instant(`csv_alert_head_7`),
              this.t.instant(`csv_alert_head_8`),
            ]
          };
          const filename = this.datepipe.transform(new Date(), 'yyyyMMdd_HHmmss');
          const angularCsv = new AngularCsv(this.alertOutputResults, filename, options);
        }
      });
  }

  /**
   * デバイスタイプ一覧取得
   */
  private searchDeviceTypes() {
    const pagination: ApiPagenation = {
      pageSize: 10000,  // 1ページに全デバイスタイプが収まるように、ページサイズを最大にする
    };
    // デバイスタイプ一覧取得API呼び出し
    this.apiHandler.call(false, DeviceTypeListApi, [], undefined, {}, [], pagination)
      .subscribe(
        res => {
          const deviceTypes = res.body;
          // ドロップダウンの選択肢を更新する
          this.dropdownDeviceTypes = deviceTypes.results.map(deviceType => {
            return { value: deviceType.id, name: deviceType.displayName };
          });
        });
  }

  /**
   * デバイス一覧取得
   */
  private searchDevices() {
    const pagination: ApiPagenation = {
      pageSize: 10000,  // 1ページに全デバイスが収まるように、ページサイズを最大にする
    };
    // デバイス一覧取得API呼び出し
    this.apiHandler.call(false, DeviceListApi, [], undefined, {}, [], pagination)
      .subscribe(
        res => {
          const devices = res.body;
          // ドロップダウンの選択肢を更新する
          this.dropdownDevices = devices.results.map(device => {
            return { value: device.id, name: device.displayName };
          });
        });
  }

  /**
  * 初期化処理
  */
  ngOnInit(): void {
    // チェック用
    this.deviceTypeIdForm = new FormControl(null, Validators.required);
    this.deviceIdForm = new FormControl(null);
    this.startForm = new FormControl(null, Validators.required);
    this.endForm = new FormControl(null, Validators.required);
    this.startTimeForm = new FormControl(null, Validators.required);
    this.endTimeForm = new FormControl(null, Validators.required);

    this.form = this.builder.group({
      deviceTypeIdForm: this.deviceTypeIdForm,
      deviceIdForm: this.deviceIdForm,
      startForm: this.startForm,
      endForm: this.endForm,
      startTimeForm: this.startTimeForm,
      endTimeForm: this.endTimeForm,
    });

    // 検索条件初期化
    this.condition = new AlertOutputSearchCondition();

    /* クエリ文字列 or 永続化された検索条件で検索条件入力フォームを更新する */
    let activeQuery: Params = {};
    const params = this.route.snapshot.queryParams;  // クエリ文字列
    if (Object.keys(params).length === 0) {
      // クエリ文字列が空の場合は保存されている検索条件を取得する
      const storedQuery = this.queryStore.getQuery('alert_output');
      activeQuery = storedQuery;
    } else {
      activeQuery = params;
    }
    // 入力情報より、conditionの値を設定する
    this.condition.update(activeQuery);

    // form設定
    this.deviceTypeIdForm.setValue(this.condition.deviceTypeId);
    this.deviceIdForm.setValue(this.condition.deviceId);
    this.startForm.setValue(this.condition.start);
    this.endForm.setValue(this.condition.end);
    if (this.startForm.value === null) {
      this.startTimeForm.setValue(new Date(2000, 0, 1, 0, 0, 0, 0));
      this.endTimeForm.setValue(new Date(2000, 0, 1, 23, 59, 59, 0));
    } else {
      this.startTimeForm.setValue(this.condition.start);
      this.endTimeForm.setValue(this.condition.end);
    }

    this.startTimeForm.valueChanges.subscribe( (value: string) => {
      if (value === null) {
        this.startTimeForm.setValue(null, {onlySelf: true, emitEvent: false});
        return;
      }
      const timeDate = new Date(value);
      let tmpDate = new Date();
      if (this.startForm.value !== null) {
        tmpDate = new Date(this.startForm.value);
      }
      tmpDate.setHours(timeDate.getHours());
      tmpDate.setMinutes(timeDate.getMinutes());
      tmpDate.setSeconds(timeDate.getSeconds());
      this.startForm.setValue(tmpDate);
    });

    this.endTimeForm.valueChanges.subscribe( (value: string) => {
      if (value === null) {
        this.endTimeForm.setValue(null, {onlySelf: true, emitEvent: false});
        return;
      }
      const timeDate = new Date(value);
      let tmpDate = new Date();
      if (this.endForm.value !== null) {
        tmpDate = new Date(this.endForm.value);
      }
      tmpDate.setHours(timeDate.getHours());
      tmpDate.setMinutes(timeDate.getMinutes());
      tmpDate.setSeconds(timeDate.getSeconds());
      this.endForm.setValue(tmpDate);
    });

    // デバイスタイプ取得
    this.searchDeviceTypes();
    // デバイス取得
    this.searchDevices();

  }

  /**
   * 日時の年月日を設定する。
   *
   * @param value Datepickerで更新後の日付。
   * @param target 更新する対象となる日付プロパティ。'start', 'end'のいずれか。
   */
  updateDate(value: Date, target: 'start' | 'end') {
    if (target === 'start') {
      if (value === null) {
        this.startForm.setValue(null, {onlySelf: true, emitEvent: false});
        return true;
      }

      const tmpDate = new Date(this.startForm.value);
      if (this.startTimeForm.value !== null) {
        const timeDate = new Date(this.startTimeForm.value);
        tmpDate.setHours(timeDate.getHours());
        tmpDate.setMinutes(timeDate.getMinutes());
        tmpDate.setSeconds(timeDate.getSeconds());
        this.startForm.setValue(tmpDate, {onlySelf: true, emitEvent: false});
      } else {
        tmpDate.setHours(0);
        tmpDate.setMinutes(0);
        tmpDate.setSeconds(0);
        this.startTimeForm.setValue(tmpDate, {onlySelf: true, emitEvent: false});
        this.startForm.setValue(tmpDate, {onlySelf: true, emitEvent: false});
      }
    } else if (target === 'end') {
      if (value === null) {
        this.endForm.setValue(null, {onlySelf: true, emitEvent: false});
        return true;
      }

      const tmpDate = new Date(this.endForm.value);
      if (this.endTimeForm.value !== null) {
        const timeDate = new Date(this.endTimeForm.value);
        tmpDate.setHours(timeDate.getHours());
        tmpDate.setMinutes(timeDate.getMinutes());
        tmpDate.setSeconds(timeDate.getSeconds());
        this.endForm.setValue(tmpDate, {onlySelf: true, emitEvent: false});
      } else {
        tmpDate.setHours(23);
        tmpDate.setMinutes(59);
        tmpDate.setSeconds(59);
        this.endTimeForm.setValue(tmpDate, {onlySelf: true, emitEvent: false});
        this.endForm.setValue(tmpDate, {onlySelf: true, emitEvent: false});
      }
    }
  }

  clear() {
    this.deviceTypeIdForm.setValue('');
    this.deviceIdForm.setValue('');
    this.startTimeForm.setValue(new Date(2000, 0, 1, 0, 0, 0, 0),
      {onlySelf: true, emitEvent: false});
    this.endTimeForm.setValue(new Date(2000, 0, 1, 23, 59, 59, 0),
      {onlySelf: true, emitEvent: false});
    this.startForm.setValue(null, {onlySelf: true, emitEvent: false});
    this.endForm.setValue(null, {onlySelf: true, emitEvent: false});
  }
}

/** アラート出力の検索条件を表すクラス */
class AlertOutputSearchCondition extends PageableSearchCondition<AlertOutputListApi> {

  constructor(
    public deviceTypeId: number[] = [],
    public deviceId: number[] = [],
    public start: Date | null = null,
    public end: Date| null = null,
  ) {
    super();
  }

  get query(): AlertOutputListApi['queryInterface'] {
    const query: AlertOutputListApi['queryInterface'] = {};
    if (!this.isEmpty(this.deviceTypeId)) {
      query.deviceTypeId = this.deviceTypeId;
    }
    if (!this.isEmpty(this.deviceId)) {
      query.deviceId = this.deviceId;
    }
    if (this.start) {
      query.start = this.start;
    }
    if (this.end) {
      query.end = this.end;
    }
    return query;
  }

  /** クエリパラメータに応じて条件を更新する */
  update(params: Params): void {
    super.update(params);
    if (!this.isEmpty(params['device_id'])) {
      this.deviceId = params['device_id'].split('|').map(elm => Number(elm));
    }
    if (!this.isEmpty(params['device_type_id'])) {
      this.deviceTypeId = params['device_type_id'].split('|').map(elm => Number(elm));
    }
    if (!this.isEmpty(params['start'])) {
      this.start = params['start'];
    }
    if (!this.isEmpty(params['end'])) {
      this.end = params['end'];
    }
  }
}

