import { Method } from '../enums/api-enums';
import { ApiStatusMessage, ApiDef } from './sip-api-client';

/**
 * LPWAパケットタイプ登録APIの定義
 */
export class LpwaPacketTypeAddApi extends ApiDef {
  requestPayloadInterface: {
    deviceTypeId: number;
    packetType: number;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/lpwa_packet_types';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
      { code: 201, message: 'toast_registered_lpwa_packet_type' },
      { code: 400, message: 'toast_input_value_invalid' },
      { code: 409, message: 'toast_lpwa_packet_type_already_exists' }
  ];
}

/**
 * LPWAパケットタイプ更新APIの定義
 */
export class LpwaPacketTypeUpdateApi extends ApiDef {
  requestPayloadInterface: {
    packetType: number;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/lpwa_packet_types/{0}';
  method = Method.PUT;
  statusMsgList: ApiStatusMessage[] = [
      { code: 204, message: 'toast_updated_lpwa_packet_type' },
      { code: 400, message: 'toast_input_value_invalid' },
      { code: 404, message: 'toast_lpwa_packet_type_not_found' },
      { code: 409, message: 'toast_lpwa_packet_type_already_exists' }
  ];
}

/**
 * LPWAパケットタイプ削除APIの定義
 */
export class LpwaPacketTypeDeleteApi extends ApiDef {
  requestPayloadInterface: {
    deviceTypeId: number;
    packetType: number;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/lpwa_packet_types/{0}';
  method = Method.DELETE;
  statusMsgList: ApiStatusMessage[] = [
      { code: 204, message: 'toast_deleted_lpwa_packet_type' },
      { code: 404, message: 'toast_lpwa_packet_type_not_found' }
  ];
}

/**
 * LPWAパケット解読登録APIの定義
 */
export class LpwaPacketDecodeAddApi extends ApiDef {
  requestPayloadInterface: {
    lpwaPacketTypeId: number;
    propertyDefId: number;
    startBit: number;
    dataBitLength: number;
    dataType: LpwaDataType;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/lpwa_packet_decoders';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
      { code: 201, message: 'toast_registered_lpwa_packet_decode' },
      { code: 400, message: 'toast_input_value_invalid' },
      { code: 409, message: 'toast_lpwa_packet_decode_already_exists' }
  ];
}

/**
 * LPWAパケット解読更新APIの定義
 */
export class LpwaPacketDecodeUpdateApi extends ApiDef {
  requestPayloadInterface: {
    propertyDefId: number;
    startBit: number;
    dataBitLength: number;
    dataType: LpwaDataType;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/lpwa_packet_decoders/{0}';
  method = Method.PUT;
  statusMsgList: ApiStatusMessage[] = [
      { code: 204, message: 'toast_updated_lpwa_packet_decode' },
      { code: 400, message: 'toast_input_value_invalid' },
      { code: 404, message: 'toast_lpwa_packet_decode_not_found' },
      { code: 409, message: 'toast_lpwa_packet_decode_already_exists' }
  ];
}

/**
 * LPWAパケット解読削除APIの定義
 */
export class LpwaPacketDecodeDeleteApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/lpwa_packet_decoders/{0}';
  method = Method.DELETE;
  statusMsgList: ApiStatusMessage[] = [
      { code: 204, message: 'toast_deleted_lpwa_packet_decode' },
      { code: 404, message: 'toast_lpwa_packet_decode_not_found' }
  ];
}

export interface LpwaPacketType {
  id: number;
  packetType: number;
  decoders: LpwaPakcetDecode[];
}

export interface LpwaPakcetDecode {
  id: number;
  propertyDefId: number;
  startBit: number;
  dataBitLength: number;
  dataType: LpwaDataType;
}

export type LpwaDataType = 'int16' | 'float32' | 'string';
export namespace LpwaDataType {
  const mapping = {
    'int16': 'title_int16',
    'float32': 'title_float32',
    'string': 'title_string',
  };

  export const values = (): LpwaDataType[] => {
    return Object.keys(mapping) as LpwaDataType[];
  };

  export const toName = (type: LpwaDataType): string => {
    const name = mapping[type];
    if (name == null) {
      throw new Error(`Invalid lpwa packet type: ${type}.`);
    }
    return name;
  };
}
