<h2>{{'title_device_group_list' | translate}}</h2>
<div class="row">
  <div class="col-sm-12">
    <table class="table table-bordered table-hover tableBreakAll">
      <colgroup>
        <col style="width:3%">
        <col style="width:57%">
        <col style="width:40%">
      </colgroup>
      <thead>
        <tr>
          <th class="checkboxth">
            <mat-checkbox [checked]="allchecked" (change)="cbAll($event.checked)"></mat-checkbox>
          </th>
          <th *ngFor="let columns of columnList">
            <label class="labelth" *ngIf="!columns.issort" id={{columns.id}}>
              {{columns.displayName | translate}}
            </label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of deviceGroupsResults" class="active">
          <td>
            <mat-checkbox [(ngModel)]="result.isChecked"></mat-checkbox>
          </td>
          <div *ngIf="isLinkShow(result.groupId); then LinkTd; else TextTd;"></div>
          <ng-template #LinkTd>
            <td [attr.data-label]="columnList[0].displayName">
              <a [routerLink]="['/device_groups', result.id]">{{result.displayName}}</a>
            </td>
          </ng-template>
          <ng-template #TextTd>
            <td [attr.data-label]="columnList[0].displayName"> {{result.displayName}} </td>
          </ng-template>
          <td [attr.data-label]="columnList[1].displayName">{{result.groupDisplayName}}</td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="isNoData" class="col-sm-12" style="margin-bottom:2em;">
      <app-note role="info">{{'msg_no_data_to_display' | translate}}</app-note>
    </div>
    <div class="row">
      <div class="col-sm-6 col-sm-offset-3 text-center">
        <pagination *ngIf="deviceGroupsResults.length > 0" [totalItems]="count | async " [itemsPerPage]="itemsPerPage" [maxSize]="8" [(ngModel)]="currentPage"
          [nextText]="'pagination_next' | translate" [previousText]="'pagination_prev' | translate" (pageChanged)="pageChanged($event)">
        </pagination>
      </div>
      <div class="col-sm-3 text-right">
        <button mat-raised-button color="primary" (click)="openGroupsAdd()">
          <mat-icon aria-hidden="true">open_in_new</mat-icon> {{'title_add' | translate}}
        </button>
        <button mat-raised-button color="warn" (click)="clickDelete()" [disabled]="!isDeletable">
          <mat-icon aria-hidden="true">delete</mat-icon> {{'title_delete' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
