import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from '../../services/data.service';
import { AlertlogResults } from '../../models/alertlogs.results.model';
import { AlertLog } from '../../common/api/alert-log';
import { IconDef } from '../../common/interfaces/display-interfaces';

@Component({
  moduleId: module.id,
  templateUrl: 'alertdetail.component.html'
})
export class AlertDetailComponent implements OnInit {
  alert: AlertLog &
    {
      mailStatusName: string,
      mailStatusIcon: IconDef,
    } = <AlertLog &
    {
      mailStatusName: string,
      mailStatusIcon: IconDef,
    }>{};
  isOk = false;

  constructor(public bsModalRef: BsModalRef, public dataService: DataService) { }


  ngOnInit() {
  }
}
