import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Functions } from '../common/functions';
import { ApiHandler } from '../services/api-handler.service';
import { WebStorageAuthRepository } from '../services/auth-repository.service';
import { ConfirmComponent } from '../common/messages/confirm.component';
import { FormControl } from '@angular/forms';
import { GroupMemberDeleteApi, GroupUpdateApi, GroupInfoGetApi, Members, GroupRole, RootUsers } from '../common/api/group';
import { GroupMemberAddComponent } from './modal/group-member-add.component';
import { GroupMemberUpdateComponent } from './modal/group-member-update.component';
import { EditableTextComponent } from '../parts/editable-text.component';
import { SelectOption　} from '../common/interfaces/display-interfaces';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
  moduleId: module.id,
  templateUrl: 'groupdetail.component.html',
  providers: [GroupUpdateApi, GroupMemberAddComponent, GroupMemberUpdateComponent, BsModalRef]
})

export class GroupDetailComponent implements OnInit {
  public modalRef: BsModalRef;
  groupRoles: GroupRole[];
  memberRolesList: string;
  isRootUser: boolean;
  isNoData: boolean = false;
  addMessage: string = 'role_super_user';
  superUserId: number[] = [];

  // DropDown
  dropdownRoles: SelectOption[] = [];
  groupInfo: GroupInfoGetApi['responseBodyInterface'];

  memberResult: Members[] = [];

  columnList = [
    { seq: 1, id: 'userDisplayName', displayName: 'title_user_name', issort: false },
    {
      seq: 2, id: 'displayName', displayName: 'title_roles', issort: false
    },
    { seq: 3, id: 'edditDelete', displayName: '', issort: false },
  ];

  @ViewChild('displayName', {static: false}) private displayName: EditableTextComponent;
  displayNameControl: FormControl;
　sshActiveControl: FormControl;

  constructor(
    public t: TranslateService,
    public functions: Functions,
    private route: ActivatedRoute,
    private router: Router,
    private apiHandler: ApiHandler,
    private modalService: BsModalService,
    public authRepo: WebStorageAuthRepository,
  ) { }

  updateDisplayName(event: any) {
    this.update({displayName: event, sshActive: this.sshActiveControl.value, is_ssh_update: false});
  }

  updateSshActive(event: MatSlideToggleChange) {
    this.update({displayName: this.displayNameControl.value, sshActive: this.sshActiveControl.value, is_ssh_update: true});
  }

  /** グループ名更新 */
  private update(request: { displayName: any; sshActive: any; is_ssh_update: any }) {
    this.apiHandler.call(true, GroupUpdateApi, [this.groupInfo.id], request)
      .subscribe(res => {
        if (!res.hasError) {
          // グループ名を更新して編集を終了
          if (!request.is_ssh_update) {
            this.groupInfo.displayName = request.displayName;
            this.displayName.finish();
          }
        }
      });
  }

  /**
   * ロール割り当て画面表示
   */
  clickUpdateRole(event: any, userDisplayName: string, userId: number, memberRoles: GroupRole[]) {
    // ロールのリスト作成
    const roleList = [];
    for (let i = 0; i < this.groupInfo.groupRoles.length; i++) {
      roleList.push({ value: this.groupInfo.groupRoles[i].id, name: this.groupInfo.groupRoles[i].displayName });
    }
    const name = userDisplayName;
    const modalRef = this.modalService.show(GroupMemberUpdateComponent, { class: 'modal-dialog modal-lg', backdrop: 'static' });
    modalRef.content.initGroupMemberUpdateModal(false, 'title_role_assignment', null, this.groupInfo.id, roleList,
      name, memberRoles, userId);
    this.modalService.onHide.subscribe(() => {
      if (modalRef.content.isOk) {
        modalRef.content.isOk = false;
        this.reloadGroupDetail();
      }
    });
  }

  /**
   * データ削除
   */
  clickDelete(event: any, userDisplayName: string, userId: number, members: Members) {
    // 確認ダイアログ
    const addmessages: Array<object> = [];
    addmessages.push({ message: this.t.instant(this.columnList[0].displayName) + '：' + userDisplayName });
    addmessages.push({ message: this.t.instant(this.columnList[1].displayName) + '：' + this.memberRoleListString(members) });

    const modalRef = this.modalService.show(ConfirmComponent, { backdrop: 'static' });
    modalRef.content.title = this.t.instant('title_confirmation');
    modalRef.content.messageHead = this.t.instant('msg_confirm_unassign_member');
    modalRef.content.messageDetail = addmessages;

    this.modalService.onHide
      .subscribe(() => {
        if (modalRef.content.isOk) {
          const apiKeysObservable = this.apiHandler.call(true, GroupMemberDeleteApi, [this.groupInfo.id, userId], [], {}, [], {});
          apiKeysObservable
            .subscribe(res => {
                modalRef.content.isOk = false;
                this.reloadGroupDetail();
              },
              error => {
                console.log(error);
              });

        }
      });
  }

  memberRoleListString(members: Members): string {
    let roleDisplayName = '';
    for (let i = 0; i < members.roles.length; i++) {
      if (0 === roleDisplayName.length) {
        roleDisplayName = members.roles[i].displayName;
      } else {
        roleDisplayName += '，' + members.roles[i].displayName;
      }
    }
    if (roleDisplayName.search(this.addMessage) !== -1) {
      // ロールは「スーパーユーザー」のみ表示
      roleDisplayName = this.addMessage;
    }
    return roleDisplayName;
  }

  /**
   * グループ名編集ボタン活性化状態
   */
  get canEdit(): boolean {
    this.isRootUser = true;
    return this.isRootUser;
  }

  /**
   * 追加ボタン、編集ボタン、削除ボタン活性状態
  */
  get canAddDelete(): boolean {
    // グループ編集権限をもつ場合に活性化
    return this.authRepo.hasPermission('group', 'edit');
  }

  /**
   * メンバー登録画面表示
   */
  openGroupMemberAdd() {
    // ロールのリスト作成
    const roleList = [];
    for (let i = 0; i < this.groupInfo.groupRoles.length; i++) {
      roleList.push({ value: this.groupInfo.groupRoles[i].id, name: this.groupInfo.groupRoles[i].displayName });
    }

    const modalRef = this.modalService.show(GroupMemberAddComponent, { class: 'modal-dialog modal-lg', backdrop: 'static' });
    modalRef.content.initGroupMemberAddModal(true, 'title_member_assignment', null, this.groupInfo.id, roleList);
    this.modalService.onHide.subscribe(() => {
      if (modalRef.content.isOk) {
        modalRef.content.isOk = false;
        this.reloadGroupDetail();
      }
    });
  }

  /**
   * 同一ルートに遷移して、グループ詳細情報を取得、再描画する
   */
  private reloadGroupDetail() {
    const superUserId = [];
    const memberResult = [];
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
    this.apiHandler.call(false, GroupInfoGetApi, [this.groupInfo.id], {}, {}, [])
      .subscribe(res => {
        this.groupInfo = res.body;

        const workRootUsers: RootUsers[] = this.groupInfo.rootUsers;
        const workMembers: Members[] = this.groupInfo.members;

        // ルートユーザ情報追加
        for (let i = 0; i < workRootUsers.length; i++) {
          // ロール情報にルート権限情報を付与
          const workRoles: GroupRole[] = [];
          workRoles.push({
            isCommon: false,
            id: -1,
            displayName: this.addMessage
          });
          memberResult.push({
            userDisplayName: workRootUsers[i].userDisplayName,
            userId: workRootUsers[i].userId,
            roles: workRoles
          });
          superUserId.push(workRootUsers[i].userId);
        }

        // メンバー情報を追加
        for (let j = 0; j < workMembers.length; j++) {
          let isAlreadyAdd = false;
          for (let k = 0; k < memberResult.length; k++) {
            if (workMembers[j].userId === memberResult[k].userId) {
              isAlreadyAdd = true;
            }
          }
          if (isAlreadyAdd === false) {
            // ルート権限なしのメンバー情報を追加
            memberResult.push({
              userDisplayName: workMembers[j].userDisplayName,
              userId: workMembers[j].userId,
              roles: workMembers[j].roles
            });
          }
        }
        if (memberResult.length === 0) {
          this.isNoData = true;
        } else {
          this.isNoData = false;
        }

        this.superUserId = superUserId;
        this.memberResult = memberResult;
      });

  }

  private canVisible(userId: number): boolean {
    let bRet = true;
    for (let i = 0; i < this.superUserId.length; i++) {
      if (userId === this.superUserId[i]) {
        bRet = false;
      }
    }
    return bRet;
  }

  ngOnInit(): void {
    // リゾルバー経由で取得したデータ
    this.route.data.subscribe(obj => {
      this.groupInfo = obj['groups'];
      this.displayNameControl = new FormControl(this.groupInfo.displayName);
      this.sshActiveControl = new FormControl(this.groupInfo.sshActive);
    });
    this.reloadGroupDetail();
  }
}

