import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeviceComponent } from './device.component';
import { DetailComponent } from './detail/detail.component';
import { EditComponent } from './edit/edit.component';
import { PropLogsComponent } from './prop-logs/prop-logs.component';
import { AuthGuard } from '../services/auth-guard.service';
import { DeviceResolver } from './device-resolver.service';
import { AlertDefsComponent } from './alert-defs/alert-defs.component';
import {RemoteComponent} from './remote/remote.component';

const routes: Routes = [
  {
    path: 'devices/:device_id',
    component: DeviceComponent,
    resolve: {
      device: DeviceResolver
    },
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DetailComponent, pathMatch: 'full' },
      { path: 'edit', component: EditComponent, pathMatch: 'full' },
      { path: 'prop_logs', component: PropLogsComponent, pathMatch: 'full' },
      { path: 'alert_defs', component: AlertDefsComponent, pathMatch: 'full' },
      { path: 'remote', component: RemoteComponent, pathMatch: 'full' },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeviceRoutingModule { }
