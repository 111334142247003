<mat-card class="app-note-contaner" [class.error]="roleError" [class.info]="roleInfo">
  <div class="wrapper">
    <div class="icon">
      <mat-icon>{{icon}}</mat-icon>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
</mat-card>
