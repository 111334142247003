import { Directive, Input, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[validateEquals][formControlName],[validateEquals][formControl],[validateEquals][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualsValidatorDirective), multi: true }
    ]
})
export class EqualsValidatorDirective implements Validator {
    @Input() validateEquals: string;

    validate(c: AbstractControl): { [key: string]: any } {

        // 入力値と比較値が異なる場合はtrue
        const v = c.value;
        if (v !== this.validateEquals) {
            return {
                validateEquals: true
            };
        }
        return null;
    }
}
