<div class="form-group">
  <div class="row">
    <div class="col-sm-12 text-right" style="margin-bottom: 20px;">
      <button mat-raised-button color="accent" (click)="searchDeviceMeasurements()">
        <mat-icon aria-hidden="true">refresh</mat-icon> {{'title_reload' | translate}}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="scrollTable">
            <table class="table table-bordered table-hover table-marginbottom">
              <thead>
                <tr>
                  <th>
                    <button id="datetime" mat-button class="btn-order" (click)="sortDeviceMeasurements()">
                        <span><b>{{'title_datetime' | translate}}</b></span>
                      <span *ngIf="isAscending" class="fa fa-sort-asc" aria-hidden="true"></span>
                      <span *ngIf="!isAscending" class="fa fa-sort-desc" aria-hidden="true"></span>
                    </button>
                  </th>
                  <th *ngFor="let prop of measurements?.propDefs">
                    <label class="labelth" title="{{prop.pre}}{{prop.displayName}}{{prop.unit | unit }}">
                      <span *ngIf="prop.pre != null">
                        {{prop.pre}}<br>{{prop.displayName}}{{prop.unit | unit }}
                      </span>
                      <span *ngIf="prop.pre == null">
                        {{prop.displayName}}{{prop.unit | unit }}
                      </span>
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let measurement of measurements?.data" class="active">
                  <td [attr.data-label]="'title_datetime' | translate">{{measurement.datetime | date:('date_format_ymdhms' | translate)}}</td>
                  <td [ngClass]="{'text-right active': valueData.type == 'number', 'text-left active': valueData.type != 'number' }"
                      title="{{valueData.val}}" *ngFor="let valueData of measurement.value">
                    {{valueData.val}}
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="isNoData" class="col-sm-12 pagination-margintop">
              <app-note role="info">{{'msg_no_data_to_display' | translate}}</app-note>
            </div>
          </div>
          <div class="row pagination-margintop">
            <div class="col-sm-12 text-center">
              <button class="btn btn-default" (click)="searchDeviceMeasurements(previous)" [disabled]="!previous">{{'pagination_prev' | translate}}
              </button>
              <button class="btn btn-default" (click)="searchDeviceMeasurements(next)" [disabled]="!next">{{'pagination_next' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
