import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../services/auth-guard.service';
import { DeviceTypeComponent } from './device-type.component';
import { DeviceTypeResolver } from './device-type-resolver.service';
import { ApiHandler } from '../services/api-handler.service';
import { DetailComponent } from './detail/detail.component';
import { LpwaPacketsComponent } from './lpwa-packets/lpwa-packets.component';
import { AlertDefsComponent } from './alert-defs/alert-defs.components';

const routes: Routes = [
  {
    path: `device_types/:device_type_id`,
    component: DeviceTypeComponent,
    resolve: {
      deviceType: DeviceTypeResolver
    },
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      { path: 'alert_defs', component: AlertDefsComponent, pathMatch: 'full' },
      { path: 'lpwa_packets', component: LpwaPacketsComponent, pathMatch: 'full' },
      { path: '', component: DetailComponent, pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ApiHandler, DeviceTypeResolver]
})
export class DeviceTypeRoutingModule { }
