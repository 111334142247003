import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceDetail, MeasurementListApi, PropDef, MeasurementData } from '../../common/api/device';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiHandler } from '../../services/api-handler.service';

@Component({
  templateUrl: './prop-logs.component.html',
  styleUrls: ['./prop-logs.component.css']
})
export class PropLogsComponent implements OnInit, OnDestroy {
  /** 本画面で表示するデバイス情報 */
  device: DeviceDetail;

  private parentSubscription: Subscription;
  isNoData: boolean = false;

  /**
   * 日時のソート順。デフォルトは降順
   */
  isAscending: boolean = false;

  next: string;              // 次ページURL
  previous: string;          // 前ページURL
  measurements: {
    propDefs: Array<PropDef>;
    data: Array<MeasurementData>;
  };

  constructor(private route: ActivatedRoute, private apiHandler: ApiHandler) { }
  /**
   * デバイス計測データ一覧取得
  */
  searchDeviceMeasurements(url?: string) {
    const query: MeasurementListApi['queryInterface'] = {};
    if (url) {
      const cursorKey = 'cursor';
      const prevKey = 'prev';
      /** URLのクエリストリングからカーソル、方向を取得 */
      // @angular/coreのURLSearchParamsはDeprecatedかつ
      // 先頭に?が入っていると正常に動作しないバグがある。
      // 将来的には、これらを全て取り除いてPollyfillに変えたほうが良いかもしれない。
      // また、IEでは new URL() ができないので強引に?でクエリ部分を分離している。

      const urlAndParam = url.split('?');
      const params = new URLSearchParams(urlAndParam.length > 1 ? urlAndParam[1] : '');
      if (params.has(cursorKey)) {
        query.cursor = params.get(cursorKey);
      }
      if (params.has(prevKey)) {
        query.prev = params.get(prevKey);
      }
    }
    // 検索
    this.apiHandler.call(false, MeasurementListApi, [this.device.id], undefined, query, [{ datetime: this.isAscending }])
      .subscribe(res => {
        if (!res.hasError) {
          this.measurements = res.body.results;
          this.measurements.propDefs.forEach(m => {
            const dn = m.displayName;
            if (dn != null) {
              let dnPre = '';
              let dnAft = '';
              if (dn.length > (11 + 1)) {
                dnPre = dn.substr(0, 11 + 1);
                dnAft = dn.substr(11 + 1);
              } else {
                dnPre = dn;
              }
              if (dnPre.indexOf(' ') > 0) {
                const idx = dnPre.indexOf(' ');
                m.pre = dnPre.substr(0, idx);
                m.displayName = dnPre.substr(idx + 1) + dnAft;
              }
            }
          });

          if (this.measurements.data.length === 0) {
            this.isNoData = true;
          } else {
            this.isNoData = false;
            this.measurements.data.forEach(d => {
              d.value.forEach(v => {
                if (v.type === 'number') {
                  try {
                    if (v.val == null) {
                      v.val = "";
                    } else {
                      v.val = Number(v.val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    }
                  } catch (e) {
                  }
                }
              });
            });
          }
          this.previous = res.body.previous;
          this.next = res.body.next;
        }
      });
  }

  // ソート
  sortDeviceMeasurements() {
    this.isAscending = !this.isAscending;  // ソート順を切り替え
    this.searchDeviceMeasurements();
  }

  ngOnInit() {
    this.parentSubscription = this.route.parent.data.subscribe(obj => {
      // 親コンポーネントが解決したデバイス情報をそのまま使わせていただく
      this.device = obj['device'] as DeviceDetail;
      // 計測データ取得
      this.searchDeviceMeasurements();
    });
  }

  ngOnDestroy() {
    this.parentSubscription.unsubscribe();
  }

}
