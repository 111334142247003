import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Functions } from '../common/functions';
import { GroupDetailComponent } from './groupdetail.component';
import { GroupDetailRoutingModule } from './groupdetail-routing.modules';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { GroupApiKeyComponent } from './group-api-key/group-api-key.component';
import { GroupApiKeyAddComponent } from './modal/group-api-key-add.component';
import { PartsComponentModule } from '../parts/parts-component.module';
import { GroupMemberAddComponent } from './modal/group-member-add.component';
import { GroupMemberUpdateComponent } from './modal/group-member-update.component';
import { AppMaterialModule } from '../common/app-material.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  imports: [
    GroupDetailRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PartsComponentModule,
    AppMaterialModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TranslateModule,
  ],
  providers: [
    Functions,
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
  ],
  declarations: [
    GroupDetailComponent,
    GroupApiKeyComponent,
    GroupApiKeyAddComponent,
    GroupMemberAddComponent,
    GroupMemberUpdateComponent
  ],
  bootstrap: [
    GroupDetailComponent,
  ],
  entryComponents: [
    GroupApiKeyComponent,
    GroupApiKeyAddComponent,
    GroupMemberAddComponent,
    GroupMemberUpdateComponent
  ]
})

export class GroupDetailModule { }
