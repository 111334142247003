import { OnInit, Input, Component } from '@angular/core';
import { DeviceAlertDef, AlertType } from '../../common/api/alert-def';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RouterService } from '../../services/router.service';
import { ApiHandler } from '../../services/api-handler.service';
import { DialogService } from '../../services/dialog.service';
import { CustomValidators } from 'ng2-validation';
import { Observable } from 'rxjs';
import { DeviceAlertDefUpdateApi, DeviceAlertDefAddApi, DeviceAlertDefDeleteApi } from '../../common/api/device-alert-def';
import { PropertyDef } from '../../common/api/prop-def';
import { BaseAlertDefEditComponent } from '../../parts/alert-def/base-alert-def-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { filter, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-device-alert-def-edit',
  templateUrl: './alert-def-edit.component.html'
})
export class DeviceAlertDefEditComponent extends BaseAlertDefEditComponent {
  /** 個別アラート定義が所属するデバイスのユニークID */
  @Input() deviceId: number;

  constructor(
    public t: TranslateService,
    routerService: RouterService,
    builder: FormBuilder,
    apiHandler: ApiHandler,
    dialogService: DialogService
  ) {
    super(t, routerService, builder, apiHandler, dialogService);
  }

  save() {
    let apiCall: Observable<{
      body: undefined;
      code: number;
      msg: string;
      hasError: boolean;
    }>;
    if (this.editMode) {
      // 更新
      const entity = this.alertDefForm.value;
      apiCall = this.apiHandler.call(true, DeviceAlertDefUpdateApi, [this.alertDef.id], entity);
    } else {
      // 登録
      const entity = {
        deviceId: this.deviceId,
        ...this.alertDefForm.value
      };
      // TODO: オプショナルな項目がnullだと登録APIがエラーになるので、nullな項目を除去する暫定対応
      Object.keys(entity).forEach((key) => (entity[key] == null) && delete entity[key]);
      apiCall = this.apiHandler.call(true, DeviceAlertDefAddApi, [], entity);
    }
    apiCall.subscribe(res => {
      if (!res.hasError) {
        this.routerService.reload();
      }
    });
  }

  delete() {
    this.dialogService.show(
      this.t.instant('title_confirmation'),
      this.t.instant(`msg_confirm_delete_alert_def`, {
        alertTitle: this.alertDef.title
      }))
      .pipe(
        filter(ok => ok),
        mergeMap(ok => {
          return this.apiHandler.call(true, DeviceAlertDefDeleteApi, [this.alertDef.id]);
      }))
      .subscribe(res => {
        if (!res.hasError) {
          this.routerService.reload();
        }
      });
  }

}
