import { Method } from '../../common/enums/api-enums';
import { ApiDef, ApiStatusMessage, Page } from './sip-api-client';

/**
 * ディバイス出力一覧APIの定義
 */
export class DeviceOutputListApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: {
    start?: Date;
    end?: Date;
    deviceTypeId?: number;
    deviceId?: number[];
  };
  orderableInterface: {
  };
  responseBodyInterface: Page<DeviceOutputForList>;
  uri = '/device_output';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_completed_download' },
    { code: 400, message: 'toast_input_value_invalid' }
  ];
}

export interface DeviceOutputForList {
  id: number;
  deviceTypeDisplayName: string;
  deviceDisplayName: string;
  propDisplayName: string;
  propValue: string;
  timeStamp: string;
}

