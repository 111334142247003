import { ApiDef, ApiStatusMessage } from './sip-api-client';
import { Method } from '../enums/api-enums';
import { IconDef } from '../interfaces/display-interfaces';

/**
 * デバイスステータス切り替えAPIの定義
 */
export class DeviceStatusToggleAddApi extends ApiDef {
  requestPayloadInterface: {
    deviceId: number;
    status: boolean;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/device_status/toggle';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
      { code: 201, message: 'toast_status_on_device' },
      { code: 204, message: 'toast_status_off_device' },
      { code: 400, message: 'toast_device_not_found' }
  ];
}

/** デバイスステータス */
export type DeviceStatus = boolean;
export namespace DeviceStatus {
  export const toIcon = (status: DeviceStatus): IconDef => {
    return status ?
     { label: 'title_status_on', name: 'star', color: 'warn' } :
     { label: 'title_status_off', name: 'star_border', color: 'foreground' } ;
  };
}
