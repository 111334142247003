import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormBuilder, Validators, FormGroup, ValidationErrors, AbstractControl } from '@angular/forms';
import { ResourceNameDirective } from '../../common/validation/resource-name.directive';
import { ApiHandler } from '../../services/api-handler.service';
import { PropType, VisualizationType, PropertyDefAddApi, PropertyDef, PropertyDefUpdateApi } from '../../common/api/prop-def';
import { Observable, Subscription } from 'rxjs';
import { SelectOption } from '../../common/interfaces/display-interfaces';

@Component({
  moduleId: module.id,
  templateUrl: './property-def-edit.component.html'
})
export class PropertyDefEditComponent implements OnInit, OnDestroy {
  get title() {
    return this.editMode ? 'title_prop_def_update' : 'title_prop_def_registration';
  }
  /**
   * trueなら更新モード。
   * falseなら新規登録モード。
   */
  editMode: boolean = false;
  isOk: boolean = false;

  /**
   * プロパティ定義を紐づけるデバイスタイプのユニークID。
   *
   * 親コンポーネントから引き継ぐ想定です。
   */
  parentDeviceTypeId: number;

  /**
   * 更新対象のプロパティ定義
   *
   * 更新モードの場合のみ親コンポーネントから引き継ぐ想定です。
   */
  originalPropDef: PropertyDef;

  // 入力フォーム
  resourceName: FormControl;
  displayName: FormControl;
  type: FormControl;
  unit: FormControl;
  isVisible: FormControl;
  visualizationType: FormControl;
  defaultValue: FormControl;
  displayOrder: FormControl;
  measurementKey: FormControl;

  propertyDefForm: FormGroup;

  /** プロパティ型の選択肢 */
  typeOptions: SelectOption[] = PropType.values().map(type => {
    return { value: type, name: PropType.toName(type) };
  });
  /** 可視化方式の選択肢 */
  visualizationTypeOptions: SelectOption[] = [];

  private typeSubscription: Subscription;

  constructor(
    public t: TranslateService, public bsModalRef: BsModalRef,
    private builder: FormBuilder, private apiHandler: ApiHandler) { }

  /**
   * 型とデフォルト値の複合バリデーション。
   *
   * デフォルト値が型に合う形式かチェックする。バリデーションエラーのキーは
   * 'defaultValuePattern' である。
   *
   * TODO: Angularのpatternバリデータを使って雑に正規表現チェックしているけど、
   * できればコネクタ側で実装している NumberValidator とか
   * LatLngValidator を使いたい。
   */
  validateDefaultValuePattern = (control: AbstractControl): ValidationErrors | null => {
    const validationKey = 'defaultValuePattern';
    const defaultValueControl = control;
    const defaultValue = defaultValueControl.value as string;
    const typeValue = this.type.value as PropType;
    if (!typeValue || !defaultValue) {
      // どちらかの値が入力されてない場合はバリデーションしない（エラーなし）
      return null;
    }
    if (typeValue === 'string') {
      return null;  // なんでもOK
    } else if (typeValue === 'number') {
      const numRegexp = /^[+-]?(?:[1-9][0-9]*|0)(?:\.[0-9]+)?$/;
      const errors = Validators.pattern(numRegexp)(defaultValueControl);
      return errors && { [validationKey]: errors['pattern'] };
    } else if (typeValue === 'latlng') {
      const latlngRegexp = /^[+-]?(?:[1-9][0-9]*|0)(?:\.[0-9]+)?,[+-]?(?:[1-9][0-9]*|0)(?:\.[0-9]+)?$/;
      const errors = Validators.pattern(latlngRegexp)(defaultValueControl);
      return errors && { [validationKey]: errors['pattern'] };
    }
    return null;
  }

  /**
   * 可視化方式と計測キーの複合バリデーション。
   *
   * 可視化方式が時系列の場合は、計測キーが空でないことを確認する。
   * バリデーションエラーのキーは 'measurementKeyRequired' である。
   */
  validateMeasurementKeyRequired = (control: AbstractControl): ValidationErrors | null => {
    const validationKey = 'measurementKeyRequired';
    const measurementKeyControl = control;
    const visualizationTypeValue = this.visualizationType.value as VisualizationType;
    if (visualizationTypeValue === 'timeseries') {
      const errors = Validators.required(measurementKeyControl);
      return errors && { [validationKey]: errors['required'] };
    }
    return null;
  }

  ngOnInit() {
    /* 入力フォームの初期化 */
    this.resourceName = new FormControl(null, [
      Validators.required,
      new ResourceNameDirective().validate
    ]);
    this.displayName = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50)
    ]);
    this.type = new FormControl(null, Validators.required);
    this.unit = new FormControl(null, Validators.maxLength(50));
    this.isVisible = new FormControl(true);
    this.visualizationType = new FormControl(null, Validators.required);
    this.defaultValue = new FormControl(null, this.validateDefaultValuePattern);
    this.displayOrder = new FormControl(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(32767),
      Validators.pattern(/^(?:[1-9][0-9]*|0)$/)
    ]);
    this.measurementKey = new FormControl(null, [
      new ResourceNameDirective().validate,
      Validators.maxLength(127),
      this.validateMeasurementKeyRequired
    ]);

    this.propertyDefForm = this.builder.group({
      resourceName: this.resourceName,
      displayName: this.displayName,
      type: this.type,
      unit: this.unit,
      isVisible: this.isVisible,
      visualizationType: this.visualizationType,
      defaultValue: this.defaultValue,
      displayOrder: this.displayOrder,
      measurementKey: this.measurementKey
    });
    /* 型の変更イベントを購読する */
    this.typeSubscription = this.type.valueChanges.subscribe(this.onTypeChange);
    this.onTypeChange(this.type.value);  // 1回目だけは明示的に呼び出す
  }

  ngOnDestroy() {
    this.typeSubscription.unsubscribe();
  }

  /**
   * モーダルを更新モードとして内部状態を変更する
   */
  prepareForEdit(propDef: PropertyDef) {
    this.editMode = true;
    this.originalPropDef = propDef;
    this.resourceName.disable();  // 入力不可にする
    /** フォームの入力値を指定されたプロパティ定義の値で上書きする。 */
    this.resourceName.setValue(propDef.resourceName);
    this.displayName.setValue(propDef.displayName);
    this.type.setValue(propDef.type);
    this.unit.setValue(propDef.unit);
    this.isVisible.setValue(propDef.isVisible);
    this.visualizationType.setValue(propDef.visualizationType);
    this.defaultValue.setValue(propDef.defaultValue);
    this.displayOrder.setValue(propDef.displayOrder);
    this.measurementKey.setValue(propDef.measurementKey);
  }

  save() {
    let apiCall: Observable<{
      body: undefined;
      code: number;
      msg: string;
      hasError: boolean;
    }>;
    if (this.editMode) {
      // 更新モード
      const entity = this.propertyDefForm.value;
      apiCall = this.apiHandler.call(true, PropertyDefUpdateApi, [this.originalPropDef.id], entity);
    } else {
      // 登録モード
      const entity = {
        deviceTypeId: this.parentDeviceTypeId,  // 親コンポーネントのデバイスタイプユニークIDを引き継ぐ
        ...this.propertyDefForm.value
      };
      apiCall = this.apiHandler.call(true, PropertyDefAddApi, [], entity);
    }
    apiCall.subscribe(res => {
      if (!res.hasError) {
        // 登録成功の場合は登録完了フラグをONにして画面を閉じる
        this.isOk = true;
        this.bsModalRef.hide();
      }
    });
  }

  /** 型が変更された時の処理 */
  onTypeChange = (type: PropType): void => {
    /* 型に応じて可視化方式の選択肢を変更する */
    this.visualizationTypeOptions = VisualizationType.forPropType(type).map(type => {
      return { value: type, name: VisualizationType.toName(type) };
    });
  }
}
