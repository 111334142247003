<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-sm-12">
      <h5 *ngIf="messageHead != ''">
        <b>{{messageHead}}</b>
      </h5>
      <li *ngFor="let detail of messageDetail">
        <b>{{detail.message}}</b>
      </li>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-sm-12 text-right">
      <button mat-raised-button color="primary" (click)="clickOK()">OK</button>
      <button mat-raised-button (click)="clickCancel()">キャンセル</button>
    </div>
  </div>
</div>
