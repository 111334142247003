import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceTypeGetApi } from '../../common/api/device-type';
import { ApiHandler } from '../../services/api-handler.service';
import { ActivatedRoute } from '@angular/router';
import { WebStorageAuthRepository } from '../../services/auth-repository.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmComponent } from '../../common/messages/confirm.component';
import { Subscription } from 'rxjs';
import { LpwaPacketTypeDeleteApi, LpwaPacketType } from '../../common/api/lpwa-packet';
import { LpwaPacketTypeEditComponent } from '../modal/lpwa-packet-type-edit.component';
import { RouterService } from '../../services/router.service';

@Component({
  selector: 'app-lpwa-packets',
  templateUrl: './lpwa-packets.component.html'
})
export class LpwaPacketsComponent implements OnInit, OnDestroy {

  deviceType: DeviceTypeGetApi['responseBodyInterface'];

  /** デバイスタイプを編集できるかどうか */
  get canEdit() {
    return this.authRepo.hasPermission('device_type', 'edit');
  }

  constructor(
    public t: TranslateService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    private authRepo: WebStorageAuthRepository,
    private apiHandler: ApiHandler,
    private confirmMessage: BsModalService,
    private modalService: BsModalService) {
  }

  /** 親コンポーネントの解決済みデータを受け取るイベントの購読状態 */
  private parentSubscription = Subscription.EMPTY;

  ngOnInit(): void {
    this.parentSubscription = this.route.parent.data.subscribe(obj => {
      // 親コンポーネントのデバイスタイプをそのまま使わせていただく
      this.deviceType = obj['deviceType'];
    });
  }

  ngOnDestroy(): void {
    // イベント購読を停止する
    this.parentSubscription.unsubscribe();
  }

  /** LPWAパケットタイプを編集するモーダル画面を開く */
  openPacketTypeEdit(packetType?: LpwaPacketType) {
    const packetTypeNumbers = this.deviceType.lpwaPacketTypes.map(it => it.packetType);
    const modalRef = this.modalService.show(LpwaPacketTypeEditComponent, { class: 'modal-dialog modal-lg', backdrop: 'static' });
    const component = <LpwaPacketTypeEditComponent>modalRef.content;
    component.prepareForEdit(this.deviceType.id, packetTypeNumbers, packetType);

    component.onEditSuccess.subscribe(ret => { // モーダル画面で編集が成功したとき
      this.routerService.reload();
    });
  }

  /** 削除確認ダイアログを表示する */
  confirmDeletion(packetType: LpwaPacketType) {
    const modalRef = this.confirmMessage.show(ConfirmComponent, { backdrop: 'static' });
    modalRef.content.title = this.t.instant('title_confirmation');
    modalRef.content.messageHead = this.t.instant(`msg_confirm_delete_packet_type`, {packetType: packetType.packetType});

    this.confirmMessage.onHide
      .subscribe(() => {
        if (modalRef.content.isOk) {
          this.deletePacketType(packetType.id);
          modalRef.content.isOk = false;
        }
      });
  }

  /** パケットタイプを削除する */
  private deletePacketType(packetTypeId: number) {
    this.apiHandler.call(true, LpwaPacketTypeDeleteApi, [packetTypeId])
      .subscribe(res => {
        if (!res.hasError) {
          this.routerService.reload();
        }
      });
  }

}
