import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceGroupsComponent } from './device-groups.component';
import { ConfirmComponent } from '../common/messages/confirm.component';
import { DataService } from '../services/data.service';
import { Functions } from '../common/functions';
import { DeviceGroupsRoutingModule } from './device-groups-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DeviceGroupsAddComponent } from './modal/device-groups-add.component';
import { AppMaterialModule } from '../common/app-material.module';
import { PartsComponentModule } from '../parts/parts-component.module';

@NgModule({
  imports: [
    DeviceGroupsRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    PartsComponentModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule,
  ],
  declarations: [
    DeviceGroupsComponent,
    DeviceGroupsAddComponent
  ],
  providers: [
    DataService,
    DeviceGroupsComponent,
    Functions
  ],
  bootstrap: [
    DeviceGroupsComponent
  ],
  entryComponents: [
    DeviceGroupsAddComponent,
    ConfirmComponent
  ]
})
export class DeviceGroupsModule { }
