<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row">
      <div class="col-sm-10">{{'title_packet_type' | translate}} {{packetType.packetType}}</div>
      <div class="col-sm-2 text-right">
        <button mat-icon-button type="button" class="icon-btn" matTooltip="{{'title_edit' | translate}}" [disabled]="!editable" (click)="fireClickEdit()">
          <mat-icon aria-hidden="true">edit</mat-icon>
        </button>
        <button mat-icon-button type="button" class="icon-btn" matTooltip="{{'title_delete' | translate}}" [disabled]="!editable" (click)="fireClickDelete()">
          <mat-icon aria-hidden="true">delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="panel-body">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th *ngFor="let columns of columnList">
            <label class="labelth" id={{columns.id}}>
              {{columns.displayName | translate}}
            </label>
          </th>
          <th>
            <!-- ボタン配置カラム -->
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="active" *ngFor="let packetDecode of packetType.decoders" app-lpwa-packet-decode-row [packetDecode]="packetDecode" [packetType]="packetType"
          [propertyDefs]="propertyDefs" [editable]="editable"></tr>
        <!-- 追加ボタンが押下されると新規行を表示する -->
        <tr *ngIf="editable && isAdding" [@newRowState] class="active" app-lpwa-packet-decode-row [packetType]="packetType" [propertyDefs]="propertyDefs"
          [editable]="editable" (cancel)="isAdding=false"></tr>
      </tbody>
    </table>
    <div *ngIf="editable" class="col-sm-12 text-right">
      <button mat-mini-fab color="primary" [disabled]="isAdding" (click)="isAdding=true">
        <mat-icon aria-hidden="true">add</mat-icon>
      </button>
    </div>
  </div>
</div>
