import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DeviceType, DeviceTypeGetApi } from '../common/api/device-type';
import { Injectable } from '@angular/core';
import { ApiHandler } from '../services/api-handler.service';
import { Observable } from 'rxjs';
import { DeviceGetApi, DeviceDetail } from '../common/api/device';
import { map } from 'rxjs/operators';

/**
 * デバイスのユニークIDからデバイス情報を解決するリゾルバー。
 *
 * デバイス詳細画面に遷移するときのURLパラメータの解決に利用する想定。
 * デバイスの取得に失敗した場合は /404 に遷移する。
 */
@Injectable()
export class DeviceResolver implements Resolve<DeviceDetail> {
  constructor(private router: Router, private apiHandler: ApiHandler) { }

  /**
   * 'device_id' というキー名のURLパラメータからデバイスタイプ情報を取得する。
   */
  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot):
    DeviceDetail | Observable<DeviceDetail> | Promise<DeviceDetail> {
    return this.apiHandler.call(false, DeviceGetApi, [route.params['device_id']])
      .pipe(map(res => {
        if (res.hasError) {
          console.log('Error to get device info.');
          this.router.navigate(['404']);
        } else {
          return res.body;
        }
      }));
  }
}
