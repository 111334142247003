<div class="row" *ngIf="!isEditing">
  <!-- 閲覧中 -->
  <div class="col-sm-10">
    <span>{{inputControl.value}}</span>
  </div>
  <div class="col-sm-2 text-right" *ngIf="!disabled">

    <button mat-icon-button type="button" class="icon-btn" matTooltip="{{'title_edit' | translate}}" (click)="edit()">
      <mat-icon aria-hidden="true">edit</mat-icon>
    </button>

  </div>
</div>
<div class="row" *ngIf="isEditing">
  <!-- 編集中 -->
  <form [formGroup]="form" (ngSubmit)="save()">
    <div class="col-sm-9">
      <mat-form-field class="compact-field" floatLabel="never">
        <input matInput [type]="type" [formControl]="inputControl" [name]="name" [required]="required" [minlength]="minlength"
          [maxlength]="maxlength" [pattern]="pattern" [min]="min" [max]="max" />
        <ng-container *ngFor="let e of errors" ngProjectAs="mat-error">
          <mat-error *ngIf="inputControl.touched && inputControl.hasError(e.key)">{{e.message | translate}}</mat-error>
        </ng-container>
      </mat-form-field>
    </div>
    <div class="col-sm-3 text-right" *ngIf="!disabled">

      <button mat-icon-button type="submit" class="icon-btn" color="primary" matTooltip="{{'title_save' | translate}}" [disabled]="form.pristine || form.invalid || submitted">
        <mat-icon aria-hidden="true">save</mat-icon>
      </button>
      <button mat-icon-button type="button" class="icon-btn" matTooltip="{{'title_cancel' | translate}}" (click)="cancel()">
        <mat-icon aria-hidden="true">clear</mat-icon>
      </button>

    </div>
  </form>
  </div>
