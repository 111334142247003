  <div class="row">
    <div class="col-sm-8">
      <h2>{{device.displayName}}</h2>
    </div>
    <div class="col-sm-4 text-right">
        <span class="headalert">
          <mat-icon [color]="device.hasAlertIcon.color" aria-hidden="true">
            {{device.hasAlertIcon.name | translate}}
          </mat-icon>
          {{device.hasAlertIcon.label | translate}}
        </span>
        <button mat-icon-button (click)="clickAlertToggle()">
          <mat-icon [color]="device.isSubscribedIcon.color" [matTooltip]="device.isSubscribedIcon.label | translate" aria-hidden="true">
            {{device.isSubscribedIcon.name}}
          </mat-icon>
        </button>
        <button mat-icon-button (click)="clickDeviceToggle()">
          <mat-icon [color]="device.isMutedIcon.color" [matTooltip]="device.isMutedIcon.label | translate" aria-hidden="true">
            {{device.isMutedIcon.name}}
          </mat-icon>
        </button>
        <a class="btn-left-margin" mat-raised-button role="button" routerLink="/devices">
          <mat-icon aria-hidden="true">arrow_upward</mat-icon> {{'title_back_to_list' | translate}}
        </a>
    </div>
  </div>
  <nav mat-tab-nav-bar>
      <a mat-tab-link *ngFor="let link of tabLinks" [routerLink]="link.path" [routerLinkActiveOptions]="link.options || {}" routerLinkActive
        #rla="routerLinkActive" [active]="rla.isActive">{{link.label | translate}}</a>
  </nav>
  <!-- <nav mat-tab-nav-bar>
      <a mat-tab-link [routerLink]="['./']" routerLinkActive #actDetail="routerLinkActive" [active]="actDetail.isActive">詳細</a>
      <a mat-tab-link [routerLink]="['prop_logs']" routerLinkActive #actPropertylog="routerLinkActive" [active]="actPropertylog.isActive">稼働ログ</a>
      <a mat-tab-link [routerLink]="['edit']" routerLinkActive #actDeviceedit="routerLinkActive" [active]="actDeviceedit.isActive">デバイス編集</a>
      <a mat-tab-link [routerLink]="['alert_defs']" routerLinkActive #actAlertedit="routerLinkActive" [active]="actAlertedit.isActive">アラート定義編集</a>
  </nav> -->
  <router-outlet></router-outlet>
