import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';
import { Directive, forwardRef } from '@angular/core';


/**
 * リソース名として正しい文字列かを検証するバリデータ、かつディレクティブ。
 *
 * バリデーションのキー名は {@code resourceName} である。
 */
@Directive({
  selector: '[appResourceName][formControl],[appResourceName][formControlName][appResourceName][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ResourceNameDirective), multi: true }
  ]
})
export class ResourceNameDirective implements Validator {
  static readonly KEY = 'resourceName';
  static readonly PATTERN = /^[-_0-9a-zA-Z]*$/;

  /**
   * コントロールの入力値がリソース名として正しいか検証する。
   *
   * @param control 検証対象となるコントロール
   */
  validate(control: AbstractControl): { [key: string]: any; } {
    const value = control.value;
    if (!(typeof value === 'string' || typeof value === 'number')) {
      return null;
    }
    const isValid = ResourceNameDirective.PATTERN.test(value.toString());
    return isValid ? null :
      { [ResourceNameDirective.KEY]: `${value} is invalid as resource name.` };
  }
}
