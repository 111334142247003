import { Component, OnInit, ViewChild } from '@angular/core';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import { ApiHandler } from './services/api-handler.service';
import { WebStorageI18nRepository } from './services/i18n-repository.service';
import { WebStorageAuthRepository } from './services/auth-repository.service';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { QueryStoreService } from './services/query-store.service';

declare var toastr: any;

@Component({
  moduleId: module.id,
  // tslint:disable-next-line:component-selector
  selector: 'app',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  /** 1つ以上のAPIコール中だったらtrue */
  isCalling: boolean = false;
  /** 最後に401/403/500エラーのトーストを出した時刻 */
  lastCommonErrorMsg: Date;
  isFirstToastShown = false;
  reloadFun: NodeJS.Timer;

  @ViewChild(MatSidenav, {static: false}) sidenav: MatSidenav;

  sidenavLinks = [
    { path: '/devices', label: 'title_device_list', queryKey: 'devices' },
    { path: '/device_types', label: 'title_device_type_list', queryKey: 'device_types' },
    { path: '/alert_logs', label: 'title_alert_log_list', queryKey: 'alert_logs' },
    { path: '/groups', label: 'title_group_list' },
    { path: '/device_groups', label: 'title_device_group_list' },
    { path: '/alert_output', label: 'title_alert_output_index' },
    { path: '/device_output', label: 'title_device_output_index' }
  ];

  constructor(public t: TranslateService, public apiHandler: ApiHandler, public i18n: WebStorageI18nRepository,
    public repos: WebStorageAuthRepository, private router: Router, private queryStore: QueryStoreService) {
    t.setDefaultLang('en');

    // 画面遷移する時、リロード処理をクリアする
    this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationStart) {
        clearInterval(this.reloadFun);
      }
    });
  }

  ngOnInit() {
    // ロード完了時点で、このページを開いたウィンドウにトークンを送らせる。
    window.onload = () => {
      if (window.opener) {
        window.opener.postMessage({}, '*');
      }
    };
    // トークンが送られてきたときにローカルストレージにセットしてリダイレクト。
    window.addEventListener(
      'message',
      (event) => {
        // 自ホスト空の呼び出しは処理しない
        if (event.origin !== window.location.protocol + '//' + window.location.host) {
          // 既に一度SSO処理が走っている場合は処理しない
          if (event.data.token && !sessionStorage.getItem('ssoProcessed')) {
            localStorage.setItem('token', event.data.token);
            sessionStorage.setItem('ssoProcessed', 'true');
            this.router.navigate([event.data.path ? event.data.path : '/']);
          }
        }
      },
      false);
    // APIレスポンスを受けてのトースト処理を登録
    this.apiHandler.gotApiResult.subscribe(
      res => {
        // 2回目以降のトーストについて、401/403/500エラーは2秒以内には再度表示されない。
        if (res.code === 401 || res.code === 403 || res.code === 500) {
          if (!this.isFirstToastShown || new Date().getTime() - this.lastCommonErrorMsg.getTime() > 2000) {
            this.isFirstToastShown = true;
            this.lastCommonErrorMsg = new Date();
            this.showToastMessage(res.msg, 'error');
          }
        } else {
          const type = res.code >= 200 && res.code < 300 ? 'success' : 'error';
          // update by lms at 20200212 start 一覧画面でリロードする時、データがなくてもダイアログを出せないのため
          // this.showToastMessage(res.msg, type);
          if (res.msg != null) {
            this.showToastMessage(res.msg, type);
          }
          // update by lms at 20200212 end
        }
      }
    );
    this.apiHandler.apiEvent.subscribe(
      res => {
        if (res) {
          this.isCalling = true;
        } else {
          this.isCalling = false;
        }
      }
    );
    // 言語指定がされていればそれ、なければブラウザ標準
    if (this.i18n.lang) {
      this.t.use(this.i18n.lang);
    } else {
      this.t.use(this.t.getBrowserLang());
    }
  }

  /**
   * toastメッセージ生成
   * @param message メッセージ
   * @param type トーストタイプ
   */
  showToastMessage(message: string, type: 'success' | 'error' | 'warning' | 'info') {
    // closeボタン
    toastr.options.closeButton = true;
    // 新しいメッセージは下に追加
    toastr.options.newestOnTop = false;
    // 表示する時間(msec)
    toastr.options.timeOut = 3000;
    toastr.options.extendedTimeOut = 5000;
    // メッセージの国際化
    message = this.t.instant(message);
    // 表示場所
    toastr.options.positionClass = 'toast-top-center';
    if (type === 'success') { toastr.success(message); }
    if (type === 'error') { toastr.error(message); }
    if (type === 'warning') { toastr.warning(message); }
    if (type === 'info') { toastr.info(message); }
  }

  /**
   * 言語を変更する。
   * @param lang 指定する言語 eg. 'en', 'ja'
   */
  changeLang(lang: string) {
    this.i18n.update({ lang: lang });
    this.t.use(lang);
  }

  /** 遷移前の前処理 */
  onNavigate(link) {
    this.sidenav.close();  // サイドバーを閉じる
    if (link.queryKey != null) {
      // 永続化されている検索条件を削除する
      this.queryStore.removeQuery(link.queryKey);
    }
  }
}
