import {
  LpwaPakcetDecode, LpwaDataType, LpwaPacketDecodeUpdateApi,
  LpwaPacketDecodeDeleteApi, LpwaPacketDecodeAddApi, LpwaPacketType
} from '../../common/api/lpwa-packet';
import { Input, Component, OnInit, EventEmitter, Output } from '@angular/core';
import { PropertyDef } from '../../common/api/prop-def';
import {
  FormControl, FormBuilder, FormGroup, Validators, AbstractControl,
  ValidationErrors, FormGroupDirective, NgForm
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiHandler } from '../../services/api-handler.service';
import { DialogService } from '../../services/dialog.service';
import { RouterService } from '../../services/router.service';
import { CustomValidators } from 'ng2-validation';
import { ErrorStateMatcher } from '@angular/material/core';
import { filter, mergeMap } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tr[app-lpwa-packet-decode-row]',
  templateUrl: './lpwa-packet-decode-row.component.html'
})
export class LpwaPacketDecodeRowComponent implements OnInit {
  /** テーブル1行に表示するLPWAパケット解読 */
  @Input() packetDecode: LpwaPakcetDecode;
  /** LPWAパケット解読が紐づくLPWAパケットタイプ */
  @Input() packetType: LpwaPacketType;
  /** デバイスタイプに紐づくプロパティ定義一覧 */
  @Input() propertyDefs: PropertyDef[];
  /** LPWAパケットデコードを編集できるかどうかを表すフラグ */
  @Input() editable: boolean;

  /** キャンセルボタンが押下された時に発生するイベント */
  @Output() cancel = new EventEmitter<boolean>();

  /** 編集モード
   * trueなら更新モード、falseなら新規登録モード。
   */
  editMode: boolean;

  startBit: FormControl;
  dataType: FormControl;
  dataBitLength: FormControl;
  propertyDefId: FormControl;
  packetDecodeForm: FormGroup;

  readonly dataTypeOptions = LpwaDataType.values();
  readonly toDataTypeName = LpwaDataType.toName;

  /** バイト範囲に関するバリデーションのエラー状態を判定するマッチャー */
  readonly byteRangeStateMatcher = new FormErrorStateMatcher('byteRange');

  /** IDに対応するプロパティ定義の表示名を取得する */
  readonly toPropertyDefName = (id: number): string => {
    const matchedDef = this.propertyDefs.find(def => def.id === id);
    return (matchedDef) ? matchedDef.displayName : id.toString();
  }

  /**
   * 選択可能なプロパティ定義の一覧。
   *
   * 通常はデバイスタイプに紐づく稼働情報のプロパティ定義すべてが選択肢になるが、
   * データ型の入力に応じて選択可能なプロパティ定義は変わる。
   */
  get propertyDefOptions(): PropertyDef[] {
    return this.propertyDefs
      .filter(def =>
        def.measurementKey != null  // 機器情報は除く
      )
      .filter(def => {
        /* データ型の入力に応じて選択可能なプロパティ定義を変える */
        const inputDataType = this.dataType && this.dataType.value;
        if (inputDataType == null || inputDataType === '') {
          // 入力されていない場合は絞り込まない
          return true;
        } else if (inputDataType === 'string') {
          // データ型が string のプロパティ定義のみ
          return def.type === 'string';
        } else if (['int16', 'float32'].includes(inputDataType)) {
          // データ型が number のプロパティ定義のみ
          return def.type === 'number';
        } else {
          return true;
        }
      });

  }

  constructor(
    public t: TranslateService,
    private routerService: RouterService,
    private formBuilder: FormBuilder,
    private apiHandler: ApiHandler,
    private dialogService: DialogService
  ) {
  }

  ngOnInit(): void {
    if (this.packetDecode == null) {
      this.editMode = false;
      this.packetDecode = {
        id: null, startBit: null, dataType: null, dataBitLength: null, propertyDefId: null
      };
    } else {
      this.editMode = true;
    }
    /* 入力フォームの初期化 */
    this.startBit = new FormControl(this.packetDecode.startBit, [
      Validators.required,
      Validators.min(0),
      CustomValidators.digits
    ]);
    this.dataType = new FormControl(this.packetDecode.dataType, Validators.required);
    this.dataBitLength = new FormControl(this.packetDecode.dataBitLength, [
      Validators.required,
      Validators.min(1),
      CustomValidators.digits
    ]);
    this.propertyDefId = new FormControl(this.packetDecode.propertyDefId, [
      Validators.required,
      this.validatePropertyDuplication
    ]);
    this.packetDecodeForm = this.formBuilder.group({
      startBit: this.startBit,
      dataType: this.dataType,
      dataBitLength: this.dataBitLength,
      propertyDefId: this.propertyDefId
    }, {
        validator: this.validateByteRange
      });
    /* データ型の変更イベントを購読 */
    this.dataType.valueChanges.subscribe(this.decideDataBitLength);
    this.dataType.setValue(this.packetDecode.dataType);  // trigger a value change event with initial value
  }

  saveLpwaPacketDecode() {

    if (this.editMode) {
      this.apiHandler.call(true, LpwaPacketDecodeUpdateApi, [this.packetDecode.id], this.packetDecodeForm.getRawValue())
        .subscribe(res => {
          if (!res.hasError) {
            this.routerService.reload();
          }
        });
    } else {
      const payload = {
        lpwaPacketTypeId: this.packetType.id,
        ...this.packetDecodeForm.getRawValue()
      };
      this.apiHandler.call(true, LpwaPacketDecodeAddApi, [], payload)
        .subscribe(res => {
          if (!res.hasError) {
            this.routerService.reload();
          }
        });
    }
  }

  deleteLpwaPacketDecode() {
    this.dialogService.show(
      this.t.instant('title_confirmation'),
      this.t.instant('msg_confirm_delete_lpwa_packet_decode'))
      .pipe(
        filter(ok => ok),
        mergeMap(ok => {
          return this.apiHandler.call(true, LpwaPacketDecodeDeleteApi, [this.packetDecode.id]);
      }))
      .subscribe(res => {
        if (!res.hasError) {
          this.routerService.reload();
        }
      });
  }

  onCancel() {
    this.cancel.emit(true);
  }

  /** データ型の入力値に応じてバイト長の入力コントロールの状態を変更する */
  private decideDataBitLength = (type: LpwaDataType) => {
    switch (type) {
      case 'int16':
        this.dataBitLength.setValue(2);
        this.dataBitLength.disable();
        break;
      case 'float32':
        this.dataBitLength.setValue(4);
        this.dataBitLength.disable();
        break;
      default:
        this.dataBitLength.enable();
        break;
    }
  }

  /**
   * プロパティが重複しているか検証するバリデーション関数。
   *
   * バリデーションキーは 'propertyDuplication' である。
   */
  private validatePropertyDuplication = (control: AbstractControl): ValidationErrors | null => {
    const validationKey = 'propertyDuplication';
    const selectedId = control.value;
    const duplicate = this.packetType.decoders
      /* 自分自身は除いた */
      .filter(decorder => decorder.id !== this.packetDecode.id)
      /* 他のパケット解読のプロパティ定義IDが */
      .map(decoder => decoder.propertyDefId)
      /* 現在選択しているプロパティ定義IDと一致したら true */
      .includes(selectedId);
    if (duplicate) {
      return { [validationKey]: true };
    } else {
      return null;
    }
  }

  /**
   * バイト範囲が重複していないか検証するバリデーション関数。
   *
   * バリデーションキーは 'byteRange' である。
   */
  private validateByteRange = (form: FormGroup): ValidationErrors | null => {
    const validationKey = 'byteRange';
    // const startByte: number = this.startBit.value;
    // const length: number = this.dataBitLength.value;
    const startByte: number = form.controls['startBit'].value;
    const length: number = form.controls['dataBitLength'].value;
    if (startByte == null || length == null) {
      // どちらかの値が入力されていない場合はバリデーションしない（エラーなし）
      return null;
    }
    const endByte = startByte + length;
    const isOverlap = this.packetType.decoders
      /* 自分自身は除いた */
      .filter(decorder => decorder.id !== this.packetDecode.id)
      /* 他のパケット解読の範囲と重複チェック */
      .some(decoder => {
        const targetStartByte = decoder.startBit;
        const targetEndByte = targetStartByte + decoder.dataBitLength;
        const notOverlap = targetEndByte <= startByte || endByte <= targetStartByte;
        return !notOverlap;
      });
    if (isOverlap) {
      return { [validationKey]: true };
    } else {
      return null;
    }
  }
}

/**
 * コンストラクタで指定されたフォームレベルのエラーがあるかを判定するマッチャー。
 */
class FormErrorStateMatcher implements ErrorStateMatcher {
  private formErrors: string[];
  constructor(...formErrors: string[]) {
    this.formErrors = formErrors;
  }

  isErrorState = (control: FormControl | null, form: FormGroupDirective | NgForm | null) => {
    /* コントロールに関するエラーがある場合は当然エラーにする */
    const controlInvalid = !!(control && control.invalid);
    const controlTouched = !!(control && (control.touched || control.dirty));
    /* 指定されたフォームレベルのエラーのうち1つでもエラーがある場合もエラーにする */
    const parentInvalid = !!(form && form.invalid);
    const specifiedFormError = this.formErrors.some(error => form.hasError(error));
    return (controlTouched && controlInvalid) || (parentInvalid && specifiedFormError);
  }
}
