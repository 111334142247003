import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiHandler } from '../services/api-handler.service';
import { Observable } from 'rxjs';
import { ApiPagenation } from '../common/api/sip-api-client';
import {DeviceGroupInfoGetApi, DeviceGroups} from "../common/api/device-group";
import { map } from 'rxjs/operators';

@Injectable()
export class DeviceGroupsResolver implements Resolve<DeviceGroups> {
  constructor(private router: Router, private apiHandler: ApiHandler) { }
  /**
  * URLパラメータからグループ情報を取得する
  */
  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot):
    DeviceGroups | Observable<DeviceGroups> | Promise<DeviceGroups> {

    const pagination: ApiPagenation = {
      pageSize: 1000,
    };

    return this.apiHandler.call(false, DeviceGroupInfoGetApi, [route.params['group_id']], {}, {}, [], pagination)
      .pipe(map(res => {
        if (res.hasError) {
          this.router.navigate(['404']);
        } else {
          return res.body;
        }
      }));
  }
}
