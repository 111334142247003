<div class="row">
  <div class="col-sm-10">
    <h2>{{groupInfo.displayName}}</h2>
  </div>
  <div class="col-sm-2 text-right">
    <a mat-raised-button role="button" [routerLink]="['/groups']" routerLinkActive="active">
      <mat-icon aria-hidden="true">arrow_upward</mat-icon> {{'title_back_to_list' | translate}}
    </a>
  </div>
</div>
<div>
  <nav mat-tab-nav-bar>
    <a mat-tab-link [routerLink]="['../']" routerLinkActive #ria1="" [routerLinkActiveOptions]="{}">{{'title_detail' | translate}} </a>
    <a mat-tab-link [routerLink]="['./']" routerLinkActive #ria2="routerLinkActive" [active]="ria2.isActive" [routerLinkActiveOptions]="{exact: true}">{{'title_api_keys' | translate}}</a>
  </nav>
</div>
<div class="row">
  <div class="col-sm-12">
    <table class="table table-bordered table-hover tableBreakAll">
      <colgroup>
        <col style="width:25%">
        <col style="width:30%">
        <col style="width:20%">
        <col style="width:7%">
      </colgroup>
      <thead>
        <tr>
          <th *ngFor="let columns of columnList">
            <label class="labelth" *ngIf="!columns.issort" id={{columns.id}}>
              {{columns.displayName | translate}}
            </label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of groupapikeyresults" class="active">
          <td [attr.data-label]="columnList[0].displayName">{{result.displayName}}</td>
          <td [attr.data-label]="columnList[1].displayName">{{result.apiKey}}</td>
          <td [attr.data-label]="columnList[2].displayName">{{result.expirationDate | date:('date_format_ymdhms' | translate)}}</td>
          <td [attr.data-label]="columnList[3].displayName" style="text-align:center;">
            <button mat-icon-button class="icon-btn" matTooltip="{{'title_edit' | translate}}" (click)="clickEddit($event, result.id,result.displayName,result.expirationDate)"
              [disabled]="!canUpdate">
              <mat-icon aria-hidden="true">edit</mat-icon>
            </button>
            <button mat-icon-button class="icon-btn" matTooltip="{{'title_delete' | translate}}" (click)="clickDelete($event, result.id, result.displayName,result.apiKey)"
              [disabled]="!canAddDelete">
              <mat-icon aria-hidden="true">delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="isNoData" class="col-sm-12" style="margin-bottom:2em;">
      <app-note role="info">
        表示するデータがありません。
      </app-note>
    </div>
    <div class="row">
      <div class="col-sm-6 col-sm-offset-3 text-center">
        <pagination *ngIf="groupapikeyresults.length > 0" [totalItems]="count | async " [itemsPerPage]="itemsPerPage" [maxSize]="8"
          [(ngModel)]="currentPage" [nextText]="'pagination_next' | translate" [previousText]="'pagination_prev' | translate" (pageChanged)="pageChanged($event)">
        </pagination>
      </div>
      <div class="col-sm-3 text-right">
        <button mat-raised-button color="primary" (click)="openGroupApiKeyAdd()" [disabled]="!canAddDelete">
          <mat-icon aria-hidden="true">open_in_new</mat-icon> {{'title_add' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
