<h4>{{'title_basic_props' | translate}}</h4>
<div class="row">
  <div class="col-sm-4">
    <div class="panel panel-default">
      <div class="panel-heading">{{'title_device_type_id' | translate}}</div>
      <div class="panel-body">
        {{deviceType.resourceName}}
      </div>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="panel panel-default">
      <div class="panel-heading">{{'title_device_type_name' | translate}}</div>
      <div class="panel-body">
          <app-editable-text #displayName [inputControl]="displayNameControl" [errors]="displayNameErrors" id="display_name" name="display_name" type="text" required
            maxlength="50" [disabled]="!canEdit" (saved)="updateDisplayName($event)"></app-editable-text>
        </div>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="panel panel-default">
      <div class="panel-heading">{{'title_group' | translate}}</div>
      <div class="panel-body">
        {{deviceType.groupDisplayName}}
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-4">
    <div class="panel panel-default">
      <div class="panel-heading">{{'title_communication_type' | translate}}</div>
      <div class="panel-body">
        {{communicationTypeName | translate}}
      </div>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="panel panel-default">
      <div class="panel-heading">{{'title_time_window' | translate}}</div>
      <div class="panel-body">
          <app-editable-text #timeWindow [inputControl]="timeWindowControl" [errors]="timeWindowErrors" id="time_window" name="time_window" type="text" [disabled]="!canEdit"
            (saved)="updateTimeWindow($event)"></app-editable-text>
        </div>
    </div>
  </div>
</div>
<h4>{{'title_prop_def' | translate}}</h4>
<div class="row">
  <div class="col-sm-12">
    <table class="table table-bordered table-hover tableBreakAll">
      <colgroup>
        <col style="width:15%">
        <col style="width:15%">
        <col style="width:11%">
        <col style="width:8%">
        <col style="width:8%">
        <col style="width:7%">
        <col style="width:15%">
        <col style="width:8%">
      </colgroup>
      <thead>
        <tr>
          <th *ngFor="let columns of columnList">
            <label class="labelth" id={{columns.id}}>
              {{columns.displayName | translate}}
            </label>
          </th>
          <th><!-- ボタン配置カラム --></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let propdef of deviceType.propertyDefs" class="active">
          <td *ngFor="let column of columnList">
              <span *ngIf="column.converter">{{column.converter(propdef[column.id]) | translate}}<!-- 変換あり --></span>
              <span *ngIf="!column.converter">{{propdef[column.id]}}<!-- 変換なし --></span>
            </td>
          <td>
            <button mat-icon-button class="icon-btn" matTooltip="{{'title_edit' | translate}}" aria-label="'title_edit' | translate" [disabled]="!canEdit" (click)="openPropertyDef(propdef)">
              <mat-icon aria-hidden="true">edit</mat-icon>
            </button>
            <button mat-icon-button class="icon-btn" matTooltip="{{'title_delete' | translate}}" aria-label="'title_delete' | translate" [disabled]="!canEdit" (click)="confirmDeletion(propdef)">
              <mat-icon aria-hidden="true">delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="isNoData" class="col-sm-12">
      <app-note role="info">{{'msg_no_prop_defs' | translate}}</app-note>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right">
        <button mat-raised-button color="primary" [disabled]="!canEdit" (click)="openPropertyDef()">
          <mat-icon aria-hidden="true">open_in_new</mat-icon> {{'title_add' | translate}}
        </button>
      </div>
    </div>
  </div>
