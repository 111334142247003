<div>
  <div class="row">
    <div class="col-sm-6 text-left">
      <h4 style="margin-bottom:0px;line-height: 36px;">{{'title_basic_props' | translate}}</h4>
    </div>
    <div class="col-sm-6 text-right" style="margin-bottom: 20px;">
      <button mat-raised-button color="accent" (click)="reload()">
        <mat-icon aria-hidden="true">refresh</mat-icon> {{'title_reload' | translate}}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="panel panel-default">
        <div class="panel-heading">{{'title_device_id' | translate}}</div>
        <div class="panel-body">
          {{device.deviceId}}
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="panel panel-default">
        <div class="panel-heading">{{'title_device_name' | translate}}</div>
        <div class="panel-body">
          {{device.displayName}}
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="panel panel-default">
        <div class="panel-heading">{{'title_group' | translate}}</div>
        <div class="panel-body">
          {{device.groupDisplayName}}
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="panel panel-default">
        <div class="panel-heading">{{'title_device_type' | translate}}</div>
        <div class="panel-body">
          {{device.deviceTypeDisplayName}}
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="panel panel-default">
        <div class="panel-heading">{{'title_last_receive_datetime' | translate}}</div>
        <div class="panel-body">
          {{device.lastReceivedDatetime | date:('date_format_ymdhms' | translate)}}
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="panel panel-default">
        <div class="panel-heading">{{'title_store_times' | translate}}</div>
        <div class="panel-body">
          {{device.storeTimes}}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group">
  <h4>{{'title_specific_props' | translate}}</h4>
</div>
<div class="row">
  <ng-container *ngFor="let prop of device.allProperties; let i = index">

    <div *ngIf="prop.type == 'latlng' && prop.visualizationType == 'map'; then mapTemplate else chartOrStringTemplate"></div>
    <ng-template #mapTemplate>
      <div class="col-sm-12">
        <div class="panel panel-default">
          <div class="panel-heading">
            <ng-container *ngIf="prop.isVisible==false">
              ({{'title_hidden_prop' | translate}})
            </ng-container>
            {{prop.displayName}}
          </div>
          <div>
            <app-map [id]="i" [latlng]="prop.value" [editable]="false"></app-map>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #chartOrStringTemplate>
      <div *ngIf="prop.type == 'number' && prop.visualizationType == 'timeseries'; then tsTemplate else stringTemplate"></div>
    </ng-template>
    <ng-template #tsTemplate>
      <div class="col-sm-12">
        <div class="panel panel-default">
          <div class="panel-heading">
            <ng-container *ngIf="prop.isVisible==false">
              ({{'title_hidden_prop' | translate}})
            </ng-container>
            {{prop.displayName}}
          </div>
          <div class="panel-body panel-chart">
            <app-time-series-chart [property]="prop" [id]="i" [deviceid]="device.id"></app-time-series-chart>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #stringTemplate>
      <div class="col-sm-4">
        <div class="panel panel-default">
          <div class="panel-heading">
            <ng-container *ngIf="prop.isVisible==false">
              ({{'title_hidden_prop' | translate}})
            </ng-container>
            {{prop.displayName}}
          </div>
          <div class="panel-body">
            {{prop.value}}{{prop.unit}}
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
