import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeviceGroupDetailComponent } from './device-groupdetail.component';
import {DeviceGroupsResolver} from './device-groupdetail-resolver.service';
import { ApiHandler } from '../services/api-handler.service';
import { AuthGuard } from '../services/auth-guard.service';

const routes: Routes = [
  {
    path: 'device_groups/:group_id',
    resolve: {
      groups: DeviceGroupsResolver
    },
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      {
        path: '', component: DeviceGroupDetailComponent, pathMatch: 'full'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ApiHandler, DeviceGroupsResolver]
})

export class DeviceGroupDetailRoutingModule { }
