import { Method } from '../../common/enums/api-enums';
import { ApiDef, ApiStatusMessage } from './sip-api-client';
import { PropertyDef } from './prop-def';
import { Injectable } from "@angular/core";

/**
 * グループ一覧APIの定義
 */
@Injectable()
export class GroupsGetApi extends ApiDef {
  requestPayloadInterface: {};
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: {
    count: number;
    next: string;
    previous: string;
    results: [
      {
        id: number;
        rootId: number;
        rootDisplayName: string;
        displayName: string;
      }
    ]
  };
  uri = '/groups';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_completed_search' }
  ];
}

/**
 * グループ情報取得APIの定義
 */
export class GroupInfoGetApi extends ApiDef {
  requestPayloadInterface: {};
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: Groups & {
    propertyDefs: PropertyDef[]
  };
  uri = '/groups/{0}';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_completed_search' },
    { code: 404, message: 'toast_group_not_found' }
  ];
}

/**
 * グループ登録APIの定義
 */
@Injectable()
export class GrouspAddApi extends ApiDef {
  requestPayloadInterface: {
    rootId: number;
    displayName: string;
    sshActive: boolean;
  };
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: {
    rootId: number;
    displayName: string;
  };
  uri = '/groups';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
    { code: 201, message: 'toast_registered_group' },
    { code: 400, message: 'toast_input_value_invalid' }
  ];
}

/**
 * グループ更新APIの定義
 */
@Injectable()
export class GroupUpdateApi extends ApiDef {
  requestPayloadInterface: {
    displayName?: string
    ssh_active?: boolean
  };
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: {};
  uri = '/groups/{0}';
  method = Method.PUT;
  statusMsgList: ApiStatusMessage[] = [
    { code: 204, message: 'toast_updated_group' },
    { code: 400, message: 'toast_input_value_invalid' }
  ];
}

/**
 *  グループ　メンバー登録APIの定義
 */
@Injectable()
export class GroupMemberAddApi extends ApiDef {
  requestPayloadInterface: {
    email: string;
    roles: number[];
  };
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: {
  };
  uri = '/groups/{0}/users';
  method = Method.POST;
  // 500 (Internal Server Error) ⇒　ローカル動作確認ではここまで。メンバー登録できたとみなす
  statusMsgList: ApiStatusMessage[] = [
    { code: 201, message: 'toast_assigned_member' },
    { code: 400, message: 'toast_input_value_invalid' },
    { code: 409, message: 'toast_member_already_exists' },
  ];
}

/**
 *  グループ　メンバー更新APIの定義
 */
@Injectable()
export class GroupMemberUpdateApi extends ApiDef {
  requestPayloadInterface: {
    roles: number[];
  };
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: {};
  uri = '/groups/{0}/users/{1}';
  method = Method.PUT;
  statusMsgList: ApiStatusMessage[] = [
    { code: 204, message: 'toast_updated_member' },
    { code: 400, message: 'toast_input_value_invalid' },
  ];
}

/**
 * グループ　メンバー削除APIの定義
 */
export class GroupMemberDeleteApi extends ApiDef {
  requestPayloadInterface: {};
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: {};
  uri = '/groups/{0}/users/{1}';
  method = Method.DELETE;
  statusMsgList: ApiStatusMessage[] = [
    { code: 204, message: 'toast_unassigned_member' },
    { code: 404, message: 'toast_member_not_assigned' },
  ];

}



export interface Groups {
  id: number;
  rootId: number;
  rootDisplayName: string;
  displayName: string;
  sshActive: boolean;
  members: Members[];
  rootUsers: RootUsers[];
  groupRoles: GroupRole[];
}

export interface Members {
  userDisplayName: string;
  userId: number;
  roles: GroupRole[];
}

export interface GroupRole {
  isCommon: boolean;
  id: number;
  displayName: string;
}

export interface RootUsers {
  userDisplayName: string;
  userId: number;
}

