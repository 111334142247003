import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceTypesComponent } from './device-types.component';
import { ConfirmComponent } from '../common/messages/confirm.component';
import { DataService } from '../services/data.service';
import { Functions } from '../common/functions';
import { DeviceTypesRoutingModule } from './device-types-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DeviceTypeAddComponent } from './modal/device-type-add.component';
import { ResourceNameDirective } from '../common/validation/resource-name.directive';
import { AppMaterialModule } from '../common/app-material.module';
import { PartsComponentModule } from '../parts/parts-component.module';
import { QueryStoreService } from '../services/query-store.service';

@NgModule({
  imports: [
    AppMaterialModule,
    DeviceTypesRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PartsComponentModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule,
  ],
  declarations: [
    DeviceTypesComponent,
    DeviceTypeAddComponent,
    ResourceNameDirective
  ],
  providers: [
    DataService,
    Functions,
    QueryStoreService
  ],
  bootstrap: [
    DeviceTypesComponent
  ],
  entryComponents: [
    ConfirmComponent,
    DeviceTypeAddComponent
  ]
})
export class DeviceTypesModule { }
