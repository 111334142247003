import { Injectable } from '@angular/core';

interface I18nInfo {
  lang: string;
}

interface I18nRepository {
  readonly lang: string;
  update(info: I18nInfo): void;
}

/**
 * WebStorageを使って言語設定情報を読み書きする機能を提供する。
 */
@Injectable()
export class WebStorageI18nRepository implements I18nRepository {
  private static readonly LANG_KEY = 'lang';

  get lang() {
    return localStorage.getItem(WebStorageI18nRepository.LANG_KEY) || '';
  }

  update(info: I18nInfo): void {
    localStorage.setItem(WebStorageI18nRepository.LANG_KEY, info.lang);
  }
}
