import { Method } from '../../common/enums/api-enums';
import { ApiDef, ApiStatusMessage, Page } from './sip-api-client';

/**
 * アラート出力APIの定義
 */
export class AlertOutputListApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: {
    start?: Date;
    end?: Date;
    deviceTypeId?: number[];
    deviceId?: number[];
  };

  orderableInterface: {
  };

  responseBodyInterface: Page<AlertOutputForList>;
  uri = '/alert_output';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_completed_download' },
    { code: 400, message: 'toast_input_value_invalid' }
  ];
}

export interface AlertOutputForList {
  id: number;
  deviceTypeDisplayName: string;
  deviceDisplayName: string;
  title: string;
  startDatetime: Date;
  alertType: string;
  propDisplayName: string;
  propValue: string;
  detail: string;
}

