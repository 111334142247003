<mat-toolbar color="primary" class="header">
  <mat-toolbar-row>
    <button mat-icon-button class="sidebar-icon" (click)="sidenav.toggle()" *ngIf="repos.token">
      <mat-icon aria-hidden="true">menu</mat-icon>
    </button>
    <span class="fill-space">
      <h1><a routerLink="/">Simple IoT Platform</a></h1>
    </span>
    <button id="menu" mat-icon-button [matMenuTriggerFor]="menu" *ngIf="repos.token">
      <mat-icon aria-hidden="true">person</mat-icon>
      <span class="caret"></span>
    </button>
    <mat-menu class="menu" #menu="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="i18n">
        <mat-icon aria-hidden="true">language</mat-icon>{{'title_lang' | translate}}
      </button>
      <a mat-menu-item routerLink="/user/password">
        <mat-icon aria-hidden="true">lock</mat-icon>
        <span>{{'title_change_password' | translate}}</span>
      </a>
      <a mat-menu-item routerLink="/login" (click)="apiHandler.eraseToken()">
        <mat-icon aria-hidden="true">exit_to_app</mat-icon>
        <span>{{'title_logout' | translate}}</span>
      </a>
    </mat-menu>
    <mat-menu class="menu" #i18n="matMenu">
      <button mat-menu-item (click)="changeLang('en')">English</button>
      <button mat-menu-item (click)="changeLang('ja')">日本語</button>
    </mat-menu>
  </mat-toolbar-row>
  <mat-toolbar-row class="progress" [style.display]="isCalling ? 'block' : 'none'">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container fullscreen>
  <mat-sidenav class="sidebar" role="navigation">
    <mat-list>
      <a *ngFor="let link of sidenavLinks" mat-list-item [routerLink]="link.path" (click)="onNavigate(link)">{{link.label | translate}}</a>
    </mat-list>
  </mat-sidenav>
  <mat-sidenav-content role="main">
    <!-- Not Logged in -->
    <ng-container *ngIf="!repos.token">
      <div class="container main">
        <router-outlet></router-outlet>
      </div>
    </ng-container>
    <!-- Logged in -->
    <ng-container *ngIf="repos.token">
      <div class="container main">
        <mat-card>
          <mat-card-content>
            <router-outlet></router-outlet>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
    <!--/.container-->
  </mat-sidenav-content>
</mat-sidenav-container>
