import { Method } from '../../common/enums/api-enums';
import { ApiDef, ApiStatusMessage } from './sip-api-client';

/**
 * ユーザ情報APIの定義
 */
export class UserGetApi extends ApiDef {
  requestPayloadInterface: {};
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: {
    isSuperuser: boolean;
  };
  uri = '/user_profile';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_completed_search' }
  ];
}

/**
 * リロード間隔取得APIの定義
 */
export class ReloadIntervalGetApi extends ApiDef {
  requestPayloadInterface: {};
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: {
    interval: number;
  };
  uri = '/reload_interval';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_completed_search' }
  ];
}

