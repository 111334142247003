<div class="modal-header">
  <h4 class="modal-title pull-left">{{'title_device_group_registration' | translate}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="deviceGroupsForm">
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <mat-select [formControl]="selectedGroup" placeholder="{{'title_group' | translate}}" required>
                <mat-option></mat-option>
                <mat-option *ngFor="let dropdownGroup of deviceGroups" [value]="dropdownGroup.value">{{dropdownGroup.name}}</mat-option>
              </mat-select>
              <mat-error *ngIf="selectedGroup.touched && selectedGroup.errors?.required">{{'msg_group_name_required' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <input matInput id="display_name" name="display_name" type="text" [formControl]="displayName" placeholder="{{'title_device_group_name' | translate}}" required>
              <mat-error *ngIf="displayName.touched && displayName.errors?.required">{{'msg_device_group_name_required' | translate}}</mat-error>
              <mat-error *ngIf="displayName.touched && displayName.errors?.maxlength">{{'mag_device_group_name_must_be_50_letters_or_less' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<div class="modal-footer">
  <button mat-raised-button color="primary" [disabled]="deviceGroupsForm.invalid" (click)="groupsAdd()">
    <mat-icon aria-hidden="true">save</mat-icon> {{'title_save' | translate}}
  </button>
  <button mat-raised-button (click)="bsModalRef.hide()">
    <mat-icon aria-hidden="true">clear</mat-icon> {{'title_cancel' | translate}}
  </button>
</div>
