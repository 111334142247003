<h2>{{'title_device_list' | translate}}</h2>

<!-- 検索折り畳み start -->
<div class="row">
  <div class="col-sm-12 text-right">
    <button (click)="isExpanded = !isExpanded">
      <span class="glyphicon"
        [ngClass]="{'glyphicon-menu-down': !isExpanded, 'glyphicon-menu-up': isExpanded }"></span>
    </button>
  </div>
</div>
<mat-expansion-panel [expanded]="isExpanded" hideToggle class="noPanelStyle">
<!-- 検索折り畳み end -->

<!-- 検索フォーム -->
<mat-card class="search-form">
  <mat-card-content>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <input matInput id="display_name" name="display_name" type="text" [(ngModel)]="condition.displayName" placeholder="{{'title_device_name' | translate}}">
          <mat-hint align="end">{{'p_holder_partial_match' | translate}}</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <input matInput id="device_id" name="device_id" type="text" [(ngModel)]="condition.deviceId" placeholder="{{'title_device_id' | translate}}">
          <mat-hint align="end">{{'p_holder_partial_match' | translate}}</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <mat-select [(ngModel)]="condition.groupId" placeholder="{{'title_group' | translate}}" multiple>
            <mat-option disabled="disabled" class="select-action">
              <button mat-button (click)="condition.groupId = []">
                <mat-icon aria-hidden="true">clear</mat-icon> {{'title_clear_all_selections' | translate}}
              </button>
            </mat-option>
            <mat-option *ngFor="let group of dropdownGroups" [value]="group.value">{{group.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <mat-select [(ngModel)]="condition.deviceTypeId" placeholder="{{'title_device_type' | translate}}" multiple>
            <mat-option disabled="disabled" class="select-action">
              <button mat-button (click)="condition.deviceTypeId = []">
                <mat-icon aria-hidden="true">clear</mat-icon> {{'title_clear_all_selections' | translate}}
              </button>
            </mat-option>
            <mat-option *ngFor="let type of dropdownDeviceTypes" [value]="type.value">{{type.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <mat-select [(ngModel)]="condition.deviceGroupId" placeholder="{{'title_device_group' | translate}}" multiple>
            <mat-option disabled="disabled" class="select-action">
              <button mat-button (click)="condition.deviceGroupId = []">
                <mat-icon aria-hidden="true">clear</mat-icon> {{'title_clear_all_selections' | translate}}
              </button>
            </mat-option>
            <mat-option *ngFor="let deviceGroup of dropdownDeviceGroups" [value]="deviceGroup.value">{{deviceGroup.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <mat-select [(ngModel)]="condition.hasAlert" placeholder="{{'title_alert_existence' | translate}}">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let alert of dropdownHasAlert" [value]="alert.value">{{alert.name | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right">
        <button type="submit" mat-raised-button color="accent" (click)="clickSearch()">
          <mat-icon aria-hidden="true">search</mat-icon> {{'title_search' | translate}}
        </button>
        <button type="button" mat-icon-button matTooltip="{{'title_clear_conditions' | translate}}" (click)="condition.clear()">
          <mat-icon aria-hidden="true">clear</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
</mat-expansion-panel>

<!-- 検索結果 -->
<mat-card class="result-table">
  <mat-card-content>
    <table class="table table-bordered table-hover tableBreakAll" >
      <colgroup>
        <col style="width:1%">
        <col style="width:14%">
        <col style="width:12%">
        <col style="width:14%">
        <col style="width:14%">
        <col style="width:14%">
        <col style="width:4%">
        <col style="width:4%">
        <col style="width:14%">
        <col style="width:4%">
        <col style="width:4%">
      </colgroup>
      <thead>
        <tr>
          <th class="checkboxth">
            <mat-checkbox [checked]="allchecked" (change)="cbAll($event.checked)"></mat-checkbox>
          </th>
          <th *ngFor="let column of columnList">
            <button mat-button *ngIf="column.issort" id={{column.id}} class="btn-order" (click)="sortDevices(column.id)">
              <div [ngSwitch]="condition.currentSortDirection(column.id)">
                <span>
                  <b>{{column.displayName | translate}}</b>
                </span>
                <span *ngSwitchDefault class="fa fa-sort" aria-hidden="true"></span>
                <span *ngSwitchCase="'asc'" class="fa fa-sort-asc" aria-hidden="true"></span>
                <span *ngSwitchCase="'desc'" class="fa fa-sort-desc" aria-hidden="true"></span>
              </div>
            </button>
            <label class="labelth" *ngIf="!column.issort" id={{column.id}}>
              {{column.displayName | translate}}
            </label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of devicesresults" class="active">
          <td>
            <mat-checkbox [(ngModel)]="result.isChecked"></mat-checkbox>
          </td>
          <td [attr.data-label]="columnList[0].displayName | translate">
            <a [routerLink]="['/devices', result.id]">{{result.displayName}}</a>
          </td>
          <td [attr.data-label]="columnList[1].displayName | translate">{{result.deviceId}}</td>
          <td hidden>{{result.groupId}}</td>
          <td [attr.data-label]="columnList[2].displayName | translate">{{result.groupDisplayName}}</td>
          <td hidden>{{result.deviceTypeId}}</td>
          <td [attr.data-label]="columnList[3].displayName | translate">{{result.deviceTypeDisplayName}}</td>
          <td [attr.data-label]="columnList[4].displayName | translate">{{result.deviceGroupDisplayName}}</td>
          <td [attr.data-label]="columnList[5].displayName | translate">
            <mat-icon [color]="result.hasAlertIcon.color" [matTooltip]="result.hasAlertIcon.label | translate" aria-hidden="true">
              {{result.hasAlertIcon.name}}
            </mat-icon>
          </td>
          <td [attr.data-label]="columnList[6].displayName | translate">
            <button mat-icon-button class="icon-btn" (click)="deviceStatusToggle(result)">
              <mat-icon [color]="result.statusIcon.color" [matTooltip]="result.statusIcon.label | translate" aria-hidden="true">
                {{result.statusIcon.name}}
              </mat-icon>
            </button>
          </td>
          <td [attr.data-label]="columnList[7].displayName | translate">{{result.lastReceivedDatetime | date:('date_format_ymdhms' | translate)}}</td>
          <td [attr.data-label]="columnList[8].displayName | translate">
            <button mat-icon-button class="icon-btn" (click)="alertToggle(result)">
              <mat-icon [color]="result.isSubscribedIcon.color" [matTooltip]="result.isSubscribedIcon.label | translate" aria-hidden="true">
                {{result.isSubscribedIcon.name}}
              </mat-icon>
            </button>
          </td>
          <td [attr.data-label]="columnList[9].displayName | translate">
            <button mat-icon-button class="icon-btn" (click)="deviceToggle(result)">
              <mat-icon [color]="result.isMutedIcon.color" [matTooltip]="result.isMutedIcon.label | translate" aria-hidden="true">
                {{result.isMutedIcon.name}}
              </mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="isNoData" class="col-sm-12">
      <app-note role="info">{{'msg_no_data_to_display' | translate}}</app-note>
    </div>
    <div class="row">
      <div class="col-sm-6 col-sm-offset-3 text-center">
        <pagination *ngIf="devicesresults.length > 0" [totalItems]="count" [itemsPerPage]="condition.pageSize" [maxSize]="8" [(ngModel)]="condition.page"
            [nextText]="'pagination_next' | translate" [previousText]="'pagination_prev' | translate" (pageChanged)="pageChanged($event)">
        </pagination>
      </div>
      <div class="col-sm-3 text-right">
        <button mat-raised-button color="primary" (click)="openDeviceAdd()">
          <mat-icon aria-hidden="true">open_in_new</mat-icon> {{'title_add' | translate}}
        </button>
        <button mat-raised-button color="warn" (click)="clickDelete()" [disabled]="!isDeletable">
          <mat-icon aria-hidden="true">delete</mat-icon> {{'title_delete' | translate}}
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
