<div class="modal-header">
  <h4 class="modal-title pull-left">{{title | translate}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="groupApiKeyForm">
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <input matInput type="text" [formControl]="displayName" placeholder="{{'title_api_key_name' | translate}}" required>
              <mat-error *ngIf="displayName.touched && displayName.errors?.required">{{'msg_api_key_name_required' | translate}}</mat-error>
              <mat-error *ngIf="displayName.touched && displayName.errors?.maxlength">{{'mag_api_key_name_must_be_50_letters_or_less' | translate}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-4" style="width:auto;">
            <mat-form-field style="margin-bottom:0%;">
              <input matInput [matDatepicker]="picker" id="expiration_date" name="expiration_date" [formControl]="calendar" type="text"
                placeholder="{{'title_expiration_date' | translate}}" autocomplete="off" required>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <button mat-raised-button (click)="picker.open()" style="min-width:40px; min-height:40px; padding-left:0px; padding-right:0px;">
              <mat-icon aria-hidden="true" matTooltip="{{'msg_open_calendar' | translate}}">event_note</mat-icon>
            </button>
            <div class="mat-error ng-star-inserted" *ngIf="calendar.touched && calendar.errors" style="font-size:75%;">
              <div [hidden]="!calendar.errors.required">{{'msg_expiration_date_required' | translate}}</div>
            </div>
          </div>
          <div class="col-sm-4">
            <table>
              <tr>
                <td>
                  <timepicker id="expiration_time" name="expiration_time" [(ngModel)]="expirationDate" [ngModelOptions]="{standalone: true}"
                    [mousewheel]="mousewheel" [showMeridian]="showMer" [showSeconds]="showSec" [min]="minTime" [max]="maxTime"
                    value="{expirationTime}"></timepicker>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<div class="modal-footer">
  <button mat-raised-button color="primary" [disabled]="!groupApiKeyForm.dirty || groupApiKeyForm.invalid" (click)="groupApiKeyEddit()">
    <mat-icon aria-hidden="true">save</mat-icon> {{'title_save' | translate}}
  </button>
  <button mat-raised-button (click)="bsModalRef.hide()">
    <mat-icon aria-hidden="true">clear</mat-icon> {{'title_cancel' | translate}}
  </button>
</div>
