import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DeviceTypeUpdateApi, DeviceTypeGetApi, CommunicationType } from '../../common/api/device-type';
import { ApiHandler } from '../../services/api-handler.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PropType, VisualizationType, PropertyDef, PropertyDefDeleteApi } from '../../common/api/prop-def';
import { WebStorageAuthRepository } from '../../services/auth-repository.service';
import { EditableTextComponent } from '../../parts/editable-text.component';
import { PropertyDefEditComponent } from '../modal/property-def-edit.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmComponent } from '../../common/messages/confirm.component';
import { Subscription } from 'rxjs';
import { RouterService } from '../../services/router.service';
import { ColumnDefinition } from '../../common/interfaces/display-interfaces';

@Component({
  selector: 'app-device-type-detail',
  templateUrl: './detail.component.html'
})
export class DetailComponent implements OnInit, OnDestroy {
  isNoData: boolean = false;
  deviceType: DeviceTypeGetApi['responseBodyInterface'];
  get communicationTypeName(): string {
    return CommunicationType.toName(this.deviceType.communicationType);
  }
  @ViewChild('displayName', {static: false}) private displayName: EditableTextComponent;
  displayNameControl: FormControl;
  @ViewChild('timeWindow', {static: false}) private timeWindow: EditableTextComponent;
  timeWindowControl: FormControl;

  readonly displayNameErrors = [{
    key: 'required',
    message: 'msg_device_type_name_required'
  }, {
    key: 'maxlength',
    message: 'msg_device_type_name_bust_be_50_or_less'
  }];

  readonly timeWindowErrors = [{
    key: 'min',
    message: 'msg_time_window_must_be_positive'
  }, {
    key: 'max',
    message: 'msg_time_window_must_be_99999999_or_less'
  }, {
    key: 'pattern',
    message: 'msg_time_window_must_be_integer'
  }];

  /** デバイスタイプを編集できるかどうか */
  get canEdit() {
    return this.authRepo.hasPermission('device_type', 'edit');
  }

  /** プロパティ定義表のカラム */
  readonly columnList: ColumnDefinition[] = [
    { seq: 1, id: 'resourceName', displayName: 'title_resource_name' },
    { seq: 2, id: 'displayName', displayName: 'title_prop_name' },
    { seq: 3, id: 'type', displayName: 'title_prop_type', converter: PropType.toName },
    {
      seq: 4, id: 'isVisible', displayName: 'title_vsl_flag', converter: (isVisible: boolean) => {
        return isVisible ? '〇' : '×';
      }
    },
    {
      seq: 5, id: 'measurementKey', displayName: 'title_time_series_flag', converter: (measurementKey: string) => {
        return measurementKey ? '〇' : '×';
      }
    },
    { seq: 6, id: 'visualizationType', displayName: 'title_vsl_type', converter: VisualizationType.toName },
    { seq: 7, id: 'unit', displayName: 'title_prop_unit' },
  ];

  constructor(
    public t: TranslateService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    private authRepo: WebStorageAuthRepository,
    private apiHandler: ApiHandler,
    private confirmMessage: BsModalService,
    private modalService: BsModalService) {
  }

  /** 親コンポーネントの解決済みデータを受け取るイベントの購読状態 */
  private parentSubscription = Subscription.EMPTY;

  ngOnInit(): void {
    // 入力コントロール初期化
    this.displayNameControl = new FormControl(null);
    this.timeWindowControl = new FormControl(null, [
      Validators.min(0),
      Validators.max(99999999),
      Validators.pattern(/^[-+]?(?:[1-9][0-9]*|0)$/)
    ]);
    this.parentSubscription = this.route.parent.data.subscribe(obj => {
      // 親コンポーネントのデバイスタイプをそのまま使わせていただく
      this.deviceType = obj['deviceType'];
      // 入力コントロールの値に当該デバイスタイプの値をセットする
      this.displayNameControl.reset(this.deviceType.displayName);
      this.timeWindowControl.reset(this.deviceType.timeWindow);
      if (this.deviceType.propertyDefs.length === 0) {
        this.isNoData = true;
      } else {
        this.isNoData = false;
      }
    });
  }

  ngOnDestroy(): void {
    // イベント購読を停止する
    this.parentSubscription.unsubscribe();
  }

  updateDisplayName(event: any) {
    this.update({ displayName: event });
  }

  updateTimeWindow(event: any) {
    this.update({ timeWindow: +event });
  }

  openPropertyDef(propDef?: PropertyDef) {
    const modalRef = this.modalService.show(PropertyDefEditComponent, { class: 'modal-dialog modal-lg', backdrop: 'static' });
    modalRef.content.parentDeviceTypeId = this.deviceType.id;
    if (propDef) {
      // 引数にプロパティ定義が指定されたら、その定義を更新するモードにする
      modalRef.content.prepareForEdit(propDef);
    }
    this.modalService.onHide.subscribe(() => {
      if (modalRef.content.isOk) {
        this.routerService.reload();
        modalRef.content.isOk = false;
      }
    });
  }

  /** デバイスタイプ更新APIで更新する */
  private update(request: DeviceTypeUpdateApi['requestPayloadInterface']) {
    this.apiHandler.call(true, DeviceTypeUpdateApi, [this.deviceType.id], request)
      .subscribe(res => {
        if (!res.hasError) {
          // 対応するモデルを更新して、編集を終了する
          if (request.displayName) {
            this.displayName.finish();
          }
          if (request.timeWindow) {
            this.timeWindow.finish();
          }
          this.routerService.reload();
        }
      });
  }

  /**
   * 削除確認ダイアログ
   */
  confirmDeletion(propDef: PropertyDef) {

    const addmessages: Array<object> = [];
    addmessages.push({ message: this.t.instant(this.columnList[0].displayName) + '：' + propDef.resourceName });
    addmessages.push({ message: this.t.instant(this.columnList[1].displayName) + '：' + propDef.displayName });

    // 確認ダイアログ
    const modalRef = this.confirmMessage.show(ConfirmComponent, { backdrop: 'static' });
    modalRef.content.title = this.t.instant('title_confirmation');
    modalRef.content.messageHead = this.t.instant('msg_confirm_delete_prop_def');
    modalRef.content.messageDetail = addmessages;

    this.confirmMessage.onHide
      .subscribe(() => {
        if (modalRef.content.isOk) {
          this.deletePropertyDef(propDef.id);
          modalRef.content.isOk = false;
        }
      });
    return true;
  }

  /** プロパティ定義を削除する */
  private deletePropertyDef(id: number) {
    this.apiHandler.call(true, PropertyDefDeleteApi, [id])
      .subscribe(res => {
        if (!res.hasError) {
          this.routerService.reload();
        }
      });
  }

}
