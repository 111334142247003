import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlertLogsComponent } from './alert-logs.component';
import { AuthGuard } from '../services/auth-guard.service';
import { ApiHandler } from '../services/api-handler.service';

const routes: Routes = [
  {
    path: 'alert_logs/:alert_log_id',
    component: AlertLogsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'alert_logs',
    component: AlertLogsComponent,
    canActivate: [ AuthGuard ],
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes)],
  exports: [ RouterModule ]
})
export class AlertLogsRoutingModule { }
