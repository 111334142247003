import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceComponent } from './device.component';
import { DeviceRoutingModule } from './device-routing.module';
import { DataService } from '../services/data.service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DetailComponent } from './detail/detail.component';
import { PropLogsComponent } from './prop-logs/prop-logs.component';
import { EditComponent } from './edit/edit.component';
import { UnitPipe } from '../common/pipes/unit.pipe';
import { PropertyTypePipe } from '../common/pipes/propertytype.pipe';
import { AlertDefsComponent } from './alert-defs/alert-defs.component';
import { MapEditComponent } from './modal/map-edit.component';
import { ConfirmComponent } from '../common/messages/confirm.component';
import { DeviceResolver } from './device-resolver.service';
import { AppMaterialModule } from '../common/app-material.module';
import { PartsComponentModule } from '../parts/parts-component.module';
import {RemoteComponent} from './remote/remote.component';


@NgModule({
  imports: [
    DeviceRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    PartsComponentModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    TranslateModule,
  ],
  declarations: [
    DeviceComponent,
    DetailComponent,
    PropLogsComponent,
    RemoteComponent,
    EditComponent,
    MapEditComponent,
    UnitPipe, PropertyTypePipe,
    AlertDefsComponent,
  ],
  providers: [
    DataService,
    EditComponent,
    DeviceResolver
  ],
  bootstrap: [
    DeviceComponent
  ],
  entryComponents: [
    MapEditComponent,
    ConfirmComponent
  ]
})
export class DeviceModule { }

