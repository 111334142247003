import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHandler } from '../services/api-handler.service';
import { DataService } from '../services/data.service';
interface Token {
  token: string;
}
@Component({
  moduleId: module.id,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email = '';
  password = '';
  message = '';
  token: Token;
  authUrl = '/api/auth';
  /**
   * 初期処理
   */
  constructor(public apiHandler: ApiHandler, public dataService: DataService, public router: Router) { }

  /**
   *
   * 初期設定処理(クエリパラメータを読む)
   */
  ngOnInit() {
  }


  /**
   * ログイン処理
   */
  login() {
    // 認証APIコール
    // const tokenObservable = this.dataService.getNewToken(this.email, this.password);
    const tokenObservable = this.apiHandler.authenticate(this.email, this.password);
    tokenObservable.subscribe(
      authResult => {
        if (!authResult.success) {
          // 認証NG
          this.message = authResult.message;
        } else {
          // 認証OK
          const redirect = this.apiHandler.redirectUrl ? this.apiHandler.redirectUrl : '/';
          this.router.navigate([redirect]);
        }
      },
      error => {
        alert('error:' + error.status + error.statusText);
        console.error(error.status + ':' + error.statusText);
      }
    );
  }


  /**
   * 入力チェック処理
   */
  checkInputValue(): boolean {
    const disabled = (!this.email || !this.password);
    return disabled;
  }
}
