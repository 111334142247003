import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UserInfoComponent } from './user-info.component';
import { UserPasswordComponent } from './user-password.component';
import { UserRoutingModule } from './user-routing.module';
import { EqualsValidatorDirective } from '../common/validation/equal-validator.directive';
@NgModule({
  imports: [
    UserRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule
  ],
  declarations: [
    UserInfoComponent,
    UserPasswordComponent,
    EqualsValidatorDirective
  ]
})
export class UserModule { }
