<div class="modal-header">
  <h4 class="modal-title pull-left">{{alert.title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-xs-6 col-sm-4">
      <h4>{{'title_ooccurrence_datetime' | translate}}</h4>
      <div>
        {{alert.startDatetime | date:('date_format_ymdhms' | translate)}}
      </div>
    </div>
    <div class="col-xs-6 col-sm-4">
      <h4>{{'title_termination_datetime' | translate}}</h4>
      <div *ngIf="alert.endDatetime">
        {{alert.endDatetime | date:('date_format_ymdhms' | translate)}}
      </div>
      <div *ngIf="!alert.endDatetime">
        <mat-icon aria-hidden="true" [color]="alert.alertActiveIcon.color">{{alert.alertActiveIcon.name}}</mat-icon> {{alert.alertActiveName | translate}}
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-xs-12">
      <h4>{{'title_mail_state' | translate}}</h4>
      <div>
        <mat-icon aria-hidden="true" [color]="alert.mailStatusIcon.color">{{alert.mailStatusIcon.name}}</mat-icon> {{alert.mailStatusName | translate}}
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-xs-12">
      <h4>{{'title_device_name' | translate}} / {{'title_device_id' | translate}}</h4>
      <div>
        {{alert.deviceDisplayName}} / {{alert.deviceId}}
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-xs-6">
      <h4>{{'title_detail' | translate}}</h4>
      <div>
        {{alert.detail}}
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button mat-raised-button color="background" (click)="bsModalRef.hide()">
    <mat-icon aria-hidden="true">clear</mat-icon> {{'title_close' | translate}}
  </button>
</div>
