import { Method } from '../../common/enums/api-enums';
import { ApiDef, ApiStatusMessage } from './sip-api-client';
import { PropertyDef } from './prop-def';
import { Injectable } from "@angular/core";

/**
 * デバイスグループ一覧APIの定義
 */
@Injectable()
export class DeviceGroupsGetApi extends ApiDef {
  requestPayloadInterface: {};

  queryInterface: {
    groupId?: number[];
  };
  orderableInterface: {};
  responseBodyInterface: {
    count: number;
    next: string;
    previous: string;
    results: [
      {
        id: number;
        groupId: number;
        groupDisplayName: string;
        displayName: string;
      }
    ]
  };
  uri = '/device_groups';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_completed_search' }
  ];
}

/**
 * デバイスグループ情報取得APIの定義
 */
export class DeviceGroupInfoGetApi extends ApiDef {
  requestPayloadInterface: {};
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: DeviceGroups & {
    propertyDefs: PropertyDef[]
  };
  uri = '/device_groups/{0}';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_completed_search' },
    { code: 404, message: 'toast_device_group_not_found' }
  ];
}

/**
 * デバイスグループ登録APIの定義
 */
@Injectable()
export class DeviceGrouspAddApi extends ApiDef {
  requestPayloadInterface: {
    groupId: number;
    displayName: string;
  };
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: {
    rootId: number;
    displayName: string;
  };
  uri = '/device_groups';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
    { code: 201, message: 'toast_registered_device_group' },
    { code: 400, message: 'toast_input_value_invalid' }
  ];
}

/**
 * デバイスグループ更新APIの定義
 */
@Injectable()
export class DeviceGroupUpdateApi extends ApiDef {
  requestPayloadInterface: {
    displayName?: string
  };
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: {};
  uri = '/device_groups/{0}';
  method = Method.PUT;
  statusMsgList: ApiStatusMessage[] = [
    { code: 204, message: 'toast_updated_device_group' },
    { code: 400, message: 'toast_input_value_invalid' }
  ];
}

/**
 * デバイスグループ削除APIの定義
 */
export class DeviceGroupDeleteApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/device_groups/{0}';
  method = Method.DELETE;
  statusMsgList: ApiStatusMessage[] = [
      { code: 204, message: 'toast_deleted_device_group' },
      { code: 400, message: 'msg_device_group_delete_has_devices' },
      { code: 404, message: 'toast_device_group_not_found' }
  ];
}

export interface DeviceGroups {
  id: number;
  displayName: string;
  groupDisplayName: string;
  devices: Device[];
}

export interface Device {
  displayName: string;
  deviceId: string;
}


