import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AlertOutputComponent} from './alert-output.component';
import { AuthGuard } from '../services/auth-guard.service';
import { ApiHandler } from '../services/api-handler.service';

const routes: Routes = [
  {
    path: 'alert_output',
    component: AlertOutputComponent,
    canActivate: [ AuthGuard ],
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes)],
  exports: [ RouterModule ]
})
export class AlertOutputRoutingModule { }
