import { Method } from '../../common/enums/api-enums';
import { ApiDef, ApiStatusMessage } from './sip-api-client';


/**
 * 認証APIの定義
 */
export class AuthPostApi extends ApiDef {
  requestPayloadInterface: {
    email: string;
    password: string;
  };
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: AuthInfo;
  uri = '/auth';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_login_success' },
    { code: 400, message: 'toast_login_fail' },
    { code: 401, message: 'toast_login_fail' }
  ];
}

/** 権限のリソース */
export type PermissionResource = 'api_key' | 'device' | 'device_type' | 'group_member' | 'group';

/** リソースに対するアクション */
export type PermissionAction = 'view' | 'add_delete' | 'edit';

/** 権限情報 */
export interface Permission {
  resource: PermissionResource;
  action: PermissionAction;
  groupIds: number[];
}

/** ルート情報 */
export interface Root {
  id: number;
  displayName: string;
}

/** 認証情報 */
export interface AuthInfo {
  token: string;
  permissions: Permission[];
  roots: Root[];
}

/**
 * 認証更新APIの定義
 */
export class AuthRefreshApi extends ApiDef {
  requestPayloadInterface: {
    token: string;
  };
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: AuthInfo;
  uri = '/auth_refresh';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_auth_refresh_success' },
    { code: 400, message: 'toast_token_refresh_fail' },
    { code: 401, message: 'toast_session_end' }
  ];
}
