import { ApiDef, ApiStatusMessage } from './sip-api-client';
import { Method } from '../enums/api-enums';

/**
 * プロパティ定義登録APIの定義
 */
export class PropertyDefAddApi extends ApiDef {
  requestPayloadInterface: {
    deviceTypeId: number;
    resourceName: string;
    displayName: string;
    unit?: string;
    type: PropType;
    isVisible?: boolean;
    visualizationType: VisualizationType;
    defaultValue?: string;
    displayOrder?: number;
    measurementKey?: string;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/property_defs';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
    { code: 201, message: 'toast_registered_prop_def' },
    { code: 400, message: 'toast_input_value_invalid' },
    { code: 409, message: 'toast_duplicate_prop_def_key' }
  ];
}

/**
 * プロパティ定義更新APIの定義
 */
export class PropertyDefUpdateApi extends ApiDef {
  requestPayloadInterface: {
    displayName: string;
    unit?: string;
    type: PropType;
    isVisible?: boolean;
    visualizationType: VisualizationType;
    defaultValue?: string;
    displayOrder?: number;
    measurementKey?: string;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/property_defs/{0}';
  method = Method.PUT;
  statusMsgList: ApiStatusMessage[] = [
    { code: 204, message: 'toast_updated_prop_def' },
    { code: 400, message: 'toast_input_value_invalid' },
    { code: 409, message: 'toast_duplicate_prop_def_key' }
  ];
}

/**
 * プロパティ定義削除APIの定義
 */
export class PropertyDefDeleteApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/property_defs/{0}';
  method = Method.DELETE;
  statusMsgList: ApiStatusMessage[] = [
    { code: 204, message: 'toast_deleted_prop_def' },
    { code: 404, message: 'toast_prop_def_not_found' }
  ];
}

export interface PropertyDef {
  id: number;
  resourceName: string;
  displayName: string;
  unit: string;
  type: PropType;
  isVisible: boolean;
  visualizationType: VisualizationType;
  defaultValue: string;
  displayOrder: number;
  measurementKey: string;
}

/**
 * プロパティの型。プロパティが取りうる値の種類を表す。
 *
 * - string
 * - number
 * - latlng
 *
 * の3種類がある。
 */
export type PropType = 'string' | 'number' | 'latlng';
export namespace PropType {
  const mapping = {
    'string': 'title_string',
    'number': 'title_number',
    'latlng': 'title_latlng',
  };

  export const values = (): PropType[] => {
    return Object.keys(mapping) as PropType[];
  };

  export const toName = (type: PropType): string => {
    const name = mapping[type];
    if (name == null) {
      throw new Error(`Invalid property type: ${type}.`);
    }
    return name;
  };
}

/**
 * 可視化方式。プロパティを画面上でどのように表示するかを表す。
 *
 * - string
 * - timeseries
 * - map
 *
 * の3種類の方式がある。
 */
export type VisualizationType = 'string' | 'timeseries' | 'map';
export namespace VisualizationType {
  const mapping = {
    'string': 'title_string',
    'timeseries': 'title_timeseries',
    'map': 'title_map'
  };

  export const values = (): VisualizationType[] => {
    return Object.keys(mapping) as VisualizationType[];
  };

  export const toName = (type: VisualizationType): string => {
    const name = mapping[type];
    if (name == null) {
      throw new Error(`Invalid visualizationerty type: ${type}.`);
    }
    return name;
  };

  /** プロパティ型と組み合わせられる可視化方式を配列で返す。 */
  export const forPropType = (propType: PropType): VisualizationType[] => {
    switch (propType) {
      case 'string':
        return ['string'];
      case 'latlng':
        return ['string', 'map'];
      case 'number':
        return ['string', 'timeseries'];
      default:
        return values();
    }
  };
}
