  <div class="row">
    <div class="col-sm-10">
      <!-- タイトル的な位置に表示するデバイスタイプ名 -->
      <h2>{{deviceType.displayName}}</h2>
    </div>
    <div class="col-sm-2 text-right">
      <a mat-raised-button role="button" [routerLink]="['/device_types']">
        <mat-icon aria-hidden="true">arrow_upward</mat-icon> {{'title_back_to_list' | translate}}
      </a>
    </div>
  </div>
  <!-- デバイスタイプに関する各画面に遷移するタブナビゲーション -->
  <nav mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let link of tabLinks" [routerLink]="link.path" [routerLinkActiveOptions]="link.options || {}" routerLinkActive
      #rla="routerLinkActive" [active]="rla.isActive">{{link.label | translate}}</a>
  </nav>
  <!-- タブごとの領域 -->
  <router-outlet></router-outlet>
