import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GroupsComponent } from './groups.component';
import { AuthGuard } from '../services/auth-guard.service';

const routes: Routes = [
  {
    path: 'groups',
    component: GroupsComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class GroupsRoutingModule { }
