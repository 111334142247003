<div class="row">
  <div class="col-sm-10">
    <h4>{{'title_lpwa_packets' | translate}}</h4>
  </div>
  <div class="col-sm-2 text-right">
    <button mat-raised-button color="primary" [disabled]="!canEdit" (click)="openPacketTypeEdit()">
      <mat-icon aria-hidden="true">open_in_new</mat-icon>{{'title_add' | translate}}
    </button>
  </div>
  <div *ngIf="deviceType.lpwaPacketTypes.length === 0" class="col-sm-12">
    <app-note role="info">{{'msg_no_packet_types' | translate}}</app-note>
  </div>
</div>
<ng-container *ngFor="let packetType of deviceType.lpwaPacketTypes">
  <div class="row">
    <div class="col-sm-12">
      <app-lpwa-packet-type [packetType]="packetType" [propertyDefs]="deviceType.propertyDefs" [editable]="canEdit" (clickEdit)="openPacketTypeEdit(packetType)" (clickDelete)="confirmDeletion(packetType)"></app-lpwa-packet-type>
    </div>
  </div>
</ng-container>
