import { Component, OnInit　} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiPagenation } from '../common/api/sip-api-client';
import { Observable, forkJoin } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeviceGroupsAddComponent } from './modal/device-groups-add.component';
import { WebStorageAuthRepository } from '../services/auth-repository.service';
import { ApiHandler } from '../services/api-handler.service';
import {DeviceGroupDeleteApi, DeviceGroupsGetApi} from '../common/api/device-group';
import {GroupsGetApi} from '../common/api/group';
import {DialogService} from '../services/dialog.service';
import {Functions} from '../common/functions';
import {PageableSearchCondition} from '../common/view/PageableSearchCondition';

@Component({
  moduleId: module.id,
  templateUrl: 'device-groups.component.html',
  providers: [DeviceGroupsGetApi]
})
export class DeviceGroupsComponent implements OnInit {

  isNoData: boolean = false;
  canEditGroups = [];

  constructor(
    public t: TranslateService,
    public apiHandler: ApiHandler,
    private modalService: BsModalService,
    private authRepository: WebStorageAuthRepository,
    private dialogService: DialogService,
    private functions: Functions
  ) { }

  // interfaceModel
  deviceGroupsResults: Array<{
    id: Number,               // デバイスグループユニークID
    groupId: Number,          // グループユニークID
    displayName: string       // デバイスグループ名
    groupDisplayName: string  // 所属グループ名
  } & Checkable> = [];

  status: number;

  // Pagination
  count: Observable<number>; // 全件数
  next: string;              // 次ページURL
  previous: string;          // 前ページURL
  currentPage = 1;
  itemsPerPage = PageableSearchCondition.DEFAULT_PAGE_SIZE;
  smallnumPages = 0;
  searchPage = 1;

  columnList = [
    { seq: 1, id: 'displayName', displayName: 'title_device_group', issort: false },
    { seq: 2, id: 'groupDisplayName', displayName: 'title_group_name', issort: false }
  ];

  /**
   * チェックボックスすべてチェックされた判断
   */
  get allchecked(): boolean {
    return this.deviceGroupsResults.length !== 0 /* ignore empty array */ &&
      this.deviceGroupsResults.every(deviceGroup => deviceGroup.isChecked);
  }

  /**
   * チェックボックス一括設定
   */
  cbAll(checkVal: boolean) {
    // 各行のチェック状態を更新
    for (const row of this.deviceGroupsResults) {
      row.isChecked = checkVal;
    }
  }

  /**
   * グループ名リンク表示/非表示判定処理
   */
  private isLinkShow(groupId: number): boolean {
    // デバイスタイプ閲覧権限がない時はグループ名をリンクにしない
    return this.authRepository.hasPermission('device_type', 'view');
  }

  /**
   * 削除ボタンの活性状態
   *
   * 1つ以上チェックがあれば活性になる。
   */
  get isDeletable(): boolean {
    return this.deviceGroupsResults.some(row => row.isChecked);
  }

  /**
   * ページ遷移
   */
  pageChanged(event: any): void {
    this.searchPage = event.page;
    this.searchDeviceGroups();
  }

  /**
   * グループ一覧取得
   */
  searchDeviceGroups() {
    const pagination: ApiPagenation = {
      page: this.searchPage,
      pageSize: this.itemsPerPage,
    };
    const groupsObservable = this.apiHandler.call(false, DeviceGroupsGetApi, [], {}, {}, [], pagination);
    groupsObservable
      .subscribe(
        res => {
          const groups = res.body;
          this.count = Observable.create(
            observer => {
              observer.next(groups.count);
              observer.complete();
            });
          this.next = groups.next;
          this.previous = groups.previous;
          this.deviceGroupsResults = groups.results.map(type => {
            return {
              isChecked: false, // チェックボックスなし
              ...type
            };
          });
          if (this.deviceGroupsResults.length === 0) {
            this.isNoData = true;
          } else {
            this.isNoData = false;
          }
        },
        error => console.log(error)
      );
  }

  /**
   * グループ一覧取得（追加画面用）
   */
  searchEditGroups() {
    // グループ一覧検索して選択ボックスの候補に設定する
    if (this.authRepository.hasPermission('device_type', 'add_delete')) {
      const groupsObservable = this.apiHandler.call(false, GroupsGetApi, [], undefined, {});
      groupsObservable.subscribe(res => {
        if (!res.hasError) {
          const groups = res.body.results.map(group => {
            return {value: group.id, name: group.displayName};
          });
          groups.forEach((group, index) => {
            if (this.authRepository.hasPermission('device_type', 'add_delete')) {
              this.canEditGroups.push(group);
            }
          });
        }
      });
    } else {
      this.canEditGroups = [];
    }
  }

  /**
   * 削除ボタンonclick
   */
  clickDelete() {
    const deleteDeviceGroups = this.deviceGroupsResults
      .filter(device => device.isChecked);
    const deleteIds = deleteDeviceGroups.map(deviceGroup => {
      return { message: deviceGroup.displayName };
    });
    // 確認ダイアログ
    this.dialogService.show(this.t.instant('title_confirmation'), this.t.instant('msg_confirm_delete_device_group'), deleteIds)
      .subscribe(ok => {
        if (ok) {  // OKが押されたら削除API発行
          const apiCalls = deleteDeviceGroups
            .map(deviceGroups => this.apiHandler.call(false, DeviceGroupDeleteApi, [deviceGroups.id]));
          // すべてのAPI呼び出しをまとめたObservableを生成
          forkJoin(apiCalls)
            .subscribe(result => {
              // すべてのAPI呼び出しが（失敗も含めて）完了した後の処理
              const totalCount = result.length;
              const failedCount = result.filter(elm => elm.hasError).length;
              if (failedCount > 0) {
                this.functions.showToastMessage(this.t.instant(`msg_faild_delete_items`, {
                  totalCount: totalCount, failedCount: failedCount
                }), 'warning');
              } else {
                this.functions.showToastMessage(this.t.instant(`msg_succeeded_delete_items`, {
                  totalCount: totalCount
                }), 'success');
              }
              this.searchDeviceGroups();
            });
        }
      });
  }

  /**
   * グループ登録画面をモーダルウィンドウで開く。
   */
  openGroupsAdd() {
    const modalRef = this.modalService.show(DeviceGroupsAddComponent, {
      class: 'modal-dialog modal-lg',
      backdrop: 'static'
    });

    // DropDown
    modalRef.content.deviceGroups = this.canEditGroups;

    // モーダルを閉じたときに登録完了フラグONの場合に再取得する。
    this.modalService.onHide.subscribe(() => {
      if (modalRef.content.isOk) {
        this.searchDeviceGroups();
        modalRef.content.isOk = false;
      }
    });
  }

  /**
* 初期化処理
*/
  ngOnInit(): void {
    // デバイスグループ一覧取得
    this.searchDeviceGroups();
    // 編集できるグループ取得
    this.searchEditGroups();
  }
}

interface Checkable {
  isChecked: boolean;
}


