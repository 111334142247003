<div class="modal-header">
  <h4 class="modal-title pull-left">{{title | translate}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="panel panel-default">
    <div class="panel-body">
      <form [formGroup]="packetTypeForm">
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <input matInput id="packet_type_number" name="packet_type_number" type="number" [formControl]="packetTypeNumber"
                placeholder="{{'title_packet_type_number' | translate}}" required>
              <mat-hint align="end">{{'p_holder_0_255_int' | translate}}</mat-hint>
              <ng-container *ngIf="packetTypeNumber.touched && packetTypeNumber.errors" ngProjectAs="mat-error">
                <mat-error *ngIf="packetTypeNumber.errors.required">{{'msg_packet_type_number_required' | translate}}</mat-error>
                <mat-error *ngIf="packetTypeNumber.errors.min">{{'msg_packet_type_number_must_be_0_or_greater' | translate}}</mat-error>
                <mat-error *ngIf="packetTypeNumber.errors.max">{{'msg_packet_type_number_must_be_255_or_less' | translate}}</mat-error>
                <mat-error *ngIf="packetTypeNumber.errors.digits">{{'msg_input_integer' | translate}}</mat-error>
                <mat-error *ngIf="packetTypeNumber.errors.alreadyUsed">{{'msg_packet_type_number_duplicated' | translate}}</mat-error>
              </ng-container>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button mat-raised-button color="primary" [disabled]="packetTypeForm.invalid" (click)="save()">
    <mat-icon aria-hidden="true">save</mat-icon> {{'title_save' | translate}}
  </button>
  <button mat-raised-button type="button" (click)="bsModalRef.hide()">
    <mat-icon aria-hidden="true">clear</mat-icon>  {{'title_cancel' | translate}}
  </button>
</div>
