import { ApiDef, ApiStatusMessage } from './sip-api-client';
import { Method } from '../enums/api-enums';

/**
 * アラート定義登録APIの定義
 */
export class AlertDefAddApi extends ApiDef {
  requestPayloadInterface: {
    deviceTypeId: number;
    title: string;
    detail: string;
    type: AlertType;
    conditions?: string;
    pingInterval?: number;
    suppressInterval?: number;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/alert_defs';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
      { code: 201, message: 'toast_registered_alert_def' },
      { code: 400, message: 'toast_input_value_invalid' }
  ];
}

/**
 * アラート定義更新APIの定義
 */
export class AlertDefUpdateApi extends ApiDef {
  requestPayloadInterface: {
    title: string;
    detail: string;
    type: AlertType;
    conditions?: string;
    pingInterval?: number;
    suppressInterval?: number;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/alert_defs/{0}';
  method = Method.PUT;
  statusMsgList: ApiStatusMessage[] = [
      // TODO: APIのステータスコードが204ではなく200になっているので暫定対応。後で直す。
      { code: 200, message: 'toast_updated_alert_def' },
      { code: 204, message: 'toast_updated_alert_def' },
      { code: 400, message: 'toast_input_value_invalid' },
      { code: 404, message: 'toast_alert_def_not_found' }
  ];
}

/**
 * アラート定義削除APIの定義
 */
export class AlertDefDeleteApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/alert_defs/{0}';
  method = Method.DELETE;
  statusMsgList: ApiStatusMessage[] = [
      { code: 204, message: 'toast_deleted_alert_def' },
      { code: 404, message: 'toast_alert_def_not_found' }
  ];
}

export interface AlertDef {
  id: number;
  title: string;
  detail: string;
  type: AlertType;
  conditions: string;
  pingInterval: number;
  suppressInterval: number;
}

export type DeviceAlertDef = AlertDef & {
  deviceId: number;
};

export type AlertType = 'ping' | 'condition';
export namespace AlertType {
  const mapping = {
    'ping': 'title_ping_check',
    'condition': 'title_conditions'
  };

  export const values = (): AlertType[] => {
    return Object.keys(mapping) as AlertType[];
  };

  export const toName = (type: AlertType): string => {
    const name = mapping[type];
    if (name == null) {
      throw new Error(`Invalid communication type: ${type}.`);
    }
    return name;
  };
}

