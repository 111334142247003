<h2>{{'title_device_output_index' | translate}}</h2>
<mat-card class="search-form">
  <form [formGroup]="form">
  <mat-card-content>
    <!--行１-->
    <div class="row">
      <!--デバイスタイプ-->
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <mat-select placeholder="{{'title_device_type' | translate}}" [formControl]="deviceTypeIdForm" required>
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let type of dropdownDeviceTypes" [value]="type.value">{{type.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="deviceTypeIdForm.touched && deviceTypeIdForm.errors?.required">{{'msg_device_type_required' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <!--デバイス-->
      <div class="col-sm-6">
        <mat-form-field class="full-width-field">
          <mat-select placeholder="{{'title_device' | translate}}" [formControl]="deviceIdForm" multiple>
            <mat-option disabled="disabled" class="select-action">
              <button mat-button (click)="deviceIdForm.setValue('')">
                <mat-icon aria-hidden="true">clear</mat-icon>
                {{'title_clear_all_selections' | translate}}
              </button>
            </mat-option>
            <mat-divider></mat-divider>
            <mat-option *ngFor="let device of dropdownDevices" [value]="device.value">{{device.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!--行２-->
    <!--行２-->
    <div class="row">
      <div class="datetimepickers">
        <!--日時開始(yyyymmdd)-->
        <div class="col-md-3 col-xs-6">
          <mat-form-field>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDatepicker" [formControl]="startForm"  placeholder="{{'title_start_datetime' | translate}}" autocomplete="off" (dateChange)="updateDate($event.value, 'start')" required>
            <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatepicker></mat-datepicker>
            <mat-error *ngIf="startForm.touched && startForm.errors?.required">{{'msg_start_required' | translate}}</mat-error>
            <mat-error *ngIf="startForm.touched && startForm.errors?.matDatepickerMin">{{'msg_date_range' | translate}}</mat-error>
            <mat-error *ngIf="startForm.touched && startForm.errors?.matDatepickerMax">{{'msg_date_range' | translate}}</mat-error>
          </mat-form-field>
        </div>
        <!--日時開始(hhmmss)-->
        <div class="col-md-3 col-xs-6 timepickerdiv">
            <timepicker [formControl]="startTimeForm" [showSeconds]="true" [showMeridian]="false"></timepicker>
            <mat-error class="msg-size-small" *ngIf="startTimeForm.errors?.required">{{'msg_start_time_required' | translate}}</mat-error>
        </div>
        <!--日時終了(yyyymmdd)-->
        <div class="col-md-3 col-xs-6">
          <mat-form-field>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="endDatepicker" [formControl]="endForm" placeholder="{{'title_end_datetime' | translate}}" autocomplete="off" (dateChange)="updateDate($event.value, 'end')" required>
            <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatepicker></mat-datepicker>
            <mat-error *ngIf="endForm.touched && endForm.errors?.required">{{'msg_end_required' | translate}}</mat-error>
            <mat-error *ngIf="endForm.touched && endForm.errors?.matDatepickerMin">{{'msg_date_range' | translate}}</mat-error>
            <mat-error *ngIf="endForm.touched && endForm.errors?.matDatepickerMax">{{'msg_date_range' | translate}}</mat-error>
          </mat-form-field>
        </div>
        <!--日時終了(hhmmss)-->
        <div class="col-md-3 col-xs-6 timepickerdiv">
          <timepicker [formControl]="endTimeForm" [showSeconds]="true" [showMeridian]="false"></timepicker>
          <mat-error class="msg-size-small" *ngIf="endTimeForm.errors?.required">{{'msg_end_time_required' | translate}}</mat-error>
        </div>
      </div>
    </div>
    <!--行３-->
    <div class="row" style="margin-top: 20px;">
      <!--ボタン-->
      <div class="col-sm-12 text-right">
        <button mat-raised-button color="primary" (click)="downloadCsv()" [disabled]="form.invalid">
          <mat-icon aria-hidden="true">cloud_download</mat-icon> {{'title_device_output_index' | translate}}
        </button>
        <button type="button" mat-icon-button matTooltip="{{'title_clear_conditions' | translate}}" (click)="clear()">
          <mat-icon aria-hidden="true">clear</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
  </form>
</mat-card>




