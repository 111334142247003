<div class="row">
  <div class="col-md-6 col-md-offset-3">
    <mat-card>
      <div class="row">
        <div class="col-sm-12">
          <h5>{{'title_login' | translate}}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <form class="form-horizontal">
            <mat-form-field class="full-width-field">
              <input matInput id="email" name="email" type="text" [(ngModel)]="email" placeholder="{{'title_email' | translate}}" required autofocus>
            </mat-form-field>
            <mat-form-field class="full-width-field">
              <input matInput id="password" name="password" type="password" [(ngModel)]="password" placeholder="{{'title_password' | translate}}" required autofocus>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-right">
          <button mat-raised-button color="accent" type="button" (click)="login()" [disabled]="checkInputValue()">
            <mat-icon aria-hidden="true">input</mat-icon> {{'title_login' | translate}}
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
