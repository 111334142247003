import { Method } from '../../common/enums/api-enums';
import { ApiDef, ApiStatusMessage } from './sip-api-client';
import { Injectable } from "@angular/core";

/**
 * APIキー一覧取得APIの定義
 */

@Injectable()
export class GroupApiKeysGetApi extends ApiDef {
  requestPayloadInterface: {};
  queryInterface: {
    groupId?: string[];
  };
  orderableInterface: {};
  responseBodyInterface: {
    count: number;
    next: string;
    previous: string;
    results: [
      {
        id: number;
        displayName: string;
        groupId: number;
        groupDisplayName: string;
        apiKey: string;
        expirationDate: Date;
        created: Date;
      }
    ]
  };
  uri = '/api_keys/';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_completed_search' },
    { code: 400, message: 'toast_search_cond_invalid' }
  ];
}

/**
 * グループAPIキー削除API定義
 */
export class GroupApiKeysDeleteApi extends ApiDef {
  requestPayloadInterface: {};
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: {};
  uri = '/api_keys/{0}';
  method = Method.DELETE;
  statusMsgList: ApiStatusMessage[] = [
    { code: 204, message: 'toast_deleted_api_key' },
    { code: 404, message: 'toast_api_key_not_found' }
  ];
}

/**
 * グループAPIキー登録APIの定義
 */
@Injectable()
export class GroupApiKeysAddApi extends ApiDef {
  requestPayloadInterface: {
    displayName?: string;
    groupId?: number;
    expirationDate?: Date;
  };
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: {};
  uri = '/api_keys';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
    { code: 201, message: 'toast_registered_api_key' },
    { code: 400, message: 'toast_input_value_invalid' }
  ];
}

/**
 * グループAPIキー更新APIの定義
 */
export class GroupApiKeysUpdatetApi extends ApiDef {
  requestPayloadInterface: {
    displayName: string;
    expirationDate?: Date;
  };
  queryInterface: {};
  orderableInterface: {};
  responseBodyInterface: {};
  uri = '/api_keys/{0}';
  method = Method.PUT;
  // APIバグのため正常終了したときにCode:200を返す
  // 204を返すようになったら200の方を消す
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_updated_api_key' },
    { code: 204, message: 'toast_updated_api_key' },
    { code: 400, message: 'toast_input_value_invalid' },
    { code: 404, message: 'toast_api_key_not_found' }
  ];
}
