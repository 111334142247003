import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiHandler } from '../../services/api-handler.service';
import { DeviceTypeListApi } from '../../common/api/device-type';
import { DeviceAddApi } from '../../common/api/device';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ResourceNameDirective } from '../../common/validation/resource-name.directive';
import {DeviceGroupsGetApi} from '../../common/api/device-group';
@Component({
  moduleId: module.id,
  templateUrl: 'device-add.component.html'
})
export class DeviceAddComponent implements OnInit {
  // DropDown
  dropdownDeviceTypes: Array<{ value: any, name: string }> = [];
  dropdownGroups: Array<{ value: any, name: string }> = [];
  dropdownDeviceGroups: Array<{ value: any, name: string }> = [];
  isSuperuser: boolean;

  // 入力フォーム
  selectedDeviceType: FormControl;
  selectedGroup: FormControl;
  selectedDeviceGroup: FormControl;
  deviceId: FormControl;
  displayName: FormControl;
  storeTimes: FormControl;
  form: FormGroup;

  isOk = false;

  constructor(public t: TranslateService, public bsModalRef: BsModalRef, private builder: FormBuilder, private apiHandler: ApiHandler) { }

  /**
   * 選択したグループに紐づくデバイスタイプを取得する。
   *
   * @param groupId グループのユニークID
   */
  searchDeviceTypes(groupId: number) {
    // デバイスタイプ一覧検索
    const deviceTypesObservable = this.apiHandler.call(false, DeviceTypeListApi, [], undefined, { groupId: [groupId] });
    deviceTypesObservable.subscribe(res => {
      if (!res.hasError) {
        const devicetypes = res.body;
        this.dropdownDeviceTypes = devicetypes.results.map(deviceType => {
          return { value: deviceType.id, name: deviceType.displayName };
        });
      }
    });
  }

  /**
   * デバイスグループ一覧取得
   */
  searchDeviceGroups(groupId: number) {
    // デバイスグループ一覧検索して選択ボックスの候補に設定する
    const groupsObservable = this.apiHandler.call(false, DeviceGroupsGetApi, [], undefined, { groupId: [groupId] });
    groupsObservable.subscribe(res => {
      if (!res.hasError) {
        this.dropdownDeviceGroups = res.body.results.map(group => {
          return { value: group.id, name: group.displayName };
        });
      }
    });
  }

  /**
   * デバイス登録
   */
  deviceAdd() {
    const device = this.form.value;
    const deviceAddObservable = this.apiHandler.call(true, DeviceAddApi, [], device);
    deviceAddObservable.subscribe(res => {
      if (!res.hasError) {
        // 登録成功の場合は登録完了フラグをONにして画面を閉じる
        this.isOk = true;
        this.bsModalRef.hide();
      }
    });
  }

  /**
   * 所属グループの値に応じて、デバイスタイプの選択肢を変更する
   */
  onGroupChange() {
    // デバイスタイプのDropDown設定
    this.selectedDeviceType.setValue(null);
    this.selectedDeviceGroup.setValue(null);
    if (this.selectedGroup.value != null) {
      this.searchDeviceTypes(this.selectedGroup.value);
      this.searchDeviceGroups(this.selectedGroup.value);
      this.selectedDeviceGroup.enable({onlySelf: true});
      this.selectedDeviceType.enable({onlySelf: true});
    } else {
      this.selectedDeviceType.disable({onlySelf: true});
      this.selectedDeviceGroup.disable({onlySelf: true});
    }
  }

  ngOnInit() {
    this.selectedGroup = new FormControl(null, Validators.required);
    this.selectedDeviceGroup = new FormControl(null, Validators.required);
    this.selectedDeviceType = new FormControl(null, Validators.required);
    this.deviceId = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50),
      new ResourceNameDirective().validate
    ]);
    this.displayName = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50)
    ]);
    this.form = this.builder.group({
      /* groupId はデバイス登録には必要ないのでフォームに含めない */
      deviceTypeId: this.selectedDeviceType,
      deviceId: this.deviceId,
      displayName: this.displayName,
      deviceGroupId: this.selectedDeviceGroup
    });

    // Superuserユーザの場合、初期化でデバイスグループとデバイスタイプドロップダウンリストが非活性
    if (this.isSuperuser) {
      this.selectedDeviceGroup.disable({onlySelf: true});
      this.selectedDeviceType.disable({onlySelf: true});
    } else {
      // Superuserユーザ以外の場合、初期化でグループドロップダウンリストが非活性、値を設定
      if (this.dropdownGroups != null && this.dropdownGroups.length > 0) {
        this.selectedGroup.setValue(this.dropdownGroups[0].value);
        if (this.dropdownGroups.length > 1) {
          this.onGroupChange();
        }
      }
      this.selectedGroup.disable({onlySelf: true});
    }
  }
}
