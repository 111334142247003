<div class="form-group">
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-default">
        <div class="panel-body">
            <button mat-raised-button color="primary" (click)="openPage()" [disabled]="!canEdit">
              <mat-icon aria-hidden="true">settings_remote</mat-icon> {{'title_device_remote' | translate}}
            </button>
        </div>
      </div>
    </div>
  </div>
</div>
