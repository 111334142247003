<div class="modal-header">
  <h4 class="modal-title pull-left">{{'msg_pick_location' | translate : param}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row" *ngIf="property">
    <div class="col-sm-12">
      <app-map [id]="property.id" [latlng]="latlng" [zoom]="zoom" [editable]="true"></app-map>
      <input id="selectedlatlng" type="hidden" [value]="latlng">
    </div>
  </div>
</div>
<div class="modal-footer">
  <button mat-raised-button color="primary" (click)="onSet()">
    <mat-icon aria-hidden="true">check</mat-icon> {{'title_ok' | translate}}
  </button>
  <button mat-raised-button (click)="bsModalRef.hide()">
    <mat-icon aria-hidden="true">clear</mat-icon> {{'title_cancel' | translate}}
  </button>
</div>
