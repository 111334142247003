<div class="row">
  <div class="col-sm-10">
    <h2>{{groupInfo.displayName}}</h2>
  </div>
  <div class="col-sm-2 text-right">
    <a mat-raised-button role="button" [routerLink]="['/groups']" routerLinkActive="active">
      <mat-icon aria-hidden="true">arrow_upward</mat-icon>{{'title_back_to_list' | translate}}
    </a>
  </div>
</div>
<nav mat-tab-nav-bar>
  <a mat-tab-link [routerLink]="['./']" routerLinkActive #ria1="routerLinkActive" [active]="ria1.isActive" [routerLinkActiveOptions]="{ exact: true }">{{'title_detail' | translate}}</a>
  <a mat-tab-link [routerLink]="['api_keys']" routerLinkActive #ria2="" [routerLinkActiveOptions]="{}">{{'title_api_keys' | translate}}</a>
</nav>
<h4>{{'title_basic_props' | translate}}</h4>
<div class="row">
  <div class="col-sm-4">
    <mat-card style="padding:0px;">
      <mat-card-header style="background-color:#eeeeee;">
        <mat-card-title style="margin:10px 8px;">{{'title_group_name' | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin:10px 15px; height:auto; min-height:40px;">
        <app-editable-text #displayName [inputControl]="displayNameControl" id="display_name" name="display_name" type="text" required
          maxlength="50" [disabled]="!canEdit" (saved)="updateDisplayName($event)"></app-editable-text>
        <div class="text-danger" *ngIf="displayNameControl.touched && displayNameControl.errors">
          <div [hidden]="!displayNameControl.errors.required">{{'msg_group_name_required' | translate}}</div>
          <div [hidden]="!displayNameControl.errors.maxlength">{{'mag_group_name_must_be_50_letters_or_less' | translate}}</div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
   <div class="col-sm-4">
    <mat-card style="padding:0px;">
      <mat-card-header style="background-color:#eeeeee;">
        <mat-card-title style="margin:10px 8px;">{{'title_ssh_active_flg' | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin:10px 15px; height:auto; min-height:40px;">
        <mat-slide-toggle (change)="updateSshActive($event)"
          [formControl]="sshActiveControl"> {{(sshActiveControl.value ? 'title_ssh_active' : 'title_ssh_unactive') | translate}}</mat-slide-toggle>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-sm-4">
    <mat-card style="padding:0px;">
      <mat-card-header style="background-color:#eeeeee;">
        <mat-card-title style="margin:10px 8px;">{{'title_root_of_group' | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin:10px 15px; height:auto; min-height:40px;">
        <label style="font-weight:100; word-wrap:break-word;">{{groupInfo.rootDisplayName}} </label>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<h4>{{'title_members' | translate}}</h4>
<div class="row">
  <div class="col-sm-12">
    <mat-card>
      <mat-card-content>
        <table class="table table-bordered table-hover tableBreakAll">
          <colgroup>
            <col style="width:30%">
            <col style="width:60%">
            <col style="width:10%">
          </colgroup>
          <thead>
            <tr>
              <th *ngFor="let columns of columnList">
                <label class="labelth" *ngIf="!columns.issort" id={{columns.id}}>
                  {{columns.displayName | translate}}
                </label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let member of memberResult" class="active">
              <td [attr.data-label]="columnList[0].displayName">{{member.userDisplayName}}</td>
              <td [attr.data-label]="columnList[1].displayName">{{memberRoleListString(member) | translate}}</td>
              <td [attr.data-label]="columnList[2].displayName" style="text-align:center;">
                <div *ngIf="canVisible(member.userId); then updateDelete;"></div>
                <ng-template #updateDelete>
                  <button mat-icon-button class="icon-btn" matTooltip="{{'title_edit' | translate}}" (click)="clickUpdateRole($event,member.userDisplayName,member.userId,member.roles)"
                    [disabled]="!canAddDelete">
                    <mat-icon aria-hidden="true">edit</mat-icon>
                  </button>
                  <button mat-icon-button class="icon-btn" matTooltip="{{'title_unassign' | translate}}" (click)="clickDelete($event,member.userDisplayName,member.userId,member)"
                    [disabled]="!canAddDelete">
                    <mat-icon aria-hidden="true">delete</mat-icon>
                  </button>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="isNoData" class="col-sm-12">
          <app-note role="info">
            {{'msg_no_data_to_display' | translate}}
          </app-note>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button btn mat-raised-button color="primary" (click)="openGroupMemberAdd()" [disabled]="!canAddDelete">
              <mat-icon aria-hidden="true">open_in_new</mat-icon> {{'title_add' | translate}}
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
