<div class="row">
  <div class="col-sm-10">
    <h4>{{'title_alert_edit' | translate}}</h4>
  </div>
  <div [hidden]="isAdding" class="col-sm-2 text-right">
    <button mat-raised-button color="primary" [disabled]="!canEdit" (click)="isAdding=true">
      <mat-icon aria-hidden="true">add</mat-icon>{{'title_add' | translate}}
    </button>
  </div>
  <div [hidden]="!isAdding" class="col-sm-2 text-right">
    <button mat-raised-button [disabled]="!canEdit" (click)="isAdding=false">
      <mat-icon aria-hidden="true">clear</mat-icon>{{'title_cancel' | translate}}
    </button>
  </div>
  <div *ngIf="deviceType.alertDefs.length === 0" class="col-sm-12">
    <app-note role="info">{{'msg_no_alerts' | translate}}</app-note>
  </div>
</div>
<app-alert-def-edit *ngIf="isAdding" [deviceTypeId]="deviceType.id" [propertyDefs]="deviceType.propertyDefs" [disabled]="!canEdit"></app-alert-def-edit>
<ng-container *ngFor="let alertDef of deviceType.alertDefs">
  <app-alert-def-edit [alertDef]="alertDef" [deviceTypeId]="deviceType.id" [propertyDefs]="deviceType.propertyDefs" [disabled]="!canEdit"></app-alert-def-edit>
</ng-container>
