import { Component, OnInit } from '@angular/core';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Functions } from '../../common/functions';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { GroupApiKeysAddApi, GroupApiKeysUpdatetApi } from '../../common/api/group-api-keys';
import { ApiHandler } from '../../services/api-handler.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  moduleId: module.id,
  templateUrl: 'group-api-key-add.component.html',
  providers: [GroupApiKeysAddApi]
})

export class GroupApiKeyAddComponent implements OnInit {
  title = '';
  isAddData: boolean;
  isOk = false;

  // 入力フォーム
  displayName: FormControl;
  groupId: number;
  groupApiKeyForm: FormGroup;

  // DatePicker,TimePicker設定
  bsConfig: Partial<BsDatepickerConfig>;
  calendar: FormControl;
  dateInfo: Date = new Date;
  expirationDate: Date = new Date();
  mousewheel: boolean;
  showSec: boolean;
  showMer: boolean;
  maxTime: Date;
  minTime: Date;

  public initGroupApiKeyModal(isAddData: boolean, title: string, id: number, displayName: string, expirationDate: Date): void {
    this.title = title;
    this.isAddData = isAddData;
    this.displayName.setValue(displayName);
    this.groupId = id;

    // 秒表示
    this.showSec = true;
    // 24時間表記ON
    this.showMer = false;
    // マウスホイール使用許可
    this.mousewheel = true;

    if (isAddData) {
      // 追加
      // 初期値設定
      this.expirationDate.setHours(23);
      this.expirationDate.setMinutes(59);
      this.expirationDate.setSeconds(59);

    } else {
      // 編集
      // 編集画面で有効期限を変更しないなら、bugがあるので、下記内容を修正する
      // this.calendar.setValue(expirationDate);
      this.calendar.setValue(new Date(expirationDate));

      // ローケルにあった時間に直す
      const localDate = new Date(expirationDate);
      this.expirationDate.setHours(localDate.getHours());
      this.expirationDate.setMinutes(localDate.getMinutes());
      this.expirationDate.setSeconds(localDate.getSeconds());
    }
  }


  constructor(
    public t: TranslateService,
    public adapter: DateAdapter<NativeDateAdapter>,
    public bsModalRef: BsModalRef,
    public apiHandler: ApiHandler,
    public groupApiKeysAddApi: GroupApiKeysAddApi,
    private functions: Functions,
    private builder: FormBuilder,
  ) { }

  /**
   * グループＡＰＩキー登録/編集
   */
  groupApiKeyEddit() {
    // 形式変換
    this.dateInfo = this.calendar.value;
    this.expirationDate = this.functions.concatDateAndTime(this.dateInfo, this.expirationDate);
    if (this.isAddData) {
      // 追加用
      const entityAdd = this.toGroupApiKeysEntityAdd();
      // APIキー登録
      this.apiHandler.call(true, GroupApiKeysAddApi, [], entityAdd, {}, [], {})
        .subscribe(
          res => {
            this.isOk = true;
            this.bsModalRef.hide();
          });
    } else {
      // 編集用
      const entityEddit = this.toGroupApiKeysEntityEddit();
      // 編集情報保存
      this.apiHandler.call(true, GroupApiKeysUpdatetApi, [this.groupId], entityEddit, {}, [], {})
        .subscribe(
          resEddit => {
            this.isOk = true;
            this.bsModalRef.hide();
          });
    }
  }

  // 入力フォームの入力をグループのエンティティに変換する
  private toGroupApiKeysEntityAdd(): GroupApiKeysAddApi['requestPayloadInterface'] {
    return {
      displayName: this.displayName.value,
      groupId: this.groupId,
      expirationDate: this.functions.concatDateAndTime(this.dateInfo, this.expirationDate),
    };
  }


  private toGroupApiKeysEntityEddit(): GroupApiKeysUpdatetApi['requestPayloadInterface'] {
    return {
      displayName: this.displayName.value,
      expirationDate: this.functions.concatDateAndTime(this.dateInfo, this.expirationDate),
    };
  }

  ngOnInit() {
    this.displayName = new FormControl('', [
      Validators.required,
      Validators.maxLength(50)
    ]);

    this.calendar = new FormControl('', [
      Validators.required,
    ]);

    this.groupApiKeyForm = new FormGroup({
      displayName: new FormControl,
      calendar: new FormControl,
    });

    this.groupApiKeyForm = this.builder.group({
      displayName: this.displayName,
      FormControl: this.calendar,
    });
    // カレンダーのロケールを設定する
    this.adapter.setLocale(this.t.currentLang);
  }
}

