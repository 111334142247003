import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SortList } from '../../models/sortlist.model';
import { Groups } from '../../models/groups.model';
import { GroupsGetApi, GroupInfoGetApi } from '../../common/api/group';
import { GroupApiKeyAddComponent } from '../modal/group-api-key-add.component';
import { GroupApiKeysDeleteApi, GroupApiKeysAddApi } from '../../common/api/group-api-keys';
import { GroupApiKeysGetApi } from '../../common/api/group-api-keys';
import { ApiHandler } from '../../services/api-handler.service';
import { ApiPagenation } from '../../common/api/sip-api-client';
import { WebStorageAuthRepository } from '../../services/auth-repository.service';
import { ConfirmComponent } from '../../common/messages/confirm.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './group-api-key.component.html',
  styleUrls: ['./group-api-key.component.css'],
  providers: [GroupsGetApi, GroupApiKeysGetApi, GroupApiKeysAddApi, GroupApiKeyAddComponent, BsModalRef],
})

export class GroupApiKeyComponent implements OnInit {
  groups: Groups;
  sortList: SortList;
  group_id: string;
  api_keys: string;
  message = '';
  keyGroupId: string[] = [];
  isNoData: boolean = false;

  groupInfo: GroupInfoGetApi['responseBodyInterface'];

  count: Observable<number>; // 全件数
  next: string;              // 次ページURL
  previous: string;          // 前ページURL
  itemsPerPage = 0;
  currentPage = 1;
  smallnumPages = 0;
  searchPage = 1;

  columnList = [
    { seq: 1, id: 'displayName', displayName: 'title_api_key_name', issort: false },
    { seq: 2, id: 'apiKey', displayName: 'title_api_key', issort: false },
    { seq: 3, id: 'expirationDate', displayName: 'title_expiration_date', issort: false },
    { seq: 4, id: 'edditDelete', displayName: '', issort: false }
  ];

  constructor(
    public t: TranslateService,
    public groupsGetApi: GroupsGetApi,
    public groupApiKeysGetApi: GroupApiKeysGetApi,
    public apiHandler: ApiHandler,
    public authRepo: WebStorageAuthRepository,
    private confirmMessage: BsModalService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
  ) { }

  // interfaceModel
  groupapikeyresults: Array<{
    id: number; // APIキーID
    displayName: string;  // APIキー名
    groupId: number;  // グループID
    groupDisplayName: string;  // グループ名
    apiKey: string;  // APIキー
    expirationDate: Date;  // 有効期限
    created: Date;  // 作成日時
  } & Checkable> = [];

  /**
   * ページ遷移
   */
  pageChanged(event: any): void {
    this.searchPage = event.page;
    this.searchApiKeys();
  }

  /**
   * APIキー登録用モーダルウィンドウをオープン
  */
  openGroupApiKeyAdd() {
    // 新規登録フラグＯＮ
    const modalRef = this.modalService.show(GroupApiKeyAddComponent, { class: 'modal-dialog modal-lg', backdrop: 'static' });
    modalRef.content.initGroupApiKeyModal(true, 'title_api_key_registration', this.groupInfo.id, null, null);

    // モーダルを閉じたときに登録完了フラグONの場合に再取得する。
    this.modalService.onHide.subscribe(() => {
      if (modalRef.content.isOk) {
        this.searchApiKeys();
        modalRef.content.isOk = false;
      }
    });
  }
  /**
   * 追加ボタン、削除ボタン活性状態
  */
  get canAddDelete(): boolean {
    return this.authRepo.hasPermission('api_key', 'add_delete');
  }

  /**
   * 編集ボタン活性状態
   */
  get canUpdate(): boolean {
    return this.authRepo.hasPermission('api_key', 'edit');
  }

  /**
   * APIキー削除
   */
  clickDelete(event: any, id: number, displayName: string, apiKey: string) {
    // 確認ダイアログ
    const addmessages: Array<object> = [];
    addmessages.push({ message: this.t.instant(this.columnList[0].displayName) + '：' + displayName });
    addmessages.push({ message: this.t.instant(this.columnList[1].displayName)  + '：' + apiKey });
    const modalRef = this.confirmMessage.show(ConfirmComponent, { backdrop: 'static' });
    modalRef.content.title = this.t.instant('title_confirmation');
    modalRef.content.messageHead = this.t.instant('msg_confirm_delete_api_key');
    modalRef.content.messageDetail = addmessages;

    this.confirmMessage.onHide
      .subscribe(() => {
        if (modalRef.content.isOk) {
          const apiKeysObservable = this.apiHandler.call(true, GroupApiKeysDeleteApi, [id], [], {}, [], {});
          apiKeysObservable
            .subscribe(res => {
              this.searchApiKeys();
            },
              error => {
                console.log(error);
              });
          modalRef.content.isOk = false;
        }
      });
    return true;
  }

  /**
   * APIキー編集
   */
  clickEddit(event: any, id: number, displayName: string, expirationDate: Date) {
    // 新規登録フラグＦＦ
    const modalRef = this.modalService.show(GroupApiKeyAddComponent, { class: 'modal-dialog modal-lg', backdrop: 'static' });
    modalRef.content.initGroupApiKeyModal(false, 'title_api_key_edit', id, displayName, expirationDate);

    // モーダルを閉じたときに登録完了フラグONの場合に再取得する。
    this.modalService.onHide.subscribe(() => {
      if (modalRef.content.isOk) {
        this.searchApiKeys();
        modalRef.content.isOk = false;
      }
    });
  }

  ngOnInit() {
    // リゾルバー経由で取得したデータを反映
    this.route.data.subscribe(obj => {
      this.groupInfo = obj['groups'];
    });
    this.searchApiKeys();
  }

  private toGroupApiKeysGetApi(): GroupApiKeysGetApi['queryInterface'] {
    return {
      groupId: this.keyGroupId
    };
  }

  /**
   * APIキー一覧情報取得
   */
  searchApiKeys() {
    const pagination: ApiPagenation = {
      page: this.searchPage,
      pageSize: 1000,
    };

    this.keyGroupId.push(this.groupInfo.id.toString());

    // const payload = {};
    const apikeysObservable = this.apiHandler.call(false, GroupApiKeysGetApi, [], {}, this.toGroupApiKeysGetApi(), [], pagination);
    apikeysObservable
      .subscribe(
        res => {
          const apikeys = res.body;
          this.count = Observable.create(
            observer => {
              observer.next(apikeys.count);
              observer.complete();
            });
          this.next = apikeys.next;
          this.previous = apikeys.previous;
          this.groupapikeyresults = apikeys.results.map(type => {
            return {
              isChecked: false, // チェックボックスなし
              ...type
            };
          });

          if (this.groupapikeyresults.length === 0) {
            this.isNoData = true;
          } else {
            this.isNoData = false;
          }

          // 1page目の件数をセット
          if (apikeys.previous) {
            this.itemsPerPage = apikeys.results.length;
          }
        },
        error => console.log(error)
      );
  }
}

interface Checkable {
  isChecked: boolean;
}
