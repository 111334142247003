import { Params } from "@angular/router";
import { Injectable } from "@angular/core";

/**
 * 検索条件の永続化を担当するクラス。
 */
@Injectable()
export class QueryStoreService {
  static readonly KEY_PREFIX = 'saved_query/';

  /**
   * 検索条件をキーバリューペアで取得する。
   *
   * @param path 検索画面を区別するパス
   * @returns 検索条件。対象の検索条件が保存されていない場合は空のキーバリューペアを返す。
   */
  getQuery(path: QueryPath): Params {
    const item = sessionStorage.getItem(this.key(path));
    if (item == null) { return {}; }
    return JSON.parse(item);
  }

  /**
   * 検索条件を保存する。
   *
   * JSON文字列化できないオブジェクトの場合は保存に失敗する。
   *
   * @param path 検索画面を区別するパス
   * @param params 検索条件のキーバリューペア
   */
  storeQuery(path: QueryPath, params: Params): void {
    const item = JSON.stringify(params || {});
    sessionStorage.setItem(this.key(path), item);
  }

  /**
   * 検索条件を削除する。
   *
   * @param path 検索画面を区別するパス
   */
  removeQuery(path: QueryPath) {
    sessionStorage.removeItem(this.key(path));
  }

  private key(path: QueryPath) {
    return `${QueryStoreService.KEY_PREFIX}${path}`;
  }
}

type QueryPath = 'devices' | 'device_types' | 'alert_logs' | 'alert_output' | 'device_output';
