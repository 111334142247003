import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Functions } from '../common/functions';
import { ApiHandler } from '../services/api-handler.service';
import { WebStorageAuthRepository } from '../services/auth-repository.service';
import { FormControl } from '@angular/forms';
import { EditableTextComponent } from '../parts/editable-text.component';
import {DeviceGroupInfoGetApi, Device, DeviceGroupUpdateApi} from "../common/api/device-group";

@Component({
  moduleId: module.id,
  templateUrl: 'device-groupdetail.component.html',
  providers: [DeviceGroupUpdateApi, BsModalRef]
})

export class DeviceGroupDetailComponent implements OnInit {

  isNoData: boolean = false;
  deviceGroupInfo: DeviceGroupInfoGetApi['responseBodyInterface'];
  deviceResult: Device[] = [];

  columnList = [
    { seq: 1, id: 'displayName', displayName: 'title_device_name', issort: false },
    { seq: 2, id: 'deviceId', displayName: 'title_device_id', issort: false },
  ];

  readonly displayNameErrors = [{
    key: 'required',
    message: 'msg_device_group_name_required'
  }, {
    key: 'maxlength',
    message: 'mag_device_group_name_must_be_50_letters_or_less'
  }];

  @ViewChild('displayName', {static: false}) private displayName: EditableTextComponent;
  displayNameControl: FormControl;

  constructor(
    public t: TranslateService,
    public functions: Functions,
    private route: ActivatedRoute,
    private router: Router,
    private apiHandler: ApiHandler,
    public authRepo: WebStorageAuthRepository,
  ) { }

  updateDisplayName(event: any) {
    this.update({displayName: event});
  }

  /** デバイスグループ名更新 */
  private update(request: { displayName: any; }) {
    this.apiHandler.call(true, DeviceGroupUpdateApi, [this.deviceGroupInfo.id], request)
      .subscribe(res => {
        if (!res.hasError) {
            this.deviceGroupInfo.displayName = request.displayName;
            this.displayName.finish();
        }
      });
  }

  /**
   * デバイスグループ名編集ボタン活性化状態
   */
  get canEdit(): boolean {
    return this.authRepo.hasPermission('device_type', 'edit');
  }

  ngOnInit(): void {
    // リゾルバー経由で取得したデータ
    this.route.data.subscribe(obj => {
      this.deviceGroupInfo = obj['groups'];
      this.displayNameControl = new FormControl(this.deviceGroupInfo.displayName);
      this.deviceResult = this.deviceGroupInfo.devices;
      if (this.deviceResult.length === 0) {
        this.isNoData = true;
      } else {
        this.isNoData = false;
      }
    });
  }
}

