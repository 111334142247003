import { Component, OnInit, Input, HostListener } from '@angular/core';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { MeasurementListApi, Property } from '../../common/api/device';
import { ApiHandler } from '../../services/api-handler.service';

@Component({
  selector: 'app-time-series-chart',
  templateUrl: './time-series-chart.component.html'
})
export class TimeSeriesChartComponent implements OnInit {
  /** グラフの通し番号（他のグラフ部品と区別するため） */
  @Input() id: string;
  /** 対象となるデバイスのユニークID */
  @Input() deviceid: number;
  /** このグラフで表示する対象となる稼働情報プロパティ */
  @Input() property: Property;

  // Chart設定
  option = {
    elements: {
      point: {
        radius: 2
      },
      line: {
        tension: 0.1
      }
    },
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: tooltipItem => tooltipItem.yLabel
      }
    },
    scales: {
      xAxes: [{
        type: 'time',
        distribution: 'linear',
        time: {
          displayFormats: {
            minute: 'M/D H:mm',
            hour: 'M/D H:mm',
            day: '\'YY/M/D H:mm',
            week: '\'YY/M/D H:mm',
            quoter: '\'YY/M/D',
            year: '\'YY/M/D',
          },
          tooltipFormat: '\'YY/M/D H:mm:ss.SS',
          round: true
        },
        ticks: {
          source: 'auto'
        }
      }]
    }
  };

  isChartVisible = false;

  /** グラフで表示する時系列データ */
  lineColor = [{
    borderColor: '#828dca',
    fill: false
  }];
  seriesData: { data: { x: Date, y: number }[]; label: string }[];
  lineChartType = 'line';

  message = '';

  /** 計測データ検索範囲の先頭日時 */
  startDatetime: Date;
  /** 計測データ検索範囲の末尾日時 */
  endDatetime: Date;
  showSec: boolean;

  constructor(
    public t: TranslateService,
    public adapter: DateAdapter<NativeDateAdapter>,
    private apiHandler: ApiHandler
  ) { }

  /**
   * 計測データ検索範囲の末尾を現在時刻に設定する。
   */
  setNow() {
    this.endDatetime = new Date();
  }

  /**
   * デバイス計測データを取得し、グラフを再描画する。
   */
  loadChart() {
    const order = [{
      datetime: !!this.startDatetime
    }];
    const page = {
      pageSize: 500
    };
    // 計測データ検索
    this.apiHandler.call(false, MeasurementListApi, [this.deviceid], undefined, this.query(), order, page)
      .subscribe(res => {
        if (res.hasError) {
          this.isChartVisible = false;
          this.message = 'msg_faild_to_get_data';
        } else {
          const measurements = res.body;
          this.seriesData = [{ data: [], label: '' }];
          this.seriesData[0].label = this.property.displayName;
          this.seriesData[0].data = measurements.results.data
            .filter(item => item.value[0] !== null)
            .map(item => {
              return { x: new Date(item.datetime), y: +item.value[0].val };
            });
          if (this.seriesData[0].data.length > 0) {
            this.isChartVisible = true;
          } else {
            this.isChartVisible = false;
            this.message = 'msg_no_data_to_display';
          }
        }
      });
  }

  /**
   * 検索クエリ
   */
  private query(): MeasurementListApi['queryInterface'] {
    const query: MeasurementListApi['queryInterface'] = {
      fields: [this.property.resourceName]
    };
    if (this.startDatetime) { query.start = this.startDatetime; }
    if (this.endDatetime) { query.end = this.endDatetime; }
    return query;
  }

  ngOnInit() {
    // DatePicker設定
    this.showSec = true;
    // end
    this.endDatetime = new Date();
    // start
    this.startDatetime = undefined;
    this.loadChart();
    // カレンダーのロケールを設定する
    this.adapter.setLocale(this.t.currentLang);
    this.t.onLangChange.subscribe((e: LangChangeEvent) => {
      this.adapter.setLocale(e.lang);
    });
  }

  /**
   * 日時の年月日を設定する。
   *
   * @param value Datepickerで更新後の日付。
   * @param target 更新する対象となる日付プロパティ。'start', 'end'のいずれか。
   */
  updateDate(value: Date, target: 'start' | 'end') {
    if (value == null) {
      // 入力値が不正な場合とかは更新せずスルー
      return;
    }
    const newDate = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0, 0);
    if (target === 'start') {
      if (this.startDatetime) {
        // Dateインスタンス設定済みなら年月日だけ更新
        this.startDatetime.setFullYear(value.getFullYear());
        this.startDatetime.setMonth(value.getMonth());
        this.startDatetime.setDate(value.getDate());
      } else {
        // 未設定なら年月日を引き継いでDateインスタンスを生成する
        this.startDatetime = newDate;
      }
    } else if (target === 'end') {
      if (this.endDatetime) {
        this.endDatetime.setFullYear(value.getFullYear());
        this.endDatetime.setMonth(value.getMonth());
        this.endDatetime.setDate(value.getDate());
      } else {
        this.endDatetime = newDate;
      }
    }
  }

}

