import { Sort } from "../interfaces/display-interfaces";
import { ApiPagenation, ApiDef } from "../api/sip-api-client";
import { DeviceTypeListApi } from "../api/device-type";
import * as _ from 'lodash';
import { Params } from "@angular/router";

/**
 * ページング可能な検索条件を表す抽象クラス
 *
 * ページやソートに関する共通機能を提供する。
 *
 * @template API 検索APIのAPI定義
 */
export abstract class PageableSearchCondition<API extends ApiDef> {
  /** バックエンド側で page_size 無指定時のページサイズ */
  static readonly DEFAULT_PAGE_SIZE = 100;

  constructor(
    public sort: Sort | null = null,
    public page: number | null = null
  ) { }

  abstract get query(): API['queryInterface'];

  get order(): API['orderableInterface'][] {
    if (this.sort == null) { return []; }
    return [{ [this.sort.column]: this.sort.direction === 'asc' }];
  }

  get pagination(): ApiPagenation {
    const pagination: ApiPagenation = {};
    if (this.page != null) {
      pagination.page = this.page;
    }
    if (this.pageSize !== PageableSearchCondition.DEFAULT_PAGE_SIZE) {
      pagination.pageSize = this.pageSize;
    }
    return pagination;
  }

  setSort(columnName: string): void {
    if (this.sort == null) {
      // ソート指定なし
      this.sort = { column: columnName, direction: 'asc' };
    } else if (this.sort.column === columnName) {
      // 既にソート済みのカラムを指定したらソート順を入れ替える
      this.sort.direction = (this.sort.direction === 'desc') ? 'asc' : 'desc';
    } else {
      // 既にソートされているカラムとは別のカラムでソート
      this.sort = { column: columnName, direction: 'asc' };
    }
  }

  clearSort(): void {
    this.sort = null;
  }

  currentSortDirection(columnName: string): 'asc' | 'desc' | undefined {
    if (this.sort == null) { return undefined; }
    return this.sort.column === columnName ? this.sort.direction : undefined;
  }

  setPage(pageNum: number) {
    this.page = pageNum;
  }

  clearPage() {
    this.page = null;
  }

  get pageSize(): number {
    return PageableSearchCondition.DEFAULT_PAGE_SIZE;
  }

  queryParams(): Params {
    const params: Params = {};
    const query = this.query;
    for (const key of Object.keys(query)) {
      const value = query[key];
      if (this.isEmpty(value)) { continue; }
      if (Array.isArray(value)) {
        params[_.snakeCase(key)] = value.join('|');
      } else {
        params[_.snakeCase(key)] = value;
      }
    }
    const order = this.order;
    if (!this.isEmpty(order)) {
      params['ordering'] = this.joinOrder(order);
    }
    const pagination = this.pagination;
    for (const key of Object.keys(pagination)) {
      const value = pagination[key];
      if (this.isEmpty(value)) { continue; }
      params[_.snakeCase(key)] = value;
    }
    return params;
  }

  /** ページ情報、ソート条件を更新する */
  update(params: Params) {
    if (!this.isEmpty(params['ordering'])) {
      this.sort = this.parseOrder(params['ordering']);
    }
    if (!this.isEmpty(params['page'])) {
      this.setPage(Number(params['page']));
    }
  }

  protected isEmpty(value: any) {
    if (value == null) { return true; }
    if (typeof value === 'string') {
      return value === '';
    } else if (Array.isArray(value)) {
      return value.length === 0;
    } else if (typeof value === 'object') {
      // add by lms at 20191115 start typeがDateの場合、判断結果不正
      if (value.getDate != null) {
        return false;
      }
      // add by lms at 20191115 end　typeがDateの場合、判断結果不正
      return Object.keys(value).length === 0;
    }
    return false;
  }

  protected toBoolean(value: any): boolean {
    if (typeof value === 'boolean') {
      return value;
    } else if (typeof value === 'string') {
      const truePattern = /^true$/i;
      return truePattern.test(value);
    } else {
      return false;
    }
  }

  private joinOrder(order: API['orderableInterface'][]): string {
    return order.map(elm => {
      const key = Object.keys(elm)[0];
      const isAsc = elm[key];
      return `${isAsc ? '' : '-'}${_.snakeCase(key)}`;
    }).join(',');
  }

  private parseOrder(order: string): Sort {
    const isDesc = order.startsWith('-');
    const column = order.substring(isDesc ? 1 : 0);
    return { column: _.camelCase(column), direction: isDesc ? 'desc' : 'asc' };
  }
}
