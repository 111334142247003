<div class="row">
  <div class="col-sm-10">
    <h2>{{deviceGroupInfo.displayName}}</h2>
  </div>
  <div class="col-sm-2 text-right">
    <a mat-raised-button role="button" [routerLink]="['/device_groups']" routerLinkActive="active">
      <mat-icon aria-hidden="true">arrow_upward</mat-icon>{{'title_back_to_list' | translate}}
    </a>
  </div>
</div>
<nav mat-tab-nav-bar>
  <a mat-tab-link [routerLink]="['./']" routerLinkActive #ria1="routerLinkActive" [active]="ria1.isActive" [routerLinkActiveOptions]="{ exact: true }">{{'title_detail' | translate}}</a>
</nav>
<h4>{{'title_basic_props' | translate}}</h4>
<div class="row">
  <div class="col-sm-4">
    <div class="panel panel-default">
      <div class="panel-heading">{{'title_device_group_name' | translate}}</div>
      <div class="panel-body">
          <app-editable-text #displayName [inputControl]="displayNameControl" [errors]="displayNameErrors" id="display_name" name="display_name" type="text" required
            maxlength="50" [disabled]="!canEdit" (saved)="updateDisplayName($event)"></app-editable-text>
        </div>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="panel panel-default">
      <div class="panel-heading">{{'title_group_of_devicegroup' | translate}}</div>
      <div class="panel-body">
        {{deviceGroupInfo.groupDisplayName}}
      </div>
    </div>
  </div>
</div>

<h4>{{'title_device' | translate}}</h4>
<mat-card class="result-table">
  <mat-card-content>
    <table class="table table-bordered table-hover">
      <colgroup>
        <col style="width:60%">
        <col style="width:40%">
      </colgroup>
      <thead>
        <tr>
          <th *ngFor="let columns of columnList">
            <label class="labelth" *ngIf="!columns.issort" id={{columns.id}}>
              {{columns.displayName | translate}}
            </label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let device of deviceResult" class="active">
          <td [attr.data-label]="columnList[0].displayName">{{device.displayName}}</td>
          <td [attr.data-label]="columnList[1].displayName">{{device.deviceId}}</td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="isNoData">
      <app-note role="info">
        {{'msg_no_data_to_display' | translate}}
      </app-note>
    </div>
  </mat-card-content>
</mat-card>


