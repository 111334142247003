import { Component, OnInit, } from '@angular/core';
import { ApiHandler } from '../services/api-handler.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertDetailComponent } from './modal/alertdetail.component';
import { AlertLogGetApi, AlertLogListApi, AlertLogForList, MailSendStatus, AlertActiveStatus } from '../common/api/alert-log';
import { ApiPagenation } from '../common/api/sip-api-client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { GroupsGetApi } from '../common/api/group';
import { DeviceTypeListApi } from '../common/api/device-type';
import { SelectOption, IconDef, Sort } from '../common/interfaces/display-interfaces';
import { PageableSearchCondition } from '../common/view/PageableSearchCondition';
import { QueryStoreService } from '../services/query-store.service';
import { AppComponent } from '../app.component';
import {ReloadIntervalGetApi} from '../common/api/user';

@Component({
  moduleId: module.id,
  templateUrl: 'alert-logs.component.html'
})
export class AlertLogsComponent implements OnInit {
  // 検索条件
  condition: AlertLogSearchCondition;
  isExpanded: boolean = true;
  isNoData: boolean = false;

  constructor(
    public apiHandler: ApiHandler,
    public modalService: BsModalService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private queryStore: QueryStoreService,
    private appComponent: AppComponent,
  ) { }

  // interfaceModel
  alertLogResults: Array<AlertLogForList &
    {
      alertActiveName: string,
      alertActiveIcon: IconDef,
      mailStatusName: string,
      mailStatusIcon: IconDef,
    }> = [];

  /** 詳細表示時のデバイスタイプ情報 */
  alertLog: AlertLogGetApi['responseBodyInterface'];

  // DropDown
  dropdownDeviceTypes: SelectOption[] = [];
  dropdownGroups: SelectOption[] = [];
  dropdownIsActive: SelectOption[] = [];

  // Pagination
  count: number;  // 全件数

  // リロード間隔
  reloadInterval: number;
  needReloadFlg: boolean = false;

  columnList = [
    { seq: 1, id: 'device_display_name', displayName: 'title_device', issort: true },
    { seq: 2, id: 'start', displayName: 'title_ooccurrence_datetime', issort: true },
    { seq: 3, id: 'title', displayName: 'title_title', issort: false },
    { seq: 4, id: 'is_active', displayName: 'title_state', issort: false },
    { seq: 5, id: 'is_mail_sent', displayName: 'title_mail_state', issort: false }
  ];

  // ソート
  sortAlerts(sortColumn: string) {
    if (this.needReloadFlg) {
      this.startTimer();
    }
    this.condition.setSort(sortColumn);
    this.condition.clearPage();
    this.searchAlerts();
  }

  /**
   * 検索ボタンonclick
   */
  clickSearch() {
    if (this.needReloadFlg) {
      this.startTimer();
    }
    this.condition.clearSort();
    this.condition.clearPage();
    this.searchAlerts();
  }

  /**
   * ページ遷移
   */
  pageChanged(event: any): void {
    if (this.needReloadFlg) {
      this.startTimer();
    }
    this.condition.setPage(event.page);
    this.searchAlerts();
  }

  startTimer(): void {
    const that = this;
    clearInterval(this.appComponent.reloadFun);
    this.appComponent.reloadFun = setInterval(function() {
      return that.searchAlerts();
    }, that.reloadInterval * 60 * 1000);
  }

  /**
   * アラートログ一覧取得
   */
  searchAlerts() {
    // URLを変更する
    const queryParams = this.condition.queryParams();
    const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
    this.location.go('/alert_logs', queryString);
    // 検索クエリ
    const query = this.condition.query;
    const order = this.condition.order;
    const pagination = this.condition.pagination;
    // アラート記録一覧検索
    this.apiHandler.call(false, AlertLogListApi, [], undefined, query, order, pagination)
      .subscribe(res => {
        if (!res.hasError) {

          this.queryStore.storeQuery('alert_logs', this.condition.queryParams());  // 検索条件を保存
          const alertLogs = res.body;
          this.count = alertLogs.count;
          this.alertLogResults = alertLogs.results.map(alertLog => {
            return {
              alertActiveName: AlertActiveStatus.toName(alertLog.isActive),
              alertActiveIcon: AlertActiveStatus.toIcon(alertLog.isActive),
              mailStatusName: MailSendStatus.toName(alertLog.isMailSent),
              mailStatusIcon: MailSendStatus.toIcon(alertLog.isMailSent),
              ...alertLog
            };
          });

          if (this.alertLogResults.length === 0) {
            this.isNoData = true;
          } else {
            this.isNoData = false;
          }
        } else {
          if (this.condition.page != null && this.condition.page > 1) {
            this.condition.setPage(this.condition.page - 1);
            this.searchAlerts();
          } else {
            this.isNoData = true;
            this.alertLogResults = [];
          }
        }
      });
  }

  /**
   * リロード間隔取得
   */
  private searchReloadInterval() {
    // リロード間隔取得API呼び出し
    this.apiHandler.call(false, ReloadIntervalGetApi, [], undefined, {}, [])
      .subscribe(
        res => {
          const reloadIntervalObj = res.body;
          this.reloadInterval = reloadIntervalObj.interval;
          if (this.reloadInterval != null && this.reloadInterval !== 0 && this.reloadInterval !== -1) {
            this.needReloadFlg = true;
            this.startTimer();
          }
        });
  }

  /**
   * デバイスタイプ一覧取得
   */
  private searchDeviceTypes() {
    const pagination: ApiPagenation = {
      pageSize: 1000,  // 1ページに全デバイスタイプが収まるように、ページサイズを最大にする
    };
    // デバイスタイプ一覧取得API呼び出し
    this.apiHandler.call(false, DeviceTypeListApi, [], undefined, {}, [], pagination)
      .subscribe(
        res => {
          const deviceTypes = res.body;
          // ドロップダウンの選択肢を更新する
          this.dropdownDeviceTypes = deviceTypes.results.map(deviceType => {
            return { value: deviceType.id, name: deviceType.displayName };
          });
        });
  }
  /**
     * グループ一覧取得
     */
  private searchGroups() {
    const pagination: ApiPagenation = {
      pageSize: 1000,  // 1ページに全グループが収まるように、ページサイズを最大にする
    };
    // グループ一覧取得API呼び出し
    this.apiHandler.call(false, GroupsGetApi, [], {}, {}, [], pagination)
      .subscribe(
        res => {
          const groups = res.body;
          // ドロップダウンの選択肢を更新する
          this.dropdownGroups = groups.results.map(group => {
            return { value: group.id, name: group.displayName };
          });
        });
  }


  /**
   * アラート有無Dropdown生成
   */
  searchIsActive() {
    this.dropdownIsActive = [
      { value: true, name: 'title_ongoing' },
      { value: false, name: 'title_terminated' }
    ];
  }

  /**
   * 詳細画面起動
   */
  openDetail(alertLogId) {
    // 詳細情報を取得
    this.location.go('/alert_logs/' + alertLogId);
    this.apiHandler.call(false, AlertLogGetApi, [alertLogId])
      .subscribe(res => {
        if (res.hasError) {
          return undefined;
        } else {
          const initState = {
            alertActiveName: AlertActiveStatus.toName(res.body.isActive),
            alertActiveIcon: AlertActiveStatus.toIcon(res.body.isActive),
            mailStatusName: MailSendStatus.toName(res.body.isMailSent),
            mailStatusIcon: MailSendStatus.toIcon(res.body.isMailSent),
            ...res.body
          };
          this.modalService.config.class = 'modal-dialog modal-lg';
          const modalRef = this.modalService.show(AlertDetailComponent, { initialState: { alert: initState }, backdrop: 'static' });
          this.modalService.onHide.subscribe(() => {
            const queryParams = this.condition.queryParams();
            const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
            this.location.go('/alert_logs', queryString);
          });
        }
      });
  }

  /**
  * 初期化処理
  */
  ngOnInit(): void {
    this.condition = new AlertLogSearchCondition();
    /* クエリ文字列 or 永続化された検索条件で検索条件入力フォームを更新する */
    let activeQuery: Params = {};
    const params = this.route.snapshot.queryParams;  // クエリ文字列
    if (Object.keys(params).length === 0) {
      // クエリ文字列が空の場合は保存されている検索条件を取得する
      const storedQuery = this.queryStore.getQuery('alert_logs');
      activeQuery = storedQuery;
    } else {
      activeQuery = params;
    }
    this.condition.update(activeQuery);
    // アラートログ一覧取得
    this.searchAlerts();
    // アラートログ一覧取得
    this.searchReloadInterval();
    // アラート有無ドロップダウン設定
    this.searchIsActive();
    // デバイスタイプ取得
    this.searchDeviceTypes();
    // グループ取得
    this.searchGroups();
    // パラメーター付きで遷移してきた場合、
    // 親側で情報を取得してモーダルを開く
    if (this.route.snapshot.params['alert_log_id']) {
      this.openDetail(this.route.snapshot.params['alert_log_id']);
    } else {
      this.modalService.hide(10);
    }
  }

}

/** アラートログの検索条件を表すクラス */
class AlertLogSearchCondition extends PageableSearchCondition<AlertLogListApi> {

  constructor(
    sort: Sort | null = null,
    page: number | null = null,
    public deviceDisplayName: string = null,
    public deviceId: string = null,
    public groupId: number[] = [],
    public deviceTypeId: number[] = [],
    public isActive: boolean | null = null,
    public alertTitle: string = null,
  ) {
    super(sort, page);
  }

  get query(): AlertLogListApi['queryInterface'] {
    const query: AlertLogListApi['queryInterface'] = {};
    if (!this.isEmpty(this.deviceDisplayName)) {
      query.deviceDisplayName = this.deviceDisplayName;
    }
    if (!this.isEmpty(this.deviceId)) {
      query.deviceId = this.deviceId;
    }
    if (!this.isEmpty(this.groupId)) {
      query.groupId = this.groupId;
    }
    if (!this.isEmpty(this.deviceTypeId)) {
      query.deviceTypeId = this.deviceTypeId;
    }
    if (!this.isEmpty(this.isActive)) {
      query.isActive = this.isActive;
    }
    if (!this.isEmpty(this.alertTitle)) {
      query.alertTitle = this.alertTitle;
    }
    return query;
  }

  /** クエリパラメータに応じて条件を更新する */
  update(params: Params): void {
    super.update(params);
    if (!this.isEmpty(params['device_display_name'])) {
      this.deviceDisplayName = params['device_display_name'];
    }
    if (!this.isEmpty(params['device_id'])) {
      this.deviceId = params['device_id'];
    }
    if (!this.isEmpty(params['group_id'])) {
      this.groupId = params['group_id'].split('|').map(elm => Number(elm));
    }
    if (!this.isEmpty(params['device_type_id'])) {
      this.deviceTypeId = params['device_type_id'].split('|').map(elm => Number(elm));
    }
    if (!this.isEmpty(params['is_active'])) {
      this.isActive = this.toBoolean(params['is_active']);
    }
    if (!this.isEmpty(params['alert_title'])) {
      this.alertTitle = params['alert_title'];
    }
  }

  /** 入力フォームの入力をクリアする */
  clear() {
    this.deviceDisplayName = null;
    this.deviceId = null;
    this.groupId = [];
    this.deviceTypeId = [];
    this.isActive = null;
    this.alertTitle = null;
  }
}

