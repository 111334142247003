import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormBuilder, Validators, FormGroup, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { ApiHandler } from '../../services/api-handler.service';
import { Observable, Subject } from 'rxjs';
import { LpwaPacketType, LpwaPacketTypeAddApi, LpwaPacketTypeUpdateApi } from '../../common/api/lpwa-packet';
import { CustomValidators } from 'ng2-validation';

@Component({
  moduleId: module.id,
  templateUrl: './lpwa-packet-type-edit.component.html'
})
export class LpwaPacketTypeEditComponent implements OnInit {

  get title() {
    return this.editMode ? 'title_lpwa_packet_type_update' : 'title_lpwa_packet_type_registration';
  }
  /**
   * trueなら更新モード。
   * falseなら新規登録モード。
   */
  editMode: boolean = false;

  /** 編集が成功したときに発火するイベント */
  onEditSuccess: Subject<boolean>;

  /**
   * LPWAパケットタイプを紐づけるデバイスタイプのユニークID。
   *
   * 親コンポーネントから引き継ぐ想定です。
   */
  parentDeviceTypeId: number;

  /**
   * 既にパケットタイプ番号として使われてい番号の配列。
   *
   * 親コンポーネントから引き継ぐ想定です。
   */
  usedNumbers: any[] = [];

  /**
   * 更新対象のLPWAパケットタイプ
   *
   * 更新モードの場合のみ親コンポーネントから引き継ぐ想定です。
   */
  originalPacketType: LpwaPacketType;

  // 入力フォーム
  packetTypeNumber: FormControl;
  packetTypeForm: FormGroup;

  constructor(
    public t: TranslateService, public bsModalRef: BsModalRef,
    private builder: FormBuilder, private apiHandler: ApiHandler) {
    this.onEditSuccess = new Subject();
  }

  ngOnInit() {
    /* 入力フォームの初期化 */
    this.packetTypeNumber = new FormControl(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(255),
      CustomValidators.digits,
      this.validateAlreadyUsedNumber()
    ]);
    this.packetTypeForm = this.builder.group({
      packetType: this.packetTypeNumber
    });
  }

  /**
   * 親コンポーネントから引き継いだ情報でコンポーネントの内部状態を変更する。
   *
   * @param deviceTypeId LPWAパケットタイプが紐づくデバイスタイプのユニークID
   * @param usedNumbers 既に登録済みのLPLWAパケットタイプ番号の配列
   * @param packetType 更新対象のLPWAパケットタイプ。指定された場合はこの画面は
   *   更新モードとなる。指定されていない場合は新規登録モードとなる。
   *
   */
  prepareForEdit(deviceTypeId: number, usedNumbers: number[], packetType?: LpwaPacketType) {
    this.parentDeviceTypeId = deviceTypeId;
    this.usedNumbers = usedNumbers;
    if (packetType) {
      this.editMode = true;
      this.originalPacketType = packetType;
      /* フォームの入力値を指定されたLPWAパケットタイプの値で上書きする。 */
      this.packetTypeNumber.setValue(packetType.packetType);
    } else {
      this.editMode = false;
    }
  }

  /** LPWAパケットタイプを保存する（登録 or 更新） */
  save() {
    let apiCall: Observable<{
      body: undefined;
      code: number;
      msg: string;
      hasError: boolean;
    }>;
    if (this.editMode) {
      // 更新モードの場合は更新API発行
      const entity: LpwaPacketTypeUpdateApi['requestPayloadInterface'] = {
        packetType: this.packetTypeNumber.value
      };
      apiCall = this.apiHandler.call(true, LpwaPacketTypeUpdateApi, [this.originalPacketType.id], entity);
    } else {
      // 登録モードの場合は登録API発行
      const entity: LpwaPacketTypeAddApi['requestPayloadInterface'] = {
        deviceTypeId: this.parentDeviceTypeId,
        packetType: this.packetTypeNumber.value
      };
      apiCall = this.apiHandler.call(true, LpwaPacketTypeAddApi, [], entity);
    }
    apiCall.subscribe(res => {
      if (!res.hasError) {
        this.onEditSuccess.next(true);
        this.bsModalRef.hide();
      }
    });
  }

  /** 既に使用されている番号とかぶっていないか検証する */
  private validateAlreadyUsedNumber(): ValidatorFn {
    const key = 'alreadyUsed';
    return (control: AbstractControl): ValidationErrors => {
      const value = control.value;
      if (value == null) { return null; }
      const alreadyUsed = this.usedNumbers.includes(value);
      if (alreadyUsed) {
        return { [key]: true };
      }
      return null;
    };
  }
}
