import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule, MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

/** ツールチップのデフォルト設定 */
const appTooltipDefaultOptions: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 0,
  touchendHideDelay: 0
};

/**
 * アプリケーションで利用する Angular Material のモジュールをまとめたモジュール。
 *
 * 毎度個別のモジュールごとに利用するコンポーネントをimportするのはめんどくさいので、
 * アプリケーションで利用するコンポーネントをあらかじめimportしておく。
 */
@NgModule({
  imports: [
    MatToolbarModule, MatSidenavModule, MatMenuModule, MatTabsModule, MatCardModule, MatFormFieldModule, MatInputModule,
    MatListModule, MatSelectModule, MatSlideToggleModule, MatButtonModule, MatIconModule, MatCheckboxModule, MatProgressBarModule,
    MatTooltipModule, MatRadioModule, MatDatepickerModule, MatExpansionModule
  ],
  exports: [
    MatToolbarModule, MatSidenavModule, MatMenuModule, MatTabsModule, MatCardModule, MatFormFieldModule, MatInputModule,
    MatListModule, MatSelectModule, MatSlideToggleModule, MatButtonModule, MatIconModule, MatCheckboxModule, MatProgressBarModule,
    MatTooltipModule, MatRadioModule, MatDatepickerModule, MatExpansionModule
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: appTooltipDefaultOptions }
  ]
})
export class AppMaterialModule { }
