import { OnInit, OnDestroy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { WebStorageAuthRepository } from '../../services/auth-repository.service';
import { DeviceTypeGetApi } from '../../common/api/device-type';

@Component({
  selector: 'app-alert-defs',
  templateUrl: './alert-defs.component.html'
})
export class AlertDefsComponent implements OnInit, OnDestroy {

  deviceType: DeviceTypeGetApi['responseBodyInterface'];
  /** 新規のアラート定義入力欄を表示中かどうか */
  isAdding: boolean;

  /** 親コンポーネントの解決済みデータを受け取るイベントの購読状態 */
  private parentSubscription = Subscription.EMPTY;

  constructor(
    public t: TranslateService,
    private route: ActivatedRoute,
    private authRepo: WebStorageAuthRepository,
  ) { }

  /** デバイスタイプを編集できるかどうか */
  get canEdit() {
    return this.authRepo.hasPermission('device_type', 'edit');
  }

  ngOnInit(): void {
    this.parentSubscription = this.route.parent.data.subscribe(obj => {
      // 親コンポーネントのデバイスタイプをそのまま使わせていただく
      this.deviceType = obj['deviceType'];
      this.isAdding = false;
    });
  }

  ngOnDestroy(): void {
    // イベント購読を停止する
    this.parentSubscription.unsubscribe();
  }

}
