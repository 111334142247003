import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Functions } from '../../common/functions';
import { Property } from '../../common/api/device';
@Component({
  moduleId: module.id,
  templateUrl: 'map-edit.component.html'
})
export class MapEditComponent implements OnInit {
  static readonly DEFAULT_LATLNG = '35,135';

  /**
   * 地図に表示する緯度経度を表す。
   *
   * プロパティの現在値、デフォルト値を順番に見て、緯度経度として
   * 正しい値ならその値を使用する。どちらも不適切な値の場合は
   * デフォルトの緯度経度を使用する。
   */
  get latlng(): string {
    if (this.property == null) { return undefined; }
    if (this.functions.isLatlng(this.property.value)) {
      return this.property.value;
    } else if (this.functions.isLatlng(this.property.defaultValue)) {
      return this.property.defaultValue;
    } else {
      return MapEditComponent.DEFAULT_LATLNG;
    }
  }

  /**
   * 地図の拡大具合を表す。
   *
   * デフォルトの緯度経度ならあまり拡大しない(5)。
   * それ以外なら適度に拡大する(15)。
   */
  get zoom(): number {
    if (this.property == null) { return 5; }
    return (!this.functions.isLatlng(this.property.value) &&
      !this.functions.isLatlng(this.property.defaultValue)) ?
      5 : 15;
  }

  /** 地図で設定する緯度経度を設定するターゲットとなるプロパティ */
  property: Property;
  constructor(public bsModalRef: BsModalRef, private functions: Functions) { }

  onSet() {
    // 選択した座標の情報をset
    const element: HTMLInputElement = <HTMLInputElement>document.getElementById('selectedlatlng');
    const value: string = element.value;
    this.property.value = value;
    this.bsModalRef.hide();
  }

  ngOnInit() {
  }

  get param() {
    return { propName: this.property ? this.property.displayName : '' };
  }
}
