<div class="row">
  <div class="col-sm-12">
    <div class="panel" [ngClass]="{'panel-default': alertDef.id, 'panel-info': !alertDef.id}">
      <div class="panel-heading">
        <div class="panel-title">
          <a data-toggle="collapse" href="#alert_def_{{alertDef.id}}">
            {{alertDef.title}}
          </a>
        </div>
      </div>
      <div id="alert_def_{{alertDef.id}}" class="panel-collapse collapse" [ngClass]="{'in': !editMode}">
        <div class="panel-body panel-alert-edit">
          <form [formGroup]="alertDefForm">
            <div class="row">
              <div class="col-sm-12">
                <mat-form-field class="full-width-field">
                  <input matInput id="title_{{alertDef.id}}" type="text" placeholder="{{'title_title' | translate}}" [formControl]="title" [readonly]="disabled" required
                    maxlength="50">
                  <ng-container *ngIf="title.touched && title.errors" ngProjectAs="mat-error">
                    <mat-error *ngIf="title.hasError('required')">{{'msg_title_required' | translate}}</mat-error>
                    <mat-error *ngIf="title.hasError('maxlength')">{{'msg_title_must_be_50_or_less' | translate}}</mat-error>
                  </ng-container>
                </mat-form-field>
              </div>
              <div class="col-sm-12">
                <mat-form-field class="no-underline" floatPlaceholder="always">
                  <!--
                      mat-form-field にラジオボタンを配置するための超汚いハック。
                      表示しないテキストフィールドと一緒にラジオボタンを表示させる。
                      formControlはテキストフィールドとラジオボタンで共有することに注意。
                      mat-error でのエラー表示もぼちぼちできる。
                      refs. https://github.com/angular/material2/issues/7891#issuecomment-342691238
                    -->
                  <input matInput value="xxx" placeholder="{{'title_alert_type' | translate}}" style="display: none">
                  <mat-radio-group class="row-radio-group" name="type_{{alertDef.id}}" [formControl]="type" required>
                    <mat-radio-button name="type_{{alertDef.id}}" *ngFor="let alertTypeOption of alertTypeOptions" [value]="alertTypeOption.value"
                      [checked]="type.value == alertTypeOption.value" (change)="onChangeType(alertTypeOption.value)" [disabled]="disabled">
                      {{alertTypeOption.name | translate}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <ng-container *ngIf="type.touched && type.errors" ngProjectAs="mat-error">
                    <mat-error *ngIf="type.hasError('required')">{{'msg_alert_type_required' | translate}}</mat-error>
                  </ng-container>
                </mat-form-field>
              </div>
              <div class="col-sm-12">
                <mat-form-field class="full-width-field">
                  <textarea matInput id="detail_{{alertDef.id}}" placeholder="{{'title_detail' | translate}}" [readonly]="disabled" [formControl]="detail" required></textarea>
                  <ng-container *ngIf="detail.touched && detail.errors" ngProjectAs="mat-error">
                    <mat-error *ngIf="detail.hasError('required')">{{'msg_detail_required' | translate}}</mat-error>
                  </ng-container>
                </mat-form-field>
              </div>
              <div class="col-xs-10 col-sm-11">
                <mat-form-field class="full-width-field">
                  <textarea matInput id="detail_{{alertDef.id}}" placeholder="{{'title_conditions' | translate}}" [readonly]="disabled" [formControl]="conditions"></textarea>
                  <ng-container *ngIf="conditions.touched && conditions.errors" ngProjectAs="mat-error">
                    <mat-error *ngIf="conditions.hasError('required')">{{'msg_conditions_required_when_type_is_conditions' | translate}}</mat-error>
                  </ng-container>
                </mat-form-field>
              </div>
              <div class="col-xs-2 col-sm-1 text-right">
                <button type="button" mat-icon-button [popover]="conditionHelp" containerClass="condition-help" placement="left">
                  <mat-icon aria-hidden="true">help_outline</mat-icon>
                </button>
              </div>
              <div class="col-xs-12 col-xs-12">
                <mat-form-field class="full-width-field">
                  <input matInput id="ping_interval_{{alertDef.id}}" type="number" placeholder="{{'title_ping_check_intvl' | translate}}" [readonly]="disabled" [formControl]="pingInterval">
                  <ng-container *ngIf="pingInterval.touched && pingInterval.errors" ngProjectAs="mat-error">
                    <mat-error *ngIf="pingInterval.hasError('required')">{{'msg_pint_intvl_required_when_type_is_ping' | translate}}</mat-error>
                    <mat-error *ngIf="pingInterval.hasError('digits')">{{'msg_input_integer' | translate}}</mat-error>
                  </ng-container>
                </mat-form-field>
              </div>
              <div class="col-sm-12">
                <mat-form-field class="full-width-field">
                  <input matInput id="suppress_interval_{{alertDef.id}}" type="number" placeholder="{{'title_alert_suppress_intvl' | translate}}" [readonly]="disabled" [formControl]="suppressInterval">
                  <ng-container *ngIf="suppressInterval.touched && suppressInterval.errors" ngProjectAs="mat-error">
                    <mat-error *ngIf="suppressInterval.hasError('digits')">{{'msg_input_integer' | translate}}</mat-error>
                  </ng-container>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12 text-right">
              <button mat-raised-button color="primary" [disabled]="disabled || !alertDefForm.dirty || alertDefForm.invalid" (click)="save()">
                <mat-icon aria-hidden="true">save</mat-icon> {{'title_save' | translate}}
              </button>
              <button mat-raised-button color="warn" [disabled]="disabled || !editMode" (click)="delete()">
                <mat-icon aria-hidden="true">delete</mat-icon> {{'title_delete' | translate}}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 条件式のヘルプ -->
<ng-template #conditionHelp>
  <div>
    <p [innerHTML]="'msg_about_prop_table' | translate"></p>
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th></th>
          <th *ngFor="let columns of columnList">
            <label class="labelth" id={{columns.id}}>
              {{columns.displayName | translate}}
            </label>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let propDef of propertyDefs">
          <tr *ngIf="propDef.measurementKey">
            <td>
              <button mat-icon-button class="icon-btn" [disabled]="disabled" matTooltip="条件に挿入" (click)="appendResourceName(propDef.resourceName)">
                <mat-icon aria-hidden="true">note_add</mat-icon>
              </button>
            </td>
            <td *ngFor="let column of columnList">
              <span *ngIf="column.converter">{{column.converter(propDef[column.id]) | translate}}<!-- 変換あり --></span>
              <span *ngIf="!column.converter">{{propDef[column.id]}}<!-- 変換なし --></span>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <ul>
    </ul>
  </div>
</ng-template>
