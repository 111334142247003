<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{'title_display_period' | translate}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="row">
    <div class="datetimepickers">
      <div class="col-md-3 col-xs-6">
        <mat-form-field>
          <input matInput [matDatepicker]="startDatepicker" [value]="startDatetime" placeholder="{{'title_start_datetime' | translate}}" autocomplete="off" (dateChange)="updateDate($event.value, 'start')">
          <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-3 col-xs-6">
        <timepicker [(ngModel)]="startDatetime" [showSeconds]="showSec" [showMeridian]="false"></timepicker>
      </div>
      <div class="col-md-3 col-xs-6">
        <mat-form-field>
          <input matInput [matDatepicker]="endDatepicker" [value]="endDatetime" placeholder="{{'title_end_datetime' | translate}}" autocomplete="off" (dateChange)="updateDate($event.value, 'end')">
          <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-3 col-xs-6">
        <table>
          <tr>
            <td>
              <timepicker [(ngModel)]="endDatetime" [showSeconds]="showSec" [showMeridian]="false"></timepicker>
            </td>
            <td>
              <button mat-icon-button matTooltip="{{'title_current_time' | translate}}" (click)="setNow()">
                <mat-icon aria-hidden="true">access_time</mat-icon>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 col-sm-offset-5">
      <button mat-raised-button color="accent" (click)="loadChart()">
        <mat-icon aria-hidden="true">refresh</mat-icon> {{'title_reload' | translate}}
      </button>
    </div>
  </div>
</mat-expansion-panel>
<mat-card>
  <mat-card-content>
      <div *ngIf="isChartVisible" style="display: block;">
          <canvas baseChart width="400" height="100"
                      [datasets]="seriesData"
                      [colors]="lineColor"
                      [options]="option"
                      [chartType]="lineChartType"></canvas>
          </div>
    <app-note *ngIf="!isChartVisible" role="info">
      <span>{{message | translate}}</span>
    </app-note>
  </mat-card-content>
</mat-card>
