import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

/** ルーティングに関連する便利メソッドを提供する */
@Injectable()
export class RouterService {

  constructor(private router: Router) { }

  /**
   * 自ページに再遷移する
   *
   * https://github.com/angular/angular/issues/13831#issuecomment-350095719
   * のワークアラウンドをメソッドにしたもの。
   *
   * ルーティング設定に
   *
   *   runGuardsAndResolvers: 'always'
   *
   * が必要であることに注意。
   */
  reload() {
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
  }
}
