<div class="modal-header">
  <h4 class="modal-title pull-left">{{title | translate}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="panel panel-default">
    <div class="panel-body">
      <form class="full-width-form" [formGroup]="propertyDefForm">
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <input matInput id="resource_name" name="resource_name" type="text" [formControl]="resourceName" placeholder="{{'title_resource_name' | translate}}"
                required>
              <mat-error *ngIf="resourceName.touched && resourceName.errors?.required">{{'msg_resource_name_required' | translate}}</mat-error>
              <mat-error *ngIf="resourceName.touched && resourceName.errors?.resourceName">{{'msg_invalid_resource_name' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <input matInput id="display_name" name="display_name" type="text" [formControl]="displayName" placeholder="{{'title_prop_name' | translate}}"
                maxlength="50" required>
              <mat-error *ngIf="displayName.touched && displayName.errors?.required">{{'msg_property_name_required' | translate}}</mat-error>
              <mat-error *ngIf="displayName.touched && displayName.errors?.maxlength">{{'msg_property_name_must_be_50_or_less' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <mat-select name="type" [formControl]="type" placeholder="{{'title_prop_type' | translate}}" required>
                <mat-option></mat-option>
                <mat-option *ngFor="let typeOption of typeOptions" [value]="typeOption.value">{{typeOption.name | translate}}</mat-option>
              </mat-select>
              <mat-error *ngIf="type.touched && type.errors?.required">{{'msg_property_type_required' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <input matInput id="unit" name="unit" type="text" [formControl]="unit" placeholder="{{'title_prop_unit' | translate}}" maxlength="50">
              <mat-error *ngIf="unit.touched && unit.errors?.maxlength">{{'msg_property_unit_must_be_50_or_less' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <mat-select name="visualizationType" [formControl]="visualizationType" placeholder="{{'title_vsl_type' | translate}}" required>
                <mat-option></mat-option>
                <mat-option *ngFor="let visualizationTypeOption of visualizationTypeOptions" [value]="visualizationTypeOption.value">{{visualizationTypeOption.name | translate}}</mat-option>
              </mat-select>
              <mat-error *ngIf="visualizationType.touched && visualizationType.errors?.required">{{'msg_vsl_type_required' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <textarea matInput id="default_value" name="default_value" [formControl]="defaultValue" placeholder="{{'title_default_value' | translate}}"></textarea>
              <mat-error *ngIf="(type.touched || defaultValue.touched) && defaultValue.errors?.defaultValuePattern">{{'msg_default_value_type_error' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <input matInput id="display_order" name="display_order" type="number" [formControl]="displayOrder" placeholder="{{'title_display_order' | translate}}"
                required>
              <mat-hint></mat-hint>
              <mat-error *ngIf="displayOrder.touched && displayOrder.errors?.required">{{'msg_display_order_required' | translate}}</mat-error>
              <mat-error *ngIf="displayOrder.touched && displayOrder.errors?.min">{{'msg_display_order_must_be_0_or_greater' | translate}}</mat-error>
              <mat-error *ngIf="displayOrder.touched && displayOrder.errors?.max">{{'msg_display_order_must_be_32767_or_less' | translate}}</mat-error>
              <mat-error *ngIf="displayOrder.touched && displayOrder.errors?.pattern">{{'msg_input_integer' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <input matInput id="measurement_key" name="measurement_key" type="text" [formControl]="measurementKey" maxlength="127" placeholder="{{'title_measurement_key' | translate}}">
              <mat-error *ngIf="measurementKey.touched && measurementKey.errors?.maxlength">{{'msg_measurement_key_must_be_127_or_less' | translate}}</mat-error>
              <mat-error *ngIf="measurementKey.touched && measurementKey.errors?.resourceName">{{'msg_invalid_resource_name' | translate}}</mat-error>
              <mat-error *ngIf="(visualizationType.touched || measurementKey.touched) && measurementKey.errors?.measurementKeyRequired">{{'msg_measurement_key_required_when_vsl_type_is_time_series' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-slide-toggle [formControl]="isVisible"> {{(isVisible.value ? 'title_do_visualization' : 'title_no_visualization') | translate}}</mat-slide-toggle>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button mat-raised-button color="primary" [disabled]="!propertyDefForm.dirty || propertyDefForm.invalid" (click)="save()">
    <mat-icon aria-hidden="true">save</mat-icon> {{'title_save' | translate}}
  </button>
  <button mat-raised-button (click)="bsModalRef.hide()">
    <mat-icon aria-hidden="true">clear</mat-icon> {{'title_cancel' | translate}}
  </button>
</div>
