import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SelectOption　} from '../../common/interfaces/display-interfaces';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiHandler } from '../../services/api-handler.service';
import {DeviceGrouspAddApi} from '../../common/api/device-group';

@Component({
  moduleId: module.id,
  templateUrl: 'device-groups-add.component.html',
  providers: [DeviceGrouspAddApi]
})

export class DeviceGroupsAddComponent implements OnInit {

  // DropDown
  deviceGroups: SelectOption[] = [];

  isOk = false;

  // 入力フォーム
  deviceGroupsForm: FormGroup;
  selectedGroup: FormControl;
  displayName: FormControl;

  constructor(
    public t: TranslateService,
    public bsModalRef: BsModalRef,
    public apiHandler: ApiHandler,
    private builder: FormBuilder) { }

  /**
   * デバイスグループ登録
  */
  groupsAdd() {
    const entity = this.toDeviceGroupsEntity();
    this.apiHandler.call(true, DeviceGrouspAddApi, [], entity)
      .subscribe(
        res => {
          // 登録成功の場合は登録完了フラグONにして画面を閉じる
          this.isOk = true;
          this.bsModalRef.hide();
        });
  }

  // 入力フォームの入力をグループのエンティティに変換する
  private toDeviceGroupsEntity(): DeviceGrouspAddApi['requestPayloadInterface'] {
    return {
      groupId: this.selectedGroup.value,
      displayName: this.displayName.value
    };
  }

  ngOnInit() {
    // 入力フォーム
    this.selectedGroup = new FormControl('', Validators.required);
    this.displayName = new FormControl('', [
      Validators.required,
      Validators.maxLength(50)
    ]);
    this.deviceGroupsForm = this.builder.group({
      groupId: this.selectedGroup,
      displayName: this.displayName
    });
  }
}

