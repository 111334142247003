import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PropertyDef } from '../../common/api/prop-def';
import { LpwaPacketType, LpwaDataType } from '../../common/api/lpwa-packet';
import { trigger, style, transition, animate } from '@angular/animations';
import { ColumnDefinition } from '../../common/interfaces/display-interfaces';

@Component({
  selector: 'app-lpwa-packet-type',
  templateUrl: './lpwa-packet-type.component.html',
  animations: [
    trigger('newRowState', [
      transition(
        ':enter', [
          style({ opacity: 0 }),
          animate('300ms ease-out', style({ opacity: 1 }))
        ]
      ),
      transition(
        ':leave', [
          style({ opacity: 1 }),
          animate('300ms ease-in', style({ opacity: 0 }))
        ]
      )]
    )]
})
export class LpwaPacketTypeComponent {
  /** 本コンポーネントで表示するLPWAパケットタイプ */
  @Input() packetType: LpwaPacketType;
  /** デバイスタイプに紐づくプロパティ定義一覧 */
  @Input() propertyDefs: PropertyDef[];
  /** LPWAパケットタイプを編集できるかどうかを表すフラグ */
  @Input() editable: boolean;

  /** LPWAパケットタイプの編集ボタンを押下したときに発火するイベント */
  @Output() clickEdit = new EventEmitter<boolean>();

  /** LPWAパケットタイプの削除ボタンを押下したときに発火するイベント */
  @Output() clickDelete = new EventEmitter<boolean>();

  /** 新規登録用の行を編集中か否か */
  isAdding = false;

  /** LPWAパケット解読表のカラム */
  readonly columnList: ColumnDefinition[] = [
    { seq: 1, id: 'startBit', displayName: 'title_start_byte' },
    { seq: 2, id: 'dataType', displayName: 'title_data_type' },
    { seq: 3, id: 'dataBitLength', displayName: 'title_byte_length' },
    { seq: 4, id: 'propertyDefId', displayName: 'title_corresponding_prop' }
  ];

  readonly dataTypeOptions = LpwaDataType.values().map(type => {
    return { id: type, name: LpwaDataType.toName(type) };
  });

  constructor(public t: TranslateService) {
  }

  fireClickEdit() {
    this.clickEdit.emit(true);
  }

  fireClickDelete() {
    this.clickDelete.emit(true);
  }

}
