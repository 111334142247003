import { ApiDef, ApiStatusMessage } from './sip-api-client';
import { Method } from '../enums/api-enums';
import { IconDef } from '../interfaces/display-interfaces';

/**
 * アラート購読切り替えAPIの定義
 */
export class AlertSubscriptionToggleAddApi extends ApiDef {
  requestPayloadInterface: {
    deviceId: number;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/alert_subscriptions/toggle';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
      { code: 201, message: 'toast_subscribed_alert' },
      { code: 204, message: 'toast_unsubscribed_alert' },
      { code: 400, message: 'toast_device_not_found' }
  ];
}

/** アラート購読状態 */
export type AlertSubscriptionStatus = boolean;
export namespace AlertSubscriptionStatus {
  export const toIcon = (status: AlertSubscriptionStatus): IconDef => {
    return status ?
     { label: 'title_subscribing', name: 'notifications_active', color: 'warn' } :
     { label: 'title_unsubscribing', name: 'notifications_none', color: 'foreground' };
  };
}
