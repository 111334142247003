<div>
  <div class="row" *ngIf="device">
    <div class="col-sm-6">
      <div class="panel panel-default panel-devices-edit">
        <div class="panel-heading">{{'title_device_id' | translate}}</div>
        <div class="panel-body">
          {{device.deviceId}}
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="panel panel-default panel-devices-edit">
        <div class="panel-heading">{{'title_device_name' | translate}}</div>
        <div class="panel-body">
          <mat-form-field class="compact-field">
            <input matInput id="display_name" name="display_name" type="text" [(ngModel)]="device.displayName">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-default">
        <div class="panel-body">
          <table class="table table-bordered table-hover">
            <colgroup>
              <col style="width:50%">
              <col style="width:15%">
              <col style="width:15%">
              <col style="width:15%">
            </colgroup>
            <thead>
              <tr>
                  <th><label class="labelth">{{'title_prop_name' | translate}}</label></th>
                  <th><label class="labelth">{{'title_prop_type' | translate}}</label></th>
                  <th><label class="labelth">{{'title_prop_value' | translate}}</label></th>
                  <th><label class="labelth">{{'title_prop_unit' | translate}}</label></th>
                </tr>
            </thead>
            <tbody>
              <tr *ngFor="let prop of device?.properties">
                <td>{{prop.displayName}}</td>
                <td>{{prop.type | property_type | translate}} </td>
                <td [ngSwitch]="prop.type">
                  <div *ngSwitchDefault>
                    <mat-form-field class="compact-field">
                      <input matInput type="text" [(ngModel)]="prop.value">
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="'latlng'">
                    <mat-form-field style="display:none">
                      <input matInput type="text" [(ngModel)]="prop.value">
                    </mat-form-field>
                    <button mat-button class="btn-map" (click)="openMapEdit(prop)">
                      <mat-icon aria-hidden="true">place</mat-icon>{{prop.value}}
                    </button>
                  </div>
                </td>
                <td>{{prop.unit}}</td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="isNoData" class="col-sm-12">
            <app-note role="info">{{'msg_no_data_to_display' | translate}}</app-note>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="row">
      <div class="col-sm-12 text-right">
        <button mat-raised-button color="primary" (click)="clickSave()">
          <mat-icon aria-hidden="true">save</mat-icon> {{'title_save' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
