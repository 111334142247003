import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { of } from 'rxjs';
import { UserModel } from '../models/user.model';
import { AuthPostApi, AuthRefreshApi } from '../common/api/auth';
import { SipApiClient, ApiDef, ApiPagenation } from '../common/api/sip-api-client';
import { WebStorageAuthRepository } from './auth-repository.service';
import { Router } from '@angular/router';
import { mergeMap, map, catchError } from 'rxjs/operators';

@Directive()
@Injectable()
export class ApiHandler {

  @Output() gotApiResult = new EventEmitter<{ code: number, msg: string }>();

  isLoggedIn = true;
  redirectUrl = '';
  user: UserModel;
  apiEvent: EventEmitter<boolean>;

  /**
   * コンストラクタ
   */
  constructor(private apiClient: SipApiClient, private authRepository: WebStorageAuthRepository, private router: Router) {
    this.apiEvent = apiClient.apiEvent;
  }

  /** 認証する */
  authenticate(email: string, password: string) {
    const payload = {
      email: email,
      password: password
    };
    return this.apiClient.exec(AuthPostApi, [], payload)
      .pipe(map(res => {
        if (res.code === 200) {
          this.authRepository.update(res.body);
          return { success: true, message: res.msg };
        } else {
          this.gotApiResult.emit({ code: res.code, msg: res.msg });
          return { success: false, message: res.msg };
        }
      }));
  }

  /**
   * ログアウト処理
   */
  // TODO: メソッド名をログアウトっぽい名前に変更する
  eraseToken(): void {
    this.authRepository.clear();
  }

  isLoggedin(): boolean {
    return this.authRepository.token !== '';
  }

  handleError(res: { code: number; msg: string; }): void {
    // 共通エラーの場合は上書き
    switch (res.code) {
      case 401:
        res.msg = '認証情報が不正です。';
        this.authRepository.clear();
        this.router.navigate(['/login']);
        break;
      case 403:
        res.msg = 'この操作を行う権限がありません。';
        break;
      case 500:
        res.msg = 'サーバーでエラーが発生しました。';
    }
    this.gotApiResult.emit({ code: res.code, msg: res.msg });
  }

  /** 認証トークンの更新ありでAPIを呼び出す */
  call<T extends ApiDef>(
    displayToast: boolean,
    apiDefType: { new(): T; }, uriParams?: any[], payload?: T['requestPayloadInterface'],
    query?: T['queryInterface'], order?: T['orderableInterface'][], page?: ApiPagenation) {
    return this.refreshToken().pipe(
      mergeMap(isSuccess => {
        if (isSuccess) {
          return this.apiClient.exec(apiDefType, uriParams, payload, query, order, page)
            .pipe(map(res => {
              if (res.hasError) {
                this.handleError(res);
              } else if (displayToast) {
                // AppComponentへ向けてイベントを通知(トースト用)
                this.gotApiResult.emit({ code: res.code, msg: res.msg });
              }
              // 結果も戻す
              return res;
            }));
        }
      }));
  }

  /** 必要に応じて認証トークンを更新する */
  private refreshToken() {
    if (this.authRepository.shouldReflesh) {
      const payload = {
        token: this.authRepository.token
      };
      return this.apiClient.exec(AuthRefreshApi, [], payload)
        .pipe(map(res => {
          if (res.hasError) {
            // 認証NG
            this.gotApiResult.emit({ code: res.code, msg: res.msg });
            this.authRepository.clear();
            this.router.navigate(['/login']);
            return false;
          }
          this.authRepository.update(res.body);
          return true;
        }),
        catchError(_error => {
          // 認証時にエラー発生
          this.authRepository.clear();
          this.router.navigate(['/login'], { queryParams: { session_end: true } });
          return of(false);
        }));
    } else {
      // 更新の必要なし
      return of(true);
    }
  }

}
