import {Component, OnInit, Input, AfterViewInit, SimpleChanges} from '@angular/core';
import { Functions } from '../../common/functions';
declare var ol: any;
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html'
})
export class MapComponent implements OnInit, AfterViewInit {
  @Input() id: string;
  @Input() latlng: string;
  @Input() zoom = 15;
  @Input() editable: boolean;
  ol: any;
  mapId = '';
  lat = 0;
  lng = 0;
  strLatlng = [];
  message = '';

  outLatlng = '';
  outLat = 0;
  outLng = 0;
  constructor(private functions: Functions) { }

  ngOnInit() {
    this.mapId = 'map' + this.id;
    // 座標を取得 latlng形式でなければエラー表示
    if (this.functions.isLatlng(this.latlng)) {
      this.strLatlng = this.latlng.split(',');
      this.lat = +this.strLatlng[0];
      this.lng = +this.strLatlng[1];
    } else {
      this.message = 'msg_no_map_displayed';
    }
  }

  // デバイス詳細画面の再表示ボタン用
  ngOnChanges(changes: SimpleChanges) {
    // マップ初期化でばなくて、前回の値と今回の値が違いの場合、マップをリフレッシュする
    if (!changes.latlng.firstChange && changes.latlng.previousValue != changes.latlng.currentValue) {
      // マップのDOMを削除する
      const parentNode = <HTMLInputElement>document.getElementById(this.mapId);
      const viewport = parentNode.querySelector(".ol-viewport");
      if (viewport != null) {
        viewport.remove();
      }
      // マップを作成する
      this.latlng = changes.latlng.currentValue;
      if (this.functions.isLatlng(this.latlng)) {
        this.strLatlng = this.latlng.split(',');
        this.lat = +this.strLatlng[0];
        this.lng = +this.strLatlng[1];
        this.message = '';
        parentNode.style.height = "300px"; // アップなしから有り変更する場合、<canvas>の高さ不正
        this.ngAfterViewInit();
      } else {
        this.message = 'msg_no_map_displayed';
      }
    }
  }

  ngAfterViewInit() {
    if (!this.functions.isLatlng(this.latlng)) { return; }
    // latlng形式の場合はMap生成
    let iconFeature = new ol.Feature({
      geometry: new ol.geom.Point(ol.proj.transform([this.lng, this.lat], 'EPSG:4326', 'EPSG:3857')),
    });
    const iconStyle = new ol.style.Style({
      image: new ol.style.Icon(/** @type {olx.style.IconOptions} */({
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: 'http://icons.iconarchive.com/icons/paomedia/small-n-flat/1024/map-marker-icon.png',
        scale: 0.03
      }))
    });
    iconFeature.setStyle(iconStyle);
    let vectorSource = new ol.source.Vector({
      features: [iconFeature]
    });
    let vectorLayer = new ol.layer.Vector({
      source: vectorSource
    });

    const map = new ol.Map({
      target: this.mapId,
      layers: [
        new ol.layer.Tile({
          source: new ol.source.OSM()
        }),
        vectorLayer
      ],
      view: new ol.View({
        center: ol.proj.fromLonLat([this.lng, this.lat]),
        zoom: this.zoom
      })
    });

    // 編集可の場合はクリックイベントを生成
    if (this.editable) {
      map.on('click', function (e) {
        // マーカーを削除
        map.removeLayer(vectorLayer);

        const element: HTMLInputElement = <HTMLInputElement>document.getElementById('selectedlatlng');
        // クリックした座標を取得
        const lonlat = ol.proj.transform(e.coordinate, 'EPSG:3857', 'EPSG:4326');
        this.outLng = Math.round(lonlat[0] * 1000000) / 1000000;
        this.outLat = Math.round(lonlat[1] * 1000000) / 1000000;
        element.value = this.outLat + ',' + this.outLng;

        // 中央に移動
        map.getView().setCenter(ol.proj.transform([this.outLng, this.outLat], 'EPSG:4326', 'EPSG:3857'));
        // マーカーを生成
        iconFeature = new ol.Feature({
          geometry: new ol.geom.Point(ol.proj.transform([this.outLng, this.outLat], 'EPSG:4326', 'EPSG:3857')),
        });
        iconFeature.setStyle(iconStyle);

        vectorSource = new ol.source.Vector({
          features: [iconFeature]
        });

        vectorLayer = new ol.layer.Vector({
          source: vectorSource
        });
        // マーカーをセット
        map.addLayer(vectorLayer);
      });

    }
  }
}
