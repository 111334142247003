<div class="modal-header">
  <h4 class="modal-title pull-left">{{title | translate}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="groupMemberAddForm">
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <input matInput type="text" [formControl]="email" placeholder="{{'title_email' | translate}}" required>
              <mat-error *ngIf="email.touched && email.errors?.required">{{'msg_email_required' | translate}}</mat-error>
              <mat-error *ngIf="email.touched && email.errors?.maxlength">{{'msg_email_must_be_128_or_less' | translate}}</mat-error>
              <mat-error *ngIf="email.touched && email.errors?.email">{{'msg_email_invalid' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <mat-select placeholder="{{'title_roles' | translate}}" [formControl]="roles" [(ngModel)]="selecteddropdownRoles" multiple="true" required>
                <div>
                  <button mat-button style="width:100%; text-align:left;" (click)="selectAll($event, true)">
                    <mat-icon aria-hidden="true">done_all</mat-icon> {{'title_select_all' | translate}}
                  </button>
                </div>
                <div>
                  <button mat-button style="width:100%; text-align:left;" (click)="selectAll($event, false)">
                    <mat-icon aria-hidden="true">clear</mat-icon> {{'title_clear_conditions' | translate}}
                  </button>
                </div>
                <mat-divider></mat-divider>
                <mat-option *ngFor="let roleResult of selectRolesList" [value]="roleResult.value">{{roleResult.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error *ngIf="roles.touched && roles.errors?.required" style="margin-top:-30px; font-size:75%;">{{'msg_role_required' | translate}}</mat-error>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<div class="modal-footer">
  <button mat-raised-button color="primary" [disabled]="groupMemberAddForm.invalid" (click)="groupMemberAdd()">
    <mat-icon aria-hidden="true">save</mat-icon> {{'title_save' | translate}}
  </button>
  <button mat-raised-button (click)="bsModalRef.hide()">
    <mat-icon aria-hidden="true">clear</mat-icon> {{'title_cancel' | translate}}
  </button>
</div>
