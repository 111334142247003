import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetail, DeviceGetApi, Property } from '../../common/api/device';
import { Subscription } from 'rxjs';
import {ApiHandler} from '../../services/api-handler.service';

@Component({
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit, OnDestroy {
  /** 本画面で表示するデバイス情報 */
  device: DeviceDetail & {
    allProperties: Array<Property>;
  };

  private parentSubscription: Subscription;

  constructor(private route: ActivatedRoute, public apiHandler: ApiHandler) { }

  ngOnInit() {
    this.parentSubscription = this.route.parent.data.subscribe(obj => {
      // 親コンポーネントが解決したデバイス情報をそのまま使わせていただく
      const device = obj['device'] as DeviceDetail;
      this.device = {
        ...device,
        /* 機器情報プロパティと稼働情報プロパティをマージ＆ソート */
        allProperties: [...device.properties, ...device.measurements].sort((a, b) => {
          // displayOrderの昇順
          if (a.displayOrder < b.displayOrder) { return -1; }
          if (a.displayOrder > b.displayOrder) { return 1; }
          return 0;
        })
      };
    });
  }

  reload() {
    this.apiHandler.call(false, DeviceGetApi, [this.device.id], undefined, undefined, [])
      .subscribe(
        res => {
          const device = res.body as DeviceDetail;
          // 基本情報設定
          this.device.deviceId = device.deviceId;
          this.device.displayName = device.displayName;
          this.device.groupId = device.groupId;
          this.device.groupDisplayName = device.groupDisplayName;
          this.device.deviceTypeId = device.deviceTypeId;
          this.device.deviceTypeDisplayName = device.deviceTypeDisplayName;
          this.device.lastReceivedDatetime = device.lastReceivedDatetime;
          this.device.storeTimes = device.storeTimes;
          // プロパティ設定
          const allProperties = [...device.properties, ...device.measurements];
          this.device.allProperties.map(prop => {
            if (!(prop.type == "number" && prop.visualizationType == 'timeseries')) {
              const propId = prop.id;
              allProperties.forEach((newProp) => {
                if (propId == newProp.id) {
                  prop.key = newProp.key;
                  prop.value = newProp.value;
                  prop.resourceName = newProp.resourceName;
                  prop.displayName = newProp.displayName;
                  prop.unit = newProp.unit;
                  prop.type = newProp.type;
                  prop.isVisible = newProp.isVisible;
                  prop.visualizationType = newProp.visualizationType;
                  prop.defaultValue = newProp.defaultValue;
                  prop.displayOrder = newProp.displayOrder;
                  prop.measurementKey = newProp.measurementKey;
                }
              });
              return prop;
            } else {
              return prop;
            }
          });
        });
  }

  ngOnDestroy() {
    this.parentSubscription.unsubscribe();
  }
}
