import { Method } from '../../common/enums/api-enums';
import { ApiDef, ApiStatusMessage, Page } from './sip-api-client';
import { PropertyDef } from './prop-def';
import { LpwaPacketType } from './lpwa-packet';
import { AlertDef } from './alert-def';


/**
 * デバイスタイプ一覧APIの定義
 */
export class DeviceTypeListApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: {
    groupId?: number[];
    displayName?: string;
    resourceName?: string;
    communicationType?: string[];
  };
  orderableInterface: {
    groupDisplayName?: boolean;
    displayName?: boolean;
    resourceName?: boolean;
    groupId?: boolean;
    communicationType?: boolean;
  };
  responseBodyInterface: Page<DeviceType>;
  uri = '/device_types';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_completed_search' },
    { code: 400, message: 'toast_search_cond_invalid' }
  ];
}

/**
 * デバイスタイプ情報取得APIの定義
 */
export class DeviceTypeGetApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: DeviceType & {
    propertyDefs: PropertyDef[];
    alertDefs: AlertDef[];
    lpwaPacketTypes: LpwaPacketType[];
  };
  uri = '/device_types/{0}';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_completed_search' },
    { code: 400, message: 'toast_search_cond_invalid' },
    { code: 404, message: 'toast_device_type_not_found' }
  ];
}

/**
 * デバイスタイプ登録APIの定義
 */
export class DeviceTypeAddApi extends ApiDef {
  requestPayloadInterface: {
    groupId: number;
    resourceName: string;
    displayName: string;
    communicationType: CommunicationType;
    timeWindow?: number;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/device_types';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
    { code: 201, message: 'toast_registered_device_type' },
    { code: 400, message: 'toast_input_value_invalid' },
    { code: 409, message: 'toast_duplicate_device_type_key' }
  ];
}

/**
 * デバイスタイプ更新APIの定義
 */
export class DeviceTypeUpdateApi extends ApiDef {
  requestPayloadInterface: {
    displayName?: string;
    timeWindow?: number;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/device_types/{0}';
  method = Method.PUT;
  statusMsgList: ApiStatusMessage[] = [
    { code: 204, message: 'toast_updated_device_type' },
    { code: 400, message: 'toast_input_value_invalid' },
    { code: 404, message: 'toast_device_type_not_found' }
  ];
}

/**
 * デバイスタイプ削除APIの定義
 */
export class DeviceTypeDeleteApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/device_types/{0}';
  method = Method.DELETE;
  statusMsgList: ApiStatusMessage[] = [
    { code: 204, message: 'toast_deleted_device_type' },
    { code: 404, message: 'toast_device_type_not_found' }
  ];
}

export type CommunicationType = 'cellular' | 'soracom' | 'sigfox' | 'cisco' | 'naltec';
export namespace CommunicationType {
  const mapping = {
    'cellular': 'title_comtype_http',
    'soracom': 'title_comtype_soracom',
    'sigfox': 'title_comtype_sigfox',
    'cisco': 'title_comtype_cisco',
    'naltec': 'title_comtype_naltec',
  };

  export const values = (): CommunicationType[] => {
    return Object.keys(mapping) as CommunicationType[];
  };

  export const toName = (type: CommunicationType): string => {
    const name = mapping[type];
    if (name == null) {
      throw new Error(`Invalid communication type: ${type}.`);
    }
    return name;
  };

  /** LPWA系の通信方式か否か */
  export const isLpwa = (type: CommunicationType): boolean => {
    return ['soracom', 'sigfox', 'cisco', 'naltec'].includes(type);
  };
}

export interface DeviceType {
  id: number;
  groupId: number;
  resourceName: string;
  communicationType: CommunicationType;
  timeWindow: number;
  displayName: string;
  groupDisplayName: string;
}
