import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'property_type'
})
export class PropertyTypePipe implements PipeTransform {

  transform(type: string): string {
    let name: string;
    if (type) {
      if (type === 'string') {
        name = 'title_string';
      } else if (type === 'number') {
        name = 'title_number';
      } else if (type === 'boolen') {
        name = 'title_boolean';
      } else if (type === 'latlng') {
        name = 'title_latlng';
      }
    }
    return name;
  }
}
