<div class="row">
  <div class="col-md-8 col-md-offset-2">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h3 class="panel-title">プロフィール</h3>
      </div>
      <div class="panel-body">
        <form class="form-horizontal">
          <div class="form-group">
            <label class="col-md-4 control-label">メールアドレス</label>
            <div class="col-md-6">
              <div class="form-control">{{ user.email }}</div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">氏名</label>
            <div class="col-md-6">
              <div class="form-control">{{ user.name }}</div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">登録日時</label>
            <div class="col-md-6">
              <div class="form-control">{{ user.created_at }}</div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">最終更新日時</label>
            <div class="col-md-6">
              <div class="form-control">{{ user.updated_at }}</div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
