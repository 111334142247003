<ng-container [formGroup]="packetDecodeForm">
  <td>
    <mat-form-field *ngIf="editable" class="compact-field">
      <input matInput type="number" name="start_bit" [formControl]="startBit" required>
      <mat-hint align="end">{{'p_holder_0_or_greater' | translate}}</mat-hint>
      <ng-container *ngIf="startBit.touched && startBit.errors" ngProjectAs="mat-error">
        <mat-error *ngIf="startBit.errors.required">{{'msg_start_byte_required' | translate}}</mat-error>
        <mat-error *ngIf="startBit.errors.min">{{'msg_start_byte_must_be_0_or_greater' | translate}}</mat-error>
        <mat-error *ngIf="startBit.errors.digits">{{'msg_input_integer' | translate}}</mat-error>
      </ng-container>
    </mat-form-field>
    <span *ngIf="!editable">{{packetDecode.startBit}}</span>
  </td>
  <td>
    <mat-form-field *ngIf="editable" class="compact-field">
      <mat-select name="dataType" [formControl]="dataType" required>
        <mat-option></mat-option>
        <mat-option *ngFor="let dataTypeOption of dataTypeOptions" [value]="dataTypeOption">{{toDataTypeName(dataTypeOption) | translate}}</mat-option>
      </mat-select>
      <ng-container *ngIf="dataType.touched && dataType.errors" ngProjectAs="mat-error">
        <mat-error *ngIf="dataType.errors.required">{{'msg_data_type_required' | translate}}</mat-error>
      </ng-container>
    </mat-form-field>
    <span *ngIf="!editable">{{toDataTypeName(packetDecode.dataType)}}</span>
  </td>
  <td>
    <mat-form-field *ngIf="editable" class="compact-field">
      <input matInput type="number" name="data_bit_length" [formControl]="dataBitLength" [errorStateMatcher]="byteRangeStateMatcher" required>
      <mat-hint align="end">{{'msg_editable_for_string' | translate}}</mat-hint>
      <ng-container *ngIf="dataBitLength.touched && dataBitLength.errors" ngProjectAs="mat-error">
        <mat-error *ngIf="dataBitLength.errors.required">{{'msg_byte_length_required' | translate}}</mat-error>
        <mat-error *ngIf="dataBitLength.errors.min">{{'msg_byte_length_must_be_1_or_greater' | translate}}</mat-error>
        <mat-error *ngIf="dataBitLength.errors.digits">{{'msg_input_integer' | translate}}</mat-error>
      </ng-container>
      <!-- 複合バリデーションなので別枠 -->
      <mat-error *ngIf="packetDecodeForm.touched && packetDecodeForm.hasError('byteRange')">{{'msg_byte_range_overlap' | translate}}</mat-error>
    </mat-form-field>
    <span *ngIf="!editable">{{packetDecode.dataBitLength}}</span>
  </td>
  <td>
    <mat-form-field *ngIf="editable" class="compact-field">
      <mat-select name="property_def_id" [formControl]="propertyDefId" required>
        <mat-option></mat-option>
        <mat-option *ngFor="let propDef of propertyDefOptions" [value]="propDef.id">{{propDef.displayName}}</mat-option>
      </mat-select>
      <ng-container *ngIf="propertyDefId.touched && propertyDefId.errors" ngProjectAs="mat-error">
        <mat-error *ngIf="propertyDefId.errors.required">{{'msg_corresponding_prop_required' | translate}}</mat-error>
        <mat-error *ngIf="propertyDefId.errors.propertyDuplication">{{'msg_corresponding_duplicated' | translate}}</mat-error>
      </ng-container>
    </mat-form-field>
    <span *ngIf="!editable">{{toPropertyDefName(packetDecode.propertyDefId)}}</span>
  </td>
  <td>
    <button mat-icon-button color="primary" type="button" class="icon-btn" matTooltip="{{'title_save' | translate}}" [disabled]="!editable || packetDecodeForm.invalid || !packetDecodeForm.dirty"
      (click)="saveLpwaPacketDecode()">
      <mat-icon aria-hidden="true">save</mat-icon>
    </button>
    <button mat-icon-button *ngIf="editMode" type="button" class="icon-btn" matTooltip="{{'title_delete' | translate}}" [disabled]="!editable" (click)="deleteLpwaPacketDecode()">
      <mat-icon aria-hidden="true">delete</mat-icon>
    </button>
    <button mat-icon-button *ngIf="!editMode" type="button" class="icon-btn" matTooltip="{{'title_cancel' | translate}}" [disabled]="!editable" (click)="onCancel()">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </td>
</ng-container>
