import { Component, OnInit, ViewChild, TemplateRef, } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiPagenation } from '../common/api/sip-api-client';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GroupsGetApi } from '../common/api/group';
import { GroupsAddComponent } from './modal/groups-add.component';
import { WebStorageAuthRepository } from '../services/auth-repository.service';
import { Root } from '../common/api/auth';
import { ApiHandler } from '../services/api-handler.service';
import {PageableSearchCondition} from '../common/view/PageableSearchCondition';
import { SelectOption　} from '../common/interfaces/display-interfaces';

@Component({
  moduleId: module.id,
  templateUrl: 'groups.component.html',
  providers: [GroupsGetApi]
})
export class GroupsComponent implements OnInit {
  id = '';
  display_name = '';
  root_id = '';
  root_display_name = '';
  message = '';
  rootInfo: Root[];
  isNoData: boolean = false;

  constructor(
    public t: TranslateService,
    public groupsGetApi: GroupsGetApi,
    public apiHandler: ApiHandler,
    private modalService: BsModalService,
    private authRepository: WebStorageAuthRepository,
  ) { }

  // interfaceModel
  groupsresults: Array<{
    id: Number,               // グループユニークID
    rootId: number,          // ルートユニークID
    displayName: string,     // グループ名
    rootDisplayName: string // 所属ルート
  } & Checkable> = [];

  status: number;

  // Pagination
  count: Observable<number>; // 全件数
  next: string;              // 次ページURL
  previous: string;          // 前ページURL
  itemsPerPage = PageableSearchCondition.DEFAULT_PAGE_SIZE;
  currentPage = 1;
  smallnumPages = 0;
  searchPage = 1;
  rootGroupName: string;  // ログインユーザが所属しているルートグループ

  /** 追加ボタン活性状態 */
  get canAdd(): boolean {
    // ログインユーザが1つ以上のルートに所属している場合trueを返す
    // グループ一覧APIで所属ルートが取得できる
    if (this.rootGroupName.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  columnList = [
    { seq: 1, id: 'displayName', displayName: 'title_group_name', issort: false },
    { seq: 2, id: 'rootDisplayName', displayName: 'title_root_of_group', issort: false }
  ];

  /**
   * 照会キーセット
   */
  setCondParams(): URLSearchParams {
    const params = new URLSearchParams();
    if (this.display_name !== '') { params.set('display_name', this.display_name); }
    if (this.root_display_name !== '') { params.set('root_display_name', this.root_display_name); }

    // page
    params.set('page', String(this.searchPage));
    return params;
  }

  /**
   * ページ遷移
   */
  pageChanged(event: any): void {
    this.searchPage = event.page;
    this.searchGroups();
  }

  /**
   * グループ一覧取得
   */
  searchGroups() {
    const pagination: ApiPagenation = {
      page: this.searchPage,
      pageSize: this.itemsPerPage,
    };
    this.rootGroupName = '';
    const groupsObservable = this.apiHandler.call(false, GroupsGetApi, [], {}, {}, [], pagination);
    groupsObservable
      .subscribe(
        res => {
          const groups = res.body;
          this.count = Observable.create(
            observer => {
              observer.next(groups.count);
              observer.complete();
            });
          this.next = groups.next;
          this.previous = groups.previous;
          this.groupsresults = groups.results.map(type => {
            return {
              isChecked: false, // チェックボックスなし
              ...type
            };
          });
          if (this.groupsresults.length === 0) {
            this.isNoData = true;
          } else {
            this.isNoData = false;
          }

          // ログインユーザ所属のルートグループを取得
          if (groups.results.length > 0) {
            this.rootGroupName = groups.results[0].rootDisplayName;
          }
        },
        error => console.log(error)
      );
  }

  /**
   * グループ登録画面をモーダルウィンドウで開く。
   */
  openGroupsAdd() {
    const modalRef = this.modalService.show(GroupsAddComponent, { class: 'modal-dialog modal-lg', backdrop: 'static' });
    // DropDown
    modalRef.content.groupRoots = this.authRepository.roots.map(
      root => {
        return {
          value: root.id, name: root.displayName
        };
      }
    );

    // モーダルを閉じたときに登録完了フラグONの場合に再取得する。
    this.modalService.onHide.subscribe(() => {
      if (modalRef.content.isOk) {
        this.searchGroups();
        modalRef.content.isOk = false;
      }
    });
  }

  /**
   * グループ名リンク表示/非表示判定処理
   */
  private isLinkShow(groupId: number): boolean {
    // グループ閲覧権限がない時はグループ名をリンクにしない
    return this.authRepository.hasPermission('group', 'view', groupId);
  }

  /**
* 初期化処理
*/
  ngOnInit(): void {
    // グループ取得
    this.searchGroups();
  }

}

interface Checkable {
  isChecked: boolean;
}
