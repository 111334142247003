import { Component } from '@angular/core';
import { ApiHandler } from '../services/api-handler.service';
import { UserModel } from '../models/user.model';

@Component({
  moduleId: module.id,
  templateUrl: 'user-info.component.html',
  styleUrls: ['user-info.component.css']
})
export class UserInfoComponent {
  user: UserModel;

  /**
   * コンストラクタ
   * @param apiHandler 認証サービス
   */
  constructor(protected apiHandler: ApiHandler) {
    this.user = apiHandler.user;
  }
}
