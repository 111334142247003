import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResourceNameDirective } from '../../common/validation/resource-name.directive';
import { DeviceTypeAddApi } from '../../common/api/device-type';
import { ApiHandler } from '../../services/api-handler.service';
import { SelectOption } from '../../common/interfaces/display-interfaces';

@Component({
  moduleId: module.id,
  templateUrl: 'device-type-add.component.html'
})
export class DeviceTypeAddComponent implements OnInit {
  readonly title = 'デバイスタイプ登録';
  // DropDown
  groupOptions: SelectOption[] = [];
  communicationTypeOptions: SelectOption[] = [];
  message: string;
  isOk = false;

  // 入力フォーム
  group = new FormControl(null, Validators.required);
  deviceTypeId = new FormControl('', [
    Validators.required,
    new ResourceNameDirective().validate,
    Validators.maxLength(50)
  ]);
  displayName = new FormControl('', [
    Validators.required,
    Validators.maxLength(50)
  ]);
  communicationType = new FormControl('', Validators.required);
  timeWindow = new FormControl(null, [
    Validators.min(0),
    Validators.max(99999999),
    Validators.pattern(/^[-+]?(?:[1-9][0-9]*|0)$/)
  ]);

  deviceTypeForm = this.builder.group({
    groupId: this.group,  // キー名がgroupIdなのに注意
    resourceName: this.deviceTypeId,  // キー名がresourceNameなのに注意
    displayName: this.displayName,
    communicationType: this.communicationType,
    timeWindow: this.timeWindow
  });

  constructor(public t: TranslateService, public bsModalRef: BsModalRef, public apiHandler: ApiHandler, private builder: FormBuilder) { }

  /**
   * デバイスタイプ登録
   */
  deviceTypeAdd() {
    const entity = this.deviceTypeForm.value;
    this.apiHandler.call(true, DeviceTypeAddApi, [], entity)
      .subscribe(res => {
        if (!res.hasError) {
          // 登録成功の場合は登録完了フラグをONにして画面を閉じる
          this.isOk = true;
          this.bsModalRef.hide();
        }
      });
  }

  ngOnInit() {
    this.message = '';
  }

}
