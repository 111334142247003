import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiHandler } from '../../services/api-handler.service';
import { ActivatedRoute } from '@angular/router';
import { SelectOption　} from '../../common/interfaces/display-interfaces';
import { GroupInfoGetApi, GroupMemberUpdateApi, GroupRole } from '../../common/api/group';

@Component({
  moduleId: module.id,
  templateUrl: 'group-member-update.component.html',
  providers: [GroupMemberUpdateApi]
})

export class GroupMemberUpdateComponent implements OnInit {
  title = '';
  isOk = false;
  groupMemberUpdateForm: FormGroup;
  groupInfo: GroupInfoGetApi['responseBodyInterface'];
  name = '';
  roles: FormControl;
  groupId: number;
  userId: number;
  selecteddropdownRoles: number[] = [];
  selectRolesList: any[] = [];
  idList: number[] = [];

  public initGroupMemberUpdateModal(isAdd: boolean, title: string, dropRole: SelectOption, groupId: number,
    roles: SelectOption[], name: string, memberRoles: GroupRole[], userId: number) {
    this.title = title;
    this.groupId = groupId;
    // 名前
    this.name = name;
    // ユーザID
    this.userId = userId;

    // 選択肢作成
    for (let i = 0; i < roles.length; i++) {
      this.selectRolesList.push({ value: roles[i].value, name: roles[i].name });
      this.idList.push(roles[i].value);
    }
    
    // メンバーのロール情報を選択状態にする
    const checkedList: number[] = [];
    for (let k = 0; k < memberRoles.length; k++) {
      checkedList.push(memberRoles[k].id);
    }
    this.roles.setValue(checkedList);
  }

  /**
   * メンバー更新
   */
  groupMemberUpdate() {
    const entity = this.toGroupMemberUpdateEntity();
    // ローカル環境で確認するとcode:500が返ってくるが、適切な環境であれば登録処理が正常終了している
    this.apiHandler.call(true, GroupMemberUpdateApi, [this.groupId, this.userId], entity, {}, [], {})
      .subscribe(
        res => {
          // 登録成功の場合は登録完了フラグONにして画面を閉じる
          this.isOk = true;
          this.bsModalRef.hide();
        });
  }

  // 入力フォームの入力をエンティティに変換する
  private toGroupMemberUpdateEntity(): GroupMemberUpdateApi['requestPayloadInterface'] {
    const selectedRoles = this.selecteddropdownRoles;
    return {
      roles: selectedRoles,
    };
  }

  constructor(
    public t: TranslateService,
    private route: ActivatedRoute,
    public bsModalRef: BsModalRef,
    public apiHandler: ApiHandler,
    private builder: FormBuilder,
  ) { }

  ngOnInit() {
    // リゾルバー経由で取得したデータ
    this.route.data.subscribe(obj => {
      this.groupInfo = obj['groups'];
    });

    this.roles = new FormControl('', [
      Validators.required,
    ]);

    this.groupMemberUpdateForm = new FormGroup({
      roles: new FormControl(),
    });

    this.groupMemberUpdateForm = this.builder.group({
      rolea: this.roles,
    });
  }

  /**
   * コンボボックス 全て選択・選択解除
   */
  selectAll($event: any[], isSelectAll: boolean) {
    if (isSelectAll) {
      // 全て選択
      this.roles.setValue(this.idList);
    } else {
      // 全て選択解除
      this.roles.setValue('');
    }
  }
}
