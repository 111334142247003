import { Method } from '../../common/enums/api-enums';
import { ApiDef, ApiStatusMessage, Page } from './sip-api-client';
import { PropertyDef } from './prop-def';
import { LpwaPacketType } from './lpwa-packet';
import { IconDef } from '../interfaces/display-interfaces';
import { DeviceAlertDef } from './alert-def';


/**
 * デバイス一覧取得APIの定義
 */
export class DeviceListApi extends ApiDef {
    requestPayloadInterface: undefined;
    queryInterface: {
        displayName?: string;
        deviceId?: string;
        groupId?: number[];
        deviceTypeId?: number[];
        hasAlert?: boolean;
        deviceGroupId?: number[];
    };
    orderableInterface: {
        groupDisplayName?: boolean;
        deviceTypeDisplayName?: boolean;
        displayName?: boolean;
        groupId?: boolean;
        deviceId?: boolean;
        lastReceivedDatetime?: boolean;
        deviceGroupDisplayName?: boolean;
    };
    responseBodyInterface: Page<Device>;
    uri = '/devices';
    method = Method.GET;
    statusMsgList: ApiStatusMessage[] = [
        { code: 200, message: 'toast_completed_search' },
        { code: 400, message: 'toast_search_cond_invalid' }
    ];
}

/**
 * デバイス情報取得APIの定義
 */
export class DeviceGetApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: DeviceDetail;
  uri = '/devices/{0}';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
      { code: 200, message: 'toast_completed_search' },
      { code: 400, message: 'toast_search_cond_invalid' },
      { code: 404, message: 'toast_device_not_found' }
  ];
}

/**
 * デバイス登録APIの定義
 */
export class DeviceAddApi extends ApiDef {
  requestPayloadInterface: {
    deviceId: string;
    deviceTypeId: number;
    displayName: string;
    storeTimes: number;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/devices';
  method = Method.POST;
  statusMsgList: ApiStatusMessage[] = [
      { code: 201, message: 'toast_registered_device' },
      { code: 400, message: 'toast_input_value_invalid' },
      { code: 409, message: 'toast_duplicate_device_id' }
  ];
}

/**
 * デバイス更新APIの定義
 */
export class DeviceUpdateApi extends ApiDef {
  requestPayloadInterface: {
    displayName?: string;
    properties?: Array<{ id: number, value: string }>;
  };
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/devices/{0}';
  method = Method.PUT;
  statusMsgList: ApiStatusMessage[] = [
      { code: 204, message: 'toast_updated_device' },
      { code: 400, message: 'toast_input_value_invalid' },
      { code: 404, message: 'toast_device_not_found' }
  ];
}

/**
 * デバイス削除APIの定義
 */
export class DeviceDeleteApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: undefined;
  uri = '/devices/{0}';
  method = Method.DELETE;
  statusMsgList: ApiStatusMessage[] = [
      { code: 204, message: 'toast_deleted_device' },
      { code: 404, message: 'toast_device_not_found' }
  ];
}

/**
 * 計測データ一覧取得APIの定義
 */
export class MeasurementListApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: {
      start?: Date;
      end?: Date;
      fields?: string[];
      cursor?: string;
      prev?: string;
  };
  orderableInterface: {
    datetime?: boolean
  };
  responseBodyInterface: {
    next: string;
    previous: string;
    results: {
      propDefs: Array<PropDef>;
      data: Array<MeasurementData>;
    }
  };
  uri = '/devices/{0}/measurements';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
      { code: 200, message: 'toast_completed_search' },
      { code: 400, message: 'toast_search_cond_invalid' },
      { code: 404, message: 'toast_device_not_found' }
  ];
}

export interface Device {
  id: number;
  deviceId: string;
  displayName: string;
  groupId: number;
  groupDisplayName: string;
  deviceTypeId: number;
  deviceTypeDisplayName: string;
  hasAlert: boolean;
  lastReceivedDatetime: string;
  isSubscribed: boolean;
  isMuted: boolean;
  status: boolean;
  storeTimes: number;
  sshActive: boolean;
  deviceGroupDisplayName: string;
  connectionDestinationUrl: string;
}

export type DeviceDetail = Device & {
  properties: Array<Property>;
  measurements: Array<Property>;
  alertDefs: Array<DeviceAlertDef>;
};

export type Property = PropertyDef & {
  key: string;  // measurementKey と同じ役割だが、キー名が異なる
  value: string;
};

/** アラート有無 */
export type HasAlert = boolean;
export namespace HasAlert {
  export const toIcon = (status: HasAlert): IconDef => {
    return status ?
     { label: 'title_has_alerts', name: 'warning', color: 'warn' } :
     { label: 'title_no_alerts', name: 'check_circle', color: 'accent' };
  };
}

export interface PropDef {
  propertyResourceName: string;
  displayName: string;
  unit: string;
  pre: string;
}

export interface MeasurementData {
  datetime: string;
  value: Array<PropVal>;
}

export interface PropVal {
  val: string;
  type: string;
}

