import { Method } from '../../common/enums/api-enums';
import { ApiDef, ApiStatusMessage, Page } from './sip-api-client';
import { IconDef } from '../interfaces/display-interfaces';

/**
 * アラート記録一覧APIの定義
 */
export class AlertLogListApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: {
    start?: string;
    end?: string;
    deviceTypeId?: number[];
    device?: number[];
    groupId?: number[];
    deviceId?: string;
    deviceDisplayName?: string;
    isActive?: boolean;
    alertTitle?: string;
  };
  orderableInterface: {
    start?: boolean;
    deviceDisplayName?: boolean;
    title?: boolean;
    deviceId?: boolean;
    end?: boolean;
  };
  responseBodyInterface: Page<AlertLogForList>;
  uri = '/alert_logs';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_completed_search' },
    { code: 400, message: 'toast_search_cond_invalid' }
  ];
}

/**
 * アラート記録情報取得APIの定義
 */
export class AlertLogGetApi extends ApiDef {
  requestPayloadInterface: undefined;
  queryInterface: undefined;
  orderableInterface: undefined;
  responseBodyInterface: AlertLog;
  uri = '/alert_logs/{0}';
  method = Method.GET;
  statusMsgList: ApiStatusMessage[] = [
    { code: 200, message: 'toast_completed_search' },
    { code: 400, message: 'toast_search_cond_invalid' },
    { code: 404, message: 'toast_alert_log_not_found' }
  ];
}


export type AlertActiveStatus = boolean;
export namespace AlertActiveStatus {
  export const toName = (status: boolean): string => {
    return status ? 'title_ongoing' : 'title_terminated';
  };
  export const toIcon = (status: AlertActiveStatus): IconDef => {
    return status ? { name: 'warning', color: 'warn' } : { name: 'check_circle', color: 'accent' };
  };
}

export type MailSendStatus = 'sent' | 'yet' | 'never';
export namespace MailSendStatus {
  const mapping = {
    'sent': 'title_sent',
    'yet': 'title_yet',
    'never': 'title_never',
  };

  const iconMapping = {
    'sent': { name: 'check_circle', color: 'accent' },
    'yet': { name: 'hourglass_full', color: 'foreground' },
    'never': { name: 'do_not_disturb', color: 'warn' },
  };

  export const values = (): MailSendStatus[] => {
    return Object.keys(mapping) as MailSendStatus[];
  };

  export const toName = (type: MailSendStatus): string => {
    const name = mapping[type];
    if (name == null) {
      throw new Error(`Invalid mail send status: ${type}.`);
    }
    return name;
  };
  export const toIcon = (type: MailSendStatus): IconDef => {
    const icon = <IconDef>iconMapping[type];
    if (icon == null) {
      throw new Error(`Invalid mail send status: ${type}.`);
    }
    return icon;
  };
}

export interface AlertLogForList {
  id: number;
  deviceId: number;
  deviceDeviceId: string;
  deviceDisplayName: string;
  startDatetime: Date;
  endDatetime: Date;
  title: string;
  isMailSent: MailSendStatus;
  isActive: boolean;
}

export interface AlertLog {
  id: number;
  deviceId: number;
  deviceDeviceId: string;
  deviceDisplayName: string;
  startDatetime: Date;
  endDatetime: Date;
  title: string;
  detail: string;
  isMailSent: MailSendStatus;
  isActive: boolean;
}
