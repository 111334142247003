import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DeviceOutputComponent} from './device-output.component';
import { AuthGuard } from '../services/auth-guard.service';
import { ApiHandler } from '../services/api-handler.service';

const routes: Routes = [
  {
    path: 'alert_output/:alert_output_id',
    component: DeviceOutputComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'device_output',
    component: DeviceOutputComponent,
    canActivate: [ AuthGuard ],
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes)],
  exports: [ RouterModule ]
})
export class DeviceOutputRoutingModule { }
