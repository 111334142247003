import { Injectable } from "@angular/core";
declare var toastr: any;

@Injectable()
export class Functions {
  // ソート条件をクエリパラメタにセットする形式に変換する。
  orderingConv(Ordering: string): string {
    let strOrdering = '';
    if (Ordering === 'desc') { strOrdering = '-'; }
    return strOrdering;
  }

  // 日付と時刻を結合して日時として返す
  concatDateAndTime(date: Date, time: Date): Date {
    if (!date && !time) { return undefined; }
    // 指定されている方を日時として返す
    if (!date) { return time; }
    if (!time) { return date; }

    // 日付に時刻をセット
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(time.getSeconds());

    return date;
  }
  // 座標(latlng)型チェック
  isLatlng(latlng: string): boolean {
    // 値があるか
    if (!latlng) { return false; }
    // カンマで区切られているか
    if (latlng.indexOf(',') === -1) { return false; }
    // 数値か
    const strLatlng = latlng.split(',');
    for (let i = 0; i <= strLatlng.length - 1; i++) {
      const num = strLatlng[i];
      // isNaN=trueは数値でない
      if (isNaN(+num)) { return false; }
    }
    return true;
  }

  // toastメッセージ生成
  showToastMessage(message: string, type: string) {
    // closeボタン
    toastr.options.closeButton = true;
    // 新しいメッセージは下に追加
    toastr.options.newestOnTop = false;
    // 表示する時間(msec)
    toastr.options.timeOut = 3000;
    toastr.options.extendedTimeOut = 5000;
    // 表示場所
    toastr.options.positionClass = 'toast-top-center';
    if (type === 'success') { toastr.success(message); }
    if (type === 'error') { toastr.error(message); }
    if (type === 'warning') { toastr.warning(message); }
    if (type === 'info') { toastr.info(message); }
  }
}
