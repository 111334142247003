import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Functions } from '../common/functions';
import { DeviceGroupDetailComponent } from './device-groupdetail.component';
import { DeviceGroupDetailRoutingModule } from './device-groupdetail-routing.modules';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PartsComponentModule } from '../parts/parts-component.module';
import { AppMaterialModule } from '../common/app-material.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  imports: [
    DeviceGroupDetailRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PartsComponentModule,
    AppMaterialModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TranslateModule,
  ],
  providers: [
    Functions,
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
  ],
  declarations: [
    DeviceGroupDetailComponent
  ],
  bootstrap: [
    DeviceGroupDetailComponent,
  ],
  entryComponents: [
  ]
})

export class DeviceGroupDetailModule { }
