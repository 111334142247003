import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../services/data.service';
import { Functions } from '../common/functions';


@Component({
  moduleId: module.id,
  templateUrl: 'user-password.component.html',
  styleUrls: ['user-password.component.css']
})
export class UserPasswordComponent implements OnInit {

  PWD_PTN = { pattern: '[-_@+*;:#$%&0-9a-zA-Z]+', display: 'p_holder_pwd_ptn' };
  old_password = '';
  new_password = '';
  new_password_conf = '';
  passwordForm: FormGroup;
  passwordValidPtn: string;
  passwordValidDisp: string;
  /**
   * 初期処理
   */
  constructor(
    public t: TranslateService,
    private dataService: DataService,
    private functions: Functions,
    private formbuiler: FormBuilder
  ) { }
  /**
   * ステータススイッチ
   * 将来的にはAPIハンドラーを使うので削除予定
   * @param {any} res : レスポンス
   */
  statusCheck(res) {
    let message = '';
    // 正常系か異常系かを切り替え
    if (200 <= res.status && res.status < 300) {
      // 正常系メッセージ
      message = this.t.instant('toast_password_change_success');
      this.functions.showToastMessage(message, 'success');
    } else if (res.status === 401) {
      // 401タイムアウトはアラートは出さない
    } else {
      // 異常系メッセージ
      message = this.t.instant('toast_current_password_invalid');
      this.functions.showToastMessage(message, 'error');
    }
  }
  /**
   * パスワード変更
   */
  changePassword() {
    // 入力値をセット
    const changepassword = { new_password: this.new_password, current_password: this.old_password };

    const url = '/api/user_profile/password';
    const changePassObservable = this.dataService.putData(url, changepassword);
    changePassObservable
      .subscribe(
        res => {
          // 画面を初期化
          this.ngOnInit();
          this.statusCheck(res);
        },
        error => {
          // 画面を初期化
          this.ngOnInit();
          this.statusCheck(error);
        }
      );
  }

  onClick() {
    this.changePassword();
  }

  // 新パスワードを消したとき(確認)もクリアする
  valuechange() {
    this.new_password_conf = '';
  }
  /**
  * 初期化処理
  */
  ngOnInit(): void {
    // フォームバリデーションチェック用定義
    this.passwordValidPtn = this.PWD_PTN['pattern'];
    this.passwordValidDisp = this.PWD_PTN['display'];
    this.passwordForm = this.formbuiler.group({
      'old_password': ['', Validators.compose([
        Validators.required])],
      'new_password': ['', Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(64),
        Validators.pattern(this.passwordValidPtn)])],
      'new_password_conf': ['', Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(64),
        Validators.pattern(this.passwordValidPtn)])]
    });
  }
}
