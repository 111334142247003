import { OnInit, Input, Directive } from '@angular/core';
import { AlertDef, AlertType } from '../../common/api/alert-def';
import { PropertyDef, PropType } from '../../common/api/prop-def';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RouterService } from '../../services/router.service';
import { ApiHandler } from '../../services/api-handler.service';
import { DialogService } from '../../services/dialog.service';
import { CustomValidators } from 'ng2-validation';
import { Observable } from 'rxjs';
import { ColumnDefinition } from '../../common/interfaces/display-interfaces';
import { TranslateService } from '@ngx-translate/core';

/**
 * 1つのアラート定義の表示、編集に関するコンポーネントのベースクラス。
 *
 * デバイスタイプのアラート定義とデバイスの個別アラート定義の両方で
 * 必要な共通機能を括りだしている。
 *
 * saveメソッド、deleteメソッドはサブクラスで個別に実装する。
 */
@Directive()
export abstract class BaseAlertDefEditComponent implements OnInit {
  /** 本コンポーネントで表示するアラート定義 */
  @Input() alertDef: AlertDef;
  /** 条件式のヘルプに表示するプロパティ定義 */
  @Input() propertyDefs: PropertyDef[] = [];
  /** コンポーネントを活性を制御する。非活性ならtrue */
  @Input() disabled: boolean;

  /** 編集モード
   * trueなら更新モード、falseなら新規登録モード。
   */
  editMode: boolean;

  /* 入力フォーム */
  title: FormControl;
  detail: FormControl;
  type: FormControl;
  conditions: FormControl;
  pingInterval: FormControl;
  suppressInterval: FormControl;

  alertDefForm: FormGroup;


  alertTypeOptions = AlertType.values().map(type => {
    return { value: type, name: AlertType.toName(type) };
  });

  /** 条件式ヘルプに表示するプロパティ定義表のカラム一覧 */
  readonly columnList: ColumnDefinition[] = [
    { seq: 1, id: 'resourceName', displayName: 'title_resource_name' },
    { seq: 2, id: 'displayName', displayName: 'title_prop_name' },
    { seq: 3, id: 'type', displayName: 'title_prop_type', converter: PropType.toName },
  ];

  constructor(
    protected t: TranslateService,
    protected routerService: RouterService,
    protected builder: FormBuilder,
    protected apiHandler: ApiHandler,
    protected dialogService: DialogService
  ) {
  }

  ngOnInit(): void {
    if (this.alertDef == null) {
      this.editMode = false;
      this.alertDef = {
        id: null,
        title: this.t.instant('title_new_alert_title'),
        detail: null,
        type: null,
        conditions: null,
        pingInterval: null,
        suppressInterval: null
      };
    } else {
      this.editMode = true;
    }
    /* 入力フォームの初期化 */
    this.title = new FormControl(this.alertDef.title, [
      Validators.required,
      Validators.maxLength(50)
    ]);
    this.detail = new FormControl(this.alertDef.detail, Validators.required);
    this.type = new FormControl(this.alertDef.type, Validators.required);
    this.conditions = new FormControl({
      value: this.alertDef.conditions,
      disabled: this.alertDef.type === 'ping'  // アラート種別に応じた活性制御
    }, Validators.required);
    this.pingInterval = new FormControl({
      value: this.alertDef.pingInterval,
      disabled: this.alertDef.type === 'condition'  // アラート種別に応じた活性制御
    }, [
        Validators.required,
        CustomValidators.digits
      ]);
    this.suppressInterval = new FormControl(this.alertDef.suppressInterval, CustomValidators.digits);
    this.alertDefForm = this.builder.group({
      title: this.title,
      detail: this.detail,
      type: this.type,
      conditions: this.conditions,
      pingInterval: this.pingInterval,
      suppressInterval: this.suppressInterval
    });

  }

  abstract save(): void;
  abstract delete(): void;

  /** アラート種別に応じて他の入力コントロールの活性状態を制御する */
  onChangeType(type: AlertType) {
    switch (type) {
      case 'ping':
        this.conditions.disable();
        this.pingInterval.enable();
        this.pingInterval.markAsDirty();  // 即座にエラーメッセージを表示させるため
        break;
      case 'condition':
        this.pingInterval.disable();
        this.conditions.enable();
        this.conditions.markAsDirty();  // 即座にエラーメッセージを表示させるため
        break;
    }
  }

  /** 条件の末尾にプロパティのリソース名を挿入する */
  appendResourceName(resourceName: string) {
    if (this.conditions.disabled) {
      return;  // 非活性なら何もしない
    }
    const newValue = (this.conditions.value || '') + '$' + resourceName + '$';
    this.conditions.setValue(newValue);
    this.conditions.markAsTouched();
    this.conditions.markAsDirty();
  }
}
