<div class="row">
  <div class="col-sm-10">
    <h4>{{'title_alert_edit' | translate}}</h4>
  </div>
  <div [hidden]="isAdding" class="col-sm-2 text-right">
    <button mat-raised-button color="primary" [disabled]="!canEdit" (click)="isAdding=true">
      <mat-icon aria-hidden="true">add</mat-icon> {{'title_add' | translate}}
    </button>
  </div>
  <div [hidden]="!isAdding" class="col-sm-2 text-right">
    <button mat-raised-button [disabled]="!canEdit" (click)="isAdding=false">
      <mat-icon aria-hidden="true">clear</mat-icon> {{'title_cancel' | translate}}
    </button>
  </div>
  <ng-container *ngIf="deviceType$ | async as deviceType">
    <div *ngIf="device.alertDefs.length === 0" class="col-sm-12">
      <ng-container *ngIf="deviceType.alertDefs.length !== 0; else noAlertDefs">
        <app-note role="info">{{'msg_no_spec_alerts' | translate}}</app-note>
      </ng-container>
      <ng-template #noAlertDefs>
        <app-note role="info">{{'msg_no_alerts' | translate}}</app-note>
      </ng-template>
    </div>
    <app-device-alert-def-edit *ngIf="isAdding" [deviceId]="device.id" [propertyDefs]="deviceType.propertyDefs" [disabled]="!canEdit"></app-device-alert-def-edit>
    <ng-container *ngIf="device.alertDefs.length === 0; else deviceAlertDefs">
      <!-- 個別アラート定義がない場合はデバイスタイプのアラート定義を読み取り専用で列挙する -->
      <div *ngFor="let alertDef of deviceType.alertDefs" class="col-sm-12">
        <app-alert-def-edit [alertDef]="alertDef" [deviceTypeId]="deviceType.id" [propertyDefs]="deviceType.propertyDefs" [disabled]="true"></app-alert-def-edit>
      </div>
    </ng-container>
    <ng-template #deviceAlertDefs>
      <!-- 個別アラート定義があれば個別アラート定義のみ列挙する -->
      <div *ngFor="let alertDef of device.alertDefs" class="col-sm-12">
        <app-device-alert-def-edit [alertDef]="alertDef" [deviceId]="device.id" [propertyDefs]="deviceType.propertyDefs" [disabled]="!canEdit"></app-device-alert-def-edit>
      </div>
    </ng-template>
  </ng-container>
</div>
