import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {

  @Input() role: 'error' | 'info';

  constructor() { }

  ngOnInit() {
  }

  get roleError(): boolean {
    return this.role === 'error';
  }

  get roleInfo(): boolean {
    return this.role === 'info';
  }

  get icon(): string {
    switch (this.role) {
      case 'error':
        return 'error';
      case 'info':
        return 'info';
    }
  }

}
