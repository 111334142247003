import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmComponent } from '../common/messages/confirm.component';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class DialogService {
  constructor(private modalService: BsModalService) {
  }

  /**
   * 確認を求めるダイアログを表示する。
   *
   * @param title 画面タイトル
   * @param head ダイアログ内に表示するテキスト
   * @param detail 詳細説明するテキストのリスト
   * @returns 確認ダイアログを閉じたときに発火するイベント。
   *   OKボタンを押下して閉じた場合は true がイベントオブジェクトになる。
   *   キャンセルボタンを押下して閉じた場合は false がイベントオブジェクトになる。
   */
  show(title: string, head: string, detail?: Iterable<{message: string}>): Observable<boolean> {
    const modalRef = this.modalService.show(ConfirmComponent, { backdrop: 'static' });
    const dialog = <ConfirmComponent>modalRef.content;
    dialog.title = title;
    dialog.messageHead = head;
    dialog.messageDetail = detail;
    return dialog.onClose;
  }
}
