<div class="modal-header">
  <h4 class="modal-title pull-left">{{'title_device_type_registration' | translate}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="panel panel-default">
    <div class="panel-body">
      <form [formGroup]="deviceTypeForm">
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <mat-select name="group" [formControl]="group" placeholder="{{'title_group' | translate}}" required>
                <mat-option></mat-option>
                <mat-option *ngFor="let groupOption of groupOptions" [value]="groupOption.value">{{groupOption.name}}</mat-option>
              </mat-select>
              <mat-error *ngIf="group.touched && group.errors?.required">{{'msg_group_required' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <input matInput id="device_type_id" name="device_type_id" type="text" [formControl]="deviceTypeId" placeholder="{{'title_device_type_id' | translate}}" required>
              <ng-container *ngIf="deviceTypeId.touched && deviceTypeId.errors" ngProjectAs="mat-error">
                <mat-error *ngIf="deviceTypeId.errors.required">{{'msg_device_type_id_required' | translate}}</mat-error>
                <mat-error *ngIf="deviceTypeId.errors.resourceName">{{'msg_invalid_resource_name' | translate}}</mat-error>
                <mat-error *ngIf="deviceTypeId.errors.maxlength">{{'msg_device_type_id_bust_be_50_or_less' | translate}}</mat-error>
              </ng-container>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="full-width-field">
              <input matInput id="display_name" name="display_name" type="text" [formControl]="displayName" placeholder="{{'title_device_type_name' | translate}}" required>
              <ng-container *ngIf="displayName.touched && displayName.errors" ngProjectAs="mat-error">
                <mat-error *ngIf="displayName.errors.required">{{'msg_device_type_name_required' | translate}}</mat-error>
                <mat-error *ngIf="displayName.errors.maxlength">{{'msg_device_type_name_bust_be_50_or_less' | translate}}</mat-error>
              </ng-container>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field class="full-width-field">
              <mat-select name="communicationType" [formControl]="communicationType" placeholder="{{'title_communication_type' | translate}}" required>
                <mat-option></mat-option>
                <mat-option *ngFor="let communicationTypeOption of communicationTypeOptions" [value]="communicationTypeOption.value">{{communicationTypeOption.name | translate}}</mat-option>
              </mat-select>
              <mat-error *ngIf="communicationType.touched && communicationType.errors?.required">{{'msg_comtype_required' | translate}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="full-width-field">
              <input matInput id="time_window" name="time_window" type="number" [formControl]="timeWindow" placeholder="{{'title_time_window' | translate}}">
              <mat-hint align="end">{{'p_holder_0_99999999_int_sec' | translate}}</mat-hint>
              <ng-container *ngIf="timeWindow.touched && timeWindow.errors" ngProjectAs="mat-error">
                <mat-error *ngIf="timeWindow.errors.required">{{'msg_time_window_must_be_positive' | translate}}</mat-error>
                <mat-error *ngIf="timeWindow.errors.max">{{'msg_time_window_must_be_99999999_or_less' | translate}}</mat-error>
                <mat-error *ngIf="timeWindow.errors.pattern">{{'msg_time_window_must_be_integer' | translate}}</mat-error>
              </ng-container>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button mat-raised-button color="primary" [disabled]="deviceTypeForm.invalid" (click)="deviceTypeAdd()">
    <mat-icon aria-hidden="true">save</mat-icon> {{'title_save' | translate}}
  </button>
  <button mat-raised-button type="button" (click)="bsModalRef.hide()">
    <mat-icon aria-hidden="true">clear</mat-icon> {{'title_cancel' | translate}}
  </button>
</div>

