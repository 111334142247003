import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CommonModule, DatePipe} from '@angular/common';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomFormsModule } from 'ng2-validation';
import { DeviceAddComponent } from './devices/modal/device-add.component';
import { GroupApiKeyAddComponent } from './groupdetail/modal/group-api-key-add.component';
import { GroupMemberAddComponent } from './groupdetail/modal/group-member-add.component';
import { MapEditComponent } from './device/modal/map-edit.component';
import { ConfirmComponent } from './common/messages/confirm.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PageNotFoundComponent } from './not-found.component';
import { DevicesModule } from './devices/devices.module';
import { LoginModule } from './login/login.module';
import { DeviceModule } from './device/device.module';
import { AlertLogsModule } from './alert-logs/alert-logs.module';
import { AlertOutputModule } from './alert-output/alert-output.module';
import { UserModule } from './user/user.module';
import { Functions } from './common/functions';
import { DeviceTypesModule } from './device-types/device-types.module';
import { DeviceOutputModule } from './device-output/device-output.module';
import { GroupsModule } from './groups/groups.module';
import { GroupDetailModule } from './groupdetail/groupdetail.modules';
import { DeviceGroupsModule } from './device-groups/device-groups.module';
import { DeviceGroupDetailModule } from './device-groupdetail/device-groupdetail.modules';
import { DeviceTypeModule } from './device-type/device-type.module';
import { RouterService } from './services/router.service';
import { DialogService } from './services/dialog.service';
import { AppMaterialModule } from './common/app-material.module';
import { QueryStoreService } from './services/query-store.service';
import { WebStorageI18nRepository } from './services/i18n-repository.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './resources/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    CustomFormsModule,
    DevicesModule,
    LoginModule,
    DeviceModule,
    DeviceOutputModule,
    DeviceTypesModule,
    DeviceTypeModule,
    AlertLogsModule,
    AlertOutputModule,
    GroupsModule,
    GroupDetailModule,
    DeviceGroupsModule,
    DeviceGroupDetailModule,
    UserModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BrowserAnimationsModule,
    AppMaterialModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ConfirmComponent
  ],
  providers: [
    Functions,
    RouterService,
    DialogService,
    QueryStoreService,
    WebStorageI18nRepository,
    DatePipe
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    DeviceAddComponent,
    GroupApiKeyAddComponent,
    GroupMemberAddComponent,
    MapEditComponent,
    ConfirmComponent
  ]
})
export class AppModule {
  constructor() { }
}
