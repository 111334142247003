import { Subscription, Observable } from 'rxjs';
import { OnInit, Component, OnDestroy } from '@angular/core';
import { DeviceDetail } from '../../common/api/device';
import { ActivatedRoute } from '@angular/router';
import { WebStorageAuthRepository } from '../../services/auth-repository.service';
import { ApiHandler } from '../../services/api-handler.service';
import { DeviceType, DeviceTypeGetApi } from '../../common/api/device-type';
import { map } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  templateUrl: 'alert-defs.component.html'
})
export class AlertDefsComponent implements OnInit, OnDestroy {

  device: DeviceDetail;
  /** このデバイスが所属するデバイスタイプの情報 */
  deviceType$: Observable<DeviceTypeGetApi['responseBodyInterface']>;
  /** 新規のアラート定義入力欄を表示中かどうか */
  isAdding: boolean;

  /** 親コンポーネントの解決済みデータを受け取るイベントの購読状態 */
  private parentSubscription = Subscription.EMPTY;

  constructor(
    private route: ActivatedRoute,
    private authRepo: WebStorageAuthRepository,
    private apiHandler: ApiHandler
  ) { }

  /** デバイスを編集できるかどうか */
  get canEdit() {
    return this.authRepo.hasPermission('device', 'edit');
  }

  private fetchDeviceType(deviceTypeId: number): Observable<DeviceTypeGetApi['responseBodyInterface']> {
    return this.apiHandler.call(false, DeviceTypeGetApi, [deviceTypeId])
      .pipe(map(res => {
        if (!res.hasError) {
          return res.body;
        }
      }));
  }

  ngOnInit(): void {
    this.parentSubscription = this.route.parent.data.subscribe(obj => {
      // 親コンポーネントのデバイスタイプをそのまま使わせていただく
      this.device = obj['device'];
      this.isAdding = false;
      this.deviceType$ = this.fetchDeviceType(this.device.deviceTypeId);
    });
  }

  ngOnDestroy(): void {
    // イベント購読を停止する
    this.parentSubscription.unsubscribe();
  }

}
