import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AlertLogsComponent } from './alert-logs.component';
import { AlertDetailComponent } from './modal/alertdetail.component';
import { AlertLogsRoutingModule } from './alert-logs-routing.module';
import { DataService } from '../services/data.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AppMaterialModule } from '../common/app-material.module';

import { Functions } from '../common/functions';
import { PartsComponentModule } from '../parts/parts-component.module';
@NgModule({
  imports: [
    AppMaterialModule,
    AlertLogsRoutingModule,
    CommonModule,
    FormsModule,
    PartsComponentModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule,
  ],
  declarations: [
    AlertLogsComponent,
    AlertDetailComponent
  ],
  providers: [
    DataService,
    Functions
  ],
  bootstrap: [
    AlertLogsComponent
  ],
  entryComponents: [
    AlertDetailComponent
  ]
})
export class AlertLogsModule { }


