import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceOutputComponent } from './device-output.component';
import { DeviceOutputRoutingModule } from './device-output-routing.module';
import { DataService } from '../services/data.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AppMaterialModule } from '../common/app-material.module';

import { Functions } from '../common/functions';
import { PartsComponentModule } from '../parts/parts-component.module';
@NgModule({
  imports: [
    AppMaterialModule,
    DeviceOutputRoutingModule,
    CommonModule,
    FormsModule,
    PartsComponentModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule,
    TimepickerModule,
    ReactiveFormsModule,
  ],
  declarations: [
    DeviceOutputComponent
  ],
  providers: [
    DataService,
    Functions
  ],
  bootstrap: [
    DeviceOutputComponent
  ],
  entryComponents: [

  ]
})
export class DeviceOutputModule { }


