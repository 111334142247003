import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { AuthGuard } from '../services/auth-guard.service';
import { ApiHandler } from '../services/api-handler.service';
import { SipApiClient } from '../common/api/sip-api-client';
import { WebStorageAuthRepository } from '../services/auth-repository.service';
import { AppMaterialModule } from '../common/app-material.module';
import { PartsComponentModule } from '../parts/parts-component.module';

@NgModule({
  imports: [LoginRoutingModule, CommonModule, FormsModule, AppMaterialModule, PartsComponentModule],
  declarations: [LoginComponent],
  providers: [AuthGuard, ApiHandler, SipApiClient, WebStorageAuthRepository]
})
export class LoginModule { }
