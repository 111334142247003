import { Input, Component, forwardRef, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';

/**
 * テキストの表示と編集を両立できる入力コントロール。
 *
 * デフォルトではテキスト表示するだけだが、編集ボタンを押下することで
 * テキストフィールドになりテキストを編集できるようになる。
 *
 * 以下の属性を付与すると、その値をそのままinputタグに引き継ぐ。
 *
 * - name
 * - type
 * - required
 * - minlength
 * - maxlength
 * - pattern
 * - min
 * - max
 *
 * @property inputControl このコンポーネントで表示するフォームコントロールを指定する。
 */
@Component({
  selector: 'app-editable-text',
  templateUrl: './editable-text.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableTextComponent),
      multi: true
    }
  ]
})
export class EditableTextComponent implements OnInit {

  /** テキストフィールドに紐づけるコントロール */
  @Input() inputControl: FormControl;
  form: FormGroup;

  /** テキストフィールドに対するバリデーションエラー一覧 */
  @Input() errors: Array<{ key: string, message: string}> = [];

  /**
   * 編集機能が無効かどうか。
   *
   * 無効な場合は、編集ボタン、保存ボタン、キャンセルボタンを非表示にするため、
   * 編集不可能になる。
   */
  @Input() disabled = false;

  /** 編集前の値を保持するフィールド */
  private oldValue: any;

  /** 保存ボタンでサブミット済みかどうか */
  submitted: boolean = false;

  /* カスタムコントロールの属性をそのままinputタグの属性に引き継ぐ */
  @Input() name: string;
  @Input() type = 'text';
  @Input() required = false;
  @Input() minlength: number;
  @Input() maxlength: number;
  @Input() pattern: string;
  @Input() min: number;
  @Input() max: number;

  /** 入力するテキストフィールド */
  @ViewChild(MatInput, { static: false }) materialInput: MatInput;

  /** 保存ボタンを押下したときに発生するイベント */
  @Output() saved = new EventEmitter<any>();

  /**
   * 編集中かどうかを表すフラグ
   *
   * 編集中は input タグで値を編集できる状態となる。
   * 非編集中は span で値を表示するだけとなる。
   */
  isEditing = false;

  ngOnInit(): void {
    this.form = new FormGroup({
      inputControl: this.inputControl
    });
  }

  edit() {
    this.isEditing = true;
    this.oldValue = this.inputControl.value;  // 編集前の値を退避
    setTimeout(_ => this.materialInput.focus());  // テキストフィールドにフォーカスする
  }

  /**
   * その時点の入力値を保存イベントとして通知する。
   */
  save() {
    this.saved.emit(this.inputControl.value);
    this.submitted = true;
  }

  /** サブミット状態を解除する */
  unsubmit() {
    this.submitted = false;
  }

  /** 入力値を確定して編集を終了する */
  finish() {
    this.isEditing = false;  // 編集を終了する
    this.initForm(this.inputControl.value);
    this.oldValue = undefined;
    this.submitted = false;
  }

  /** 入力前の値を復元してから編集を終了する */
  cancel() {
    this.isEditing = false;  // 編集を終了する
    this.initForm(this.oldValue);
    this.oldValue = undefined;
    this.submitted = false;
  }

  private initForm(value: any) {
    this.inputControl.reset(value);
    this.form.reset({
      inputControl: this.inputControl.value
    });
  }
}
