import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GroupDetailComponent } from './groupdetail.component';
import { GroupApiKeyComponent } from './group-api-key/group-api-key.component';
import { GroupsResolver } from './groupdetail-resolver.service';
import { ApiHandler } from '../services/api-handler.service';
import { AuthGuard } from '../services/auth-guard.service';

const routes: Routes = [
  {
    path: 'groups/:group_id',
    resolve: {
      groups: GroupsResolver
    },
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'api_keys', component: GroupApiKeyComponent, pathMatch: 'full'
      },
      {
        path: '', component: GroupDetailComponent, pathMatch: 'full'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ApiHandler, GroupsResolver]
})

export class GroupDetailRoutingModule { }
