import { Component, Input } from '@angular/core';
import { BaseAlertDefEditComponent } from './base-alert-def-edit.component';
import { RouterService } from '../../services/router.service';
import { FormBuilder } from '@angular/forms';
import { ApiHandler } from '../../services/api-handler.service';
import { DialogService } from '../../services/dialog.service';
import { Observable } from 'rxjs';
import { AlertDefUpdateApi, AlertDefAddApi, AlertDefDeleteApi } from '../../common/api/alert-def';
import { TranslateService } from '@ngx-translate/core';
import { filter, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-alert-def-edit',
  templateUrl: './alert-def-edit.component.html'
})
export class AlertDefEditComponent extends BaseAlertDefEditComponent {
  /** アラート定義が所属するデバイスタイプのユニークID */
  @Input() deviceTypeId: number;

  constructor(
    public t: TranslateService,
    routerService: RouterService,
    builder: FormBuilder,
    apiHandler: ApiHandler,
    dialogService: DialogService
  ) {
    super(t, routerService, builder, apiHandler, dialogService);
  }

  save() {
    let apiCall: Observable<{
      body: undefined;
      code: number;
      msg: string;
      hasError: boolean;
    }>;
    if (this.editMode) {
      // 更新
      const entity = this.alertDefForm.value;
      apiCall = this.apiHandler.call(true, AlertDefUpdateApi, [this.alertDef.id], entity);
    } else {
      // 登録
      const entity = {
        deviceTypeId: this.deviceTypeId,
        ...this.alertDefForm.value
      };
      // TODO: オプショナルな項目がnullだと登録APIがエラーになるので、nullな項目を除去する暫定対応
      Object.keys(entity).forEach((key) => (entity[key] == null) && delete entity[key]);
      apiCall = this.apiHandler.call(true, AlertDefAddApi, [], entity);
    }
    apiCall.subscribe(res => {
      if (!res.hasError) {
        this.routerService.reload();
      }
    });
  }

  delete() {
    this.dialogService.show(
      this.t.instant('title_confirmation'),
      this.t.instant(`msg_confirm_delete_alert_def`, {
        alertTitle: this.alertDef.title
      }))
      .pipe(
        filter(ok => ok),
        mergeMap(ok => {
          return this.apiHandler.call(true, AlertDefDeleteApi, [this.alertDef.id]);
      }))
      .subscribe(res => {
        if (!res.hasError) {
          this.routerService.reload();
        }
      });
  }

}
