<div class="modal-header">
  <h4 class="modal-title pull-left">{{'title_device_registration' | translate}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="form">
<div class="modal-body">
  <div class="panel panel-default">
    <div class="panel-body">
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="full-width-field">
            <mat-select [formControl]="selectedGroup" placeholder="{{'title_group' | translate}}" (selectionChange)="onGroupChange()" required>
              <mat-option></mat-option>
              <mat-option *ngFor="let dropdownGroup of dropdownGroups" [value]="dropdownGroup.value">{{dropdownGroup.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedGroup.touched && selectedGroup.hasError('required')">{{'msg_group_select_required' | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="full-width-field">
            <mat-select [formControl]="selectedDeviceType" placeholder="{{'title_device_type' | translate}}" required>
              <mat-option></mat-option>
              <mat-option *ngFor="let dropdownDeviceType of dropdownDeviceTypes" [value]="dropdownDeviceType.value">{{dropdownDeviceType.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedDeviceType.touched && selectedDeviceType.hasError('required')">{{'msg_device_type_required' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="full-width-field">
            <mat-select [formControl]="selectedDeviceGroup" placeholder="{{'title_device_group' | translate}}" required>
              <mat-option></mat-option>
              <mat-option *ngFor="let dropdownDeviceGroup of dropdownDeviceGroups" [value]="dropdownDeviceGroup.value">{{dropdownDeviceGroup.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedDeviceGroup.touched && selectedDeviceGroup.hasError('required')">{{'msg_device_group_required' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <mat-form-field class="full-width-field">
            <input matInput id="device_id" name="device_id" type="text" [formControl]="deviceId" placeholder="{{'title_device_id' | translate}}" required maxlength="50">
            <ng-container *ngIf="deviceId.touched && deviceId.errors" ngProjectAs="mat-error">
              <mat-error *ngIf="deviceId.hasError('required')">{{'msg_device_id_required' | translate}}</mat-error>
              <mat-error *ngIf="deviceId.hasError('resourceName')">{{'msg_invalid_resource_name' | translate}}</mat-error>
              <mat-error *ngIf="deviceId.hasError('maxlength')">{{'msg_device_id_bust_be_50_or_less' | translate}}</mat-error>
            </ng-container>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <mat-form-field class="full-width-field">
            <input matInput id="display_name" name="display_name" type="text" [formControl]="displayName" placeholder="{{'title_device_name' | translate}}" required maxlength="50">
            <ng-container *ngIf="displayName.touched && displayName.errors" ngProjectAs="mat-error">
              <mat-error *ngIf="displayName.hasError('required')">{{'msg_device_name_required' | translate}}</mat-error>
              <mat-error *ngIf="displayName.hasError('maxlength')">{{'msg_device_name_bust_be_50_or_less' | translate}}</mat-error>
            </ng-container>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button mat-raised-button color="primary" (click)="deviceAdd()" [disabled]="form.pristine || form.invalid || selectedDeviceType.value==null || selectedDeviceGroup.value==null">
    <mat-icon aria-hidden="true">save</mat-icon> {{'title_save' | translate}}
  </button>
  <button mat-raised-button type="button" (click)="bsModalRef.hide()">
    <mat-icon aria-hidden="true">clear</mat-icon> {{'title_cancel' | translate}}
  </button>
</div>
</form>
