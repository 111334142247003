import { Component, OnInit } from '@angular/core';
import { ApiHandler } from '../services/api-handler.service';
import { Observable, forkJoin } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeviceAddComponent } from './modal/device-add.component';
import { Functions } from '../common/functions';
import { DeviceTypeListApi } from '../common/api/device-type';
import { GroupsGetApi } from '../common/api/group';
import { DeviceListApi, Device, DeviceDeleteApi, HasAlert } from '../common/api/device';
import { DialogService } from '../services/dialog.service';
import { AlertSubscriptionToggleAddApi, AlertSubscriptionStatus } from '../common/api/alert-subscription';
import { IconDef, Sort } from '../common/interfaces/display-interfaces';
import { PageableSearchCondition } from '../common/view/PageableSearchCondition';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { QueryStoreService } from '../services/query-store.service';
import { TranslateService } from '@ngx-translate/core';
import {DeviceMuteStatus, DeviceMuteToggleAddApi} from '../common/api/device-mute';
import {DeviceStatus, DeviceStatusToggleAddApi} from '../common/api/device-status';
import {DeviceGroupsGetApi} from '../common/api/device-group';
import {ReloadIntervalGetApi, UserGetApi} from '../common/api/user';
import { AppComponent } from '../app.component';
import { WebStorageAuthRepository } from '../services/auth-repository.service';

@Component({
  moduleId: module.id,
  templateUrl: 'devices.component.html'
})
export class DevicesComponent implements OnInit {
  condition: DeviceSearchCondition;
  isNoData: boolean = false;
  isExpanded: boolean = true;

  constructor(
    public t: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private queryStore: QueryStoreService,
    private apiHandler: ApiHandler,
    private modalService: BsModalService,
    private dialogService: DialogService,
    private functions: Functions,
    private appComponent: AppComponent,
    private authRepo: WebStorageAuthRepository
  ) { }

  // 開発用特権ユーザー
  isSuperuser: boolean;

  // interfaceModel
  devicesresults: Array<DeviceRow> = [];

  // DropDown
  dropdownDeviceTypes: Array<{ value: any, name: string }>;
  dropdownGroups: Array<{ value: any, name: string }>;
  dropdownDeviceGroups: Array<{ value: any, name: string }>;
  dropdownHasAlert: Array<{ value: any, name: string }>;

  // Pagination
  count: number; // 全件数

  // リロード間隔
  reloadInterval: number;
  needReloadFlg: boolean = false;

  get allchecked(): boolean {
    return this.devicesresults.length !== 0 /* ignore empty array */ &&
      this.devicesresults.every(device => device.isChecked);
  }

  /** テーブルのカラム定義 */
  columnList = [
    { seq: 1, id: 'displayName', displayName: 'title_device_name', issort: true },
    { seq: 2, id: 'deviceId', displayName: 'title_device_id', issort: true },
    { seq: 3, id: 'groupDisplayName', displayName: 'title_group', issort: true },
    { seq: 4, id: 'deviceTypeDisplayName', displayName: 'title_device_type', issort: true },
    { seq: 5, id: 'deviceGroupDisplayName', displayName: 'title_device_group', issort: true },
    { seq: 6, id: 'hasAlert', displayName: 'title_state', issort: false },
    { seq: 7, id: 'status', displayName: 'title_status', issort: false },
    { seq: 8, id: 'lastReceivedDatetime', displayName: 'title_last_receive_datetime', issort: true },
    { seq: 9, id: 'isSubscribed', displayName: '', issort: false },
    { seq: 10, id: 'isMuted', displayName: '', issort: false }
  ];

  // ソート
  sortDevices(sortColumn: string) {
    if (this.needReloadFlg) {
      this.startTimer();
    }
    this.condition.setSort(sortColumn);
    this.condition.clearPage();
    this.searchDevices();
  }

  /**
   * 検索ボタンonclick
   */
  clickSearch() {
    if (this.needReloadFlg) {
      this.startTimer();
    }
    this.condition.clearSort();
    this.condition.clearPage();
    this.searchDevices();
  }

  /**
   * ページ遷移
   */
  pageChanged(event: any): void {
    if (this.needReloadFlg) {
      this.startTimer();
    }
    this.condition.setPage(event.page);
    this.searchDevices();
  }

  startTimer(): void {
    const that = this;
    clearInterval(this.appComponent.reloadFun);
    this.appComponent.reloadFun = setInterval(function() {
      return that.searchDevices();
    }, that.reloadInterval * 60 * 1000);
  }

  /**
   * 削除ボタンonclick
   */
  clickDelete() {
    const deleteDevices = this.devicesresults
      .filter(device => device.isChecked);
    const deleteIds = deleteDevices.map(device => {
      return { message: device.deviceId };
    });
    // 確認ダイアログ
    this.dialogService.show(this.t.instant('title_confirmation'), this.t.instant('msg_confirm_delete_devices'), deleteIds)
      .subscribe(ok => {
        if (ok) {  // OKが押されたら削除API発行
          const apiCalls = deleteDevices
            .map(device => this.apiHandler.call(false, DeviceDeleteApi, [device.id]));
          // すべてのAPI呼び出しをまとめたObservableを生成
          forkJoin(apiCalls)
            .subscribe(result => {
              // すべてのAPI呼び出しが（失敗も含めて）完了した後の処理
              const totalCount = result.length;
              const failedCount = result.filter(elm => elm.hasError).length;
              if (failedCount > 0) {
                this.functions.showToastMessage(this.t.instant(`msg_faild_delete_items`, {
                  totalCount: totalCount, failedCount: failedCount
                }), 'warning');
              } else {
                this.functions.showToastMessage(this.t.instant(`msg_succeeded_delete_items`, {
                  totalCount: totalCount
                }), 'success');
              }
              this.searchDevices();
            });
        }
      });
  }

  /**
   * デバイス一覧取得
   */
  searchDevices() {
    // URLを変更する
    this.router.navigate(this.route.snapshot.url, {
      queryParams: this.condition.queryParams(),
      replaceUrl: true  // 履歴は上書き
    });

    // デバイス一覧検索
    this.apiHandler.call(false, DeviceListApi, [], undefined, this.condition.query, this.condition.order, this.condition.pagination)
      .subscribe(res => {
        if (!res.hasError) {
          this.queryStore.storeQuery('devices', this.condition.queryParams());  // 検索条件を保存
          const devices = res.body;
          this.count = devices.count;
          this.devicesresults = devices.results.map(device => {
            return {
              ...device,
              isChecked: false,
              hasAlertIcon: this.getAlertIconByStatus(device.status, device.hasAlert),
              isSubscribedIcon: AlertSubscriptionStatus.toIcon(device.isSubscribed),
              isMutedIcon: DeviceMuteStatus.toIcon(device.isMuted),
              statusIcon: DeviceStatus.toIcon(device.status)
            };
          });

          if (this.devicesresults.length === 0) {
            this.isNoData = true;
          } else {
            this.isNoData = false;
          }
        } else {
          if (this.condition.page != null && this.condition.page > 1) {
            this.condition.setPage(this.condition.page - 1);
            this.searchDevices();
          } else {
            this.isNoData = true;
            this.devicesresults = [];
          }
        }
      });
  }

  /**
   * リロード間隔取得
   */
  private searchReloadInterval() {
    // リロード間隔取得API呼び出し
    this.apiHandler.call(false, ReloadIntervalGetApi, [], undefined, {}, [])
      .subscribe(
        res => {
          const reloadIntervalObj = res.body;
          this.reloadInterval = reloadIntervalObj.interval;
          if (this.reloadInterval != null && this.reloadInterval !== 0 && this.reloadInterval !== -1) {
            this.needReloadFlg = true;
            this.startTimer();
          }
        });
  }

  /**
   * デバイスタイプ一覧取得
   */
  searchDeviceTypes() {
    const pagination = { pageSize: 1000 };  // 全てのデバイスタイプを取得するためのページサイズを最大にする
    // デバイスタイプを検索して、選択ボックスの候補に設定する
    const deviceTypesObservable = this.apiHandler.call(false, DeviceTypeListApi, [], undefined, {}, [], pagination);
    deviceTypesObservable.subscribe(res => {
      if (!res.hasError) {
        this.dropdownDeviceTypes = res.body.results.map(deviceType => {
          return { value: deviceType.id, name: deviceType.displayName };
        });
      }
    });
  }

  /**
   * グループ一覧取得
   */
  searchGroups() {
    const pagination = { pageSize: 1000 };  // 全てのグループを取得するためのページサイズを最大にする
    // グループ一覧検索して選択ボックスの候補に設定する
    const groupsObservable = this.apiHandler.call(false, GroupsGetApi, [], undefined, {}, [], pagination);
    groupsObservable.subscribe(res => {
      if (!res.hasError) {
        this.dropdownGroups = res.body.results.map(group => {
          return { value: group.id, name: group.displayName };
        });
      }
    });
  }

  /**
   * デバイスグループ一覧取得
   */
  searchDeviceGroups() {
    const pagination = { pageSize: 1000 };  // 全てのデバイスグループを取得するためのページサイズを最大にする
    // デバイスグループ一覧検索して選択ボックスの候補に設定する
    const groupsObservable = this.apiHandler.call(false, DeviceGroupsGetApi, [], undefined, {}, [], pagination);
    groupsObservable.subscribe(res => {
      if (!res.hasError) {
        this.dropdownDeviceGroups = res.body.results.map(group => {
          return { value: group.id, name: group.displayName };
        });
      }
    });
  }

  /**
   * ユーザスーパーユーザー権限チェック
   */
  // checkUserIsSuperuser() {
  //   const pagination = { pageSize: 1000 };  // 全てのデバイスグループを取得するためのページサイズを最大にする
  //   // デバイスグループ一覧検索して選択ボックスの候補に設定する
  //   const groupsObservable = this.apiHandler.call(false, UserGetApi, [], undefined, {}, [], pagination);
  //   groupsObservable.subscribe(res => {
  //     if (!res.hasError) {
  //       this.isSuperuser = res.body.isSuperuser;
  //     }
  //   });
  // }

  /**
   * アラート購読を切り替える
   */
  alertToggle(device: DeviceRow) {
    const payload = { deviceId: device.id };
    this.apiHandler.call(true, AlertSubscriptionToggleAddApi, [], payload)
      .subscribe(res => {
        if (!res.hasError) {
          // 当該モデルの値を更新
          device.isSubscribed = !device.isSubscribed;
          device.isSubscribedIcon = AlertSubscriptionStatus.toIcon(device.isSubscribed);
        }
      });
  }

  /**
   * デバイスステータス切り替えボタン
   */
  deviceStatusToggle(device: DeviceRow) {

    const payload = {
      deviceId: device.id,
      status: !device.status
    };
    this.apiHandler.call(true, DeviceStatusToggleAddApi, [], payload)
      .subscribe(res => {
        if (!res.hasError) {
          // 当該モデルの値を更新
          device.status = !device.status;
          device.statusIcon = DeviceStatus.toIcon(device.status);
          device.hasAlertIcon = this.getAlertIconByStatus(device.status, device.hasAlert);
        }
      });
  }

  /**
   * ステータスによって、アラートアイコンを取得する
   */
  getAlertIconByStatus(status: boolean, hasAlert: boolean) {
      // アラートアイコン連動
      if (status) {
          return HasAlert.toIcon(hasAlert);
      } else {
        return HasAlert.toIcon(false);
      }
  }

  /**
   * デバイスミュート切り替えボタン
   */
  deviceToggle(device: DeviceRow) {

    let msg_confirm_key = 'msg_confirm_mute_on';
    if (device.isMuted) {
      msg_confirm_key = 'msg_confirm_mute_off';
    }
    this.dialogService.show(this.t.instant('title_confirmation'),
      this.t.instant(msg_confirm_key, {'deviceDisplayName': device.displayName}))
      .subscribe(ok => {
        if (ok) {
          // ミュート切り替え start
          const payload = {
            deviceId: device.id,
            isMuted: !device.isMuted
          };
          this.apiHandler.call(true, DeviceMuteToggleAddApi, [], payload)
            .subscribe(res => {
              if (!res.hasError) {
                // 当該モデルの値を更新
                device.isMuted = !device.isMuted;
                device.isMutedIcon = DeviceMuteStatus.toIcon(device.isMuted);
              }
            });
          // ミュート切り替え end
        }
      });
  }

  /**
   * 追加画面起動
   */
  openDeviceAdd() {
    const modalRef = this.modalService.show(DeviceAddComponent, {
      class: 'modal-dialog modal-lg',
      backdrop: 'static',
      initialState: {
        dropdownGroups: this.dropdownGroups,
        dropdownDeviceTypes: this.dropdownDeviceTypes,
        dropdownDeviceGroups: this.dropdownDeviceGroups,
        isSuperuser: this.isSuperuser
      }
    });
    // モーダルを閉じたときに登録完了フラグONの場合に再取得する。
    this.modalService.onHide.subscribe(() => {
      if (modalRef.content.isOk) {
        this.searchDevices();
        modalRef.content.isOk = false;
      }
    });
  }

  /**
   * 削除ボタンの活性状態
   *
   * 1つ以上チェックがあれば活性になる。
   */
  get isDeletable(): boolean {
    return this.devicesresults.some(row => row.isChecked);
  }

  /**
   * チェックボックス一括設定
   */
  cbAll(checkVal: boolean) {
    // 各行のチェック状態を更新
    for (const row of this.devicesresults) {
      row.isChecked = checkVal;
    }
  }

  /**
* 初期化処理
*/
  ngOnInit(): void {
    this.condition = new DeviceSearchCondition();
    /* クエリ文字列 or 永続化された検索条件で検索条件入力フォームを更新する */
    let activeQuery: Params = {};
    const params = this.route.snapshot.queryParams;  // クエリ文字列
    if (Object.keys(params).length === 0) {
      // クエリ文字列が空の場合は保存されている検索条件を取得する
      const storedQuery = this.queryStore.getQuery('devices');
      activeQuery = storedQuery;
    } else {
      activeQuery = params;
    }
    this.condition.update(activeQuery);
    // デバイス一覧取得
    this.searchDevices();
    // アラートログ一覧取得
    this.searchReloadInterval();
    // チェックボックス初期化(off)
    this.cbAll(false);
    // アラート有無ドロップダウン設定
    this.dropdownHasAlert = [
      { value: true, name: 'title_has_alerts' },
      { value: false, name: 'title_no_alerts' }
    ];

    // デバイスタイプ取得
    this.searchDeviceTypes();
    // グループ取得
    this.searchGroups();
    // デバイスグループ取得
    this.searchDeviceGroups();
    // ユーザスーパーユーザー権限チェック
    this.isSuperuser = this.authRepo.isRootUser();
  }
}

/** デバイス検索の入力フォームを表すクラス */
class DeviceSearchCondition extends PageableSearchCondition<DeviceListApi> {
  constructor(
    sort: Sort | null = null,
    page: number | null = null,
    public displayName: string = null,
    public deviceId: string = null,
    public groupId: number[] = [],
    public deviceGroupId: number[] = [],
    public deviceTypeId: number[] = [],
    public hasAlert: boolean = null,
  ) {
    super(sort, page);
  }

  get query(): DeviceListApi['queryInterface'] {
    const query: DeviceListApi['queryInterface'] = {};
    if (!this.isEmpty(this.displayName)) {
      query.displayName = this.displayName;
    }
    if (!this.isEmpty(this.deviceId)) {
      query.deviceId = this.deviceId;
    }
    if (!this.isEmpty(this.groupId)) {
      query.groupId = this.groupId;
    }
    if (!this.isEmpty(this.deviceTypeId)) {
      query.deviceTypeId = this.deviceTypeId;
    }
    if (!this.isEmpty(this.hasAlert)) {
      query.hasAlert = this.hasAlert;
    }
    if (!this.isEmpty(this.deviceGroupId)) {
      query.deviceGroupId = this.deviceGroupId;
    }
    return query;
  }

  /** クエリパラメータに応じて条件を更新する */
  update(params: Params): void {
    super.update(params);
    if (!this.isEmpty(params['display_name'])) {
      this.displayName = params['display_name'];
    }
    if (!this.isEmpty(params['device_id'])) {
      this.deviceId = params['device_id'];
    }
    if (!this.isEmpty(params['group_id'])) {
      this.groupId = params['group_id'].split('|').map(elm => Number(elm));
    }
    if (!this.isEmpty(params['device_group_id'])) {
      this.groupId = params['device_group_id'].split('|').map(elm => Number(elm));
    }
    if (!this.isEmpty(params['device_type_id'])) {
      this.deviceTypeId = params['device_type_id'].split('|').map(elm => Number(elm));
    }
    if (!this.isEmpty(params['has_alert'])) {
      this.hasAlert = this.toBoolean(params['has_alert']);
    }
  }

  /** 入力フォームの入力をクリアする */
  clear() {
    this.displayName = null;
    this.deviceId = null;
    this.groupId = [];
    this.deviceTypeId = [];
    this.hasAlert = null;
    this.deviceGroupId = [];
  }
}

/** 1行に表示するデバイス情報 */
type DeviceRow = Device & {
  isChecked: boolean;
  hasAlertIcon: IconDef;
  isSubscribedIcon: IconDef;
  isMutedIcon: IconDef;
  statusIcon: IconDef;
};
